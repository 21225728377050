import 'assets/ProfileLogos';
import {
	ChatIcon,
	EmployeesIcon,
	HelpIcon,
	PartnerIcon,
	PasswordIcon,
	RequestSuppliersIcon,
	SourcesStatisticsIcon,
	TariffsIcon,
	UserIcon
} from 'assets/ProfileLogos';
import clsx from 'clsx';
import { format } from 'date-fns';
import { useGetUser } from 'features/profile/api/useGetUser';
import { useGetTariff } from 'features/tariff/api/useGetTariff';
import { useContext, useEffect, useState } from 'react';
import { Link, NavLink, Outlet, useLocation } from 'react-router-dom';
import CommonContext from 'services/AuthContext';
import ToggleMobNavButton from '../ToggleMobNavButton';
import './ProfileNav.scss';

const ProfileNav = () => {
	const { userData, isPending, isError } = useGetUser();
	const location = useLocation();
	const { data, tariffName: tariff } = useGetTariff();
	const { authState } = useContext(CommonContext);
	const [tariffDate, setTariffDate] = useState('');
	const [isShowNav, setIsShowNav] = useState(false);

	useEffect(() => {
		const appNode = window.document.querySelector('.App');

		if (isShowNav) {
			appNode!.classList.add('hidden');
		} else {
			appNode!.classList.remove('hidden');
		}
		return () => {
			appNode!.classList.remove('hidden');
		};
	}, [isShowNav]);

	useEffect(() => {
		if (data) {
			//@ts-ignore
			setTariffDate(format(data.expired_in, 'dd.MM.yyyy'));
		}
	}, [data]);

	return (
		<>
			{authState ? (
				<div
					className={`tariff-header ${
						tariff !== 'Бесплатный' ? 'activated' : ' '
					}`}
				>
					{tariff === 'Бесплатный' ? (
						<>
							<p className='tariff-name'>Без тарифа</p>
							<div>
								<p>
									<Link to='tariffs'>Подобрать тариф</Link>
								</p>
							</div>
						</>
					) : (
						<>
							<p className='tariff-name'>
								<i>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'
									>
										<path
											d='M15.2683 18.2287C13.2889 20.9067 12.2992 22.2458 11.3758 21.9628C10.4525 21.6798 10.4525 20.0375 10.4525 16.7528L10.4526 16.4433C10.4526 15.2585 10.4526 14.6662 10.074 14.2946L10.054 14.2754C9.6673 13.9117 9.05079 13.9117 7.81775 13.9117C5.59888 13.9117 4.48945 13.9117 4.1145 13.2387C4.10829 13.2276 4.10225 13.2164 4.09639 13.205C3.74244 12.5217 4.3848 11.6526 5.66953 9.91436L8.73167 5.77133C10.711 3.09327 11.7007 1.75425 12.6241 2.03721C13.5474 2.32018 13.5474 3.96249 13.5474 7.24712V7.55682C13.5474 8.74151 13.5474 9.33386 13.926 9.70541L13.946 9.72466C14.3327 10.0884 14.9492 10.0884 16.1822 10.0884C18.4011 10.0884 19.5106 10.0884 19.8855 10.7613C19.8917 10.7724 19.8977 10.7837 19.9036 10.795C20.2576 11.4784 19.6152 12.3475 18.3304 14.0857'
											stroke='white'
											strokeWidth='2'
											strokeLinecap='round'
										/>
									</svg>
								</i>
								{tariff}
							</p>
							<div>
								<p className='tariff-date'>Действует до {tariffDate}</p>
							</div>
						</>
					)}
				</div>
			) : (
				<></>
			)}
			<div className={clsx('profile', isShowNav && 'hidden')}>
				<ToggleMobNavButton
					isShowNav={isShowNav}
					onClick={() => setIsShowNav(isShowNav => !isShowNav)}
				/>
				<nav className={isShowNav ? 'show' : ''}>
					<NavLink
						to='profile'
						onClick={() => setIsShowNav(isShowNav => !isShowNav)}
					>
						<UserIcon />
						<span>Профиль компании</span>
					</NavLink>
					<NavLink
						to='tariffs'
						onClick={() => setIsShowNav(isShowNav => !isShowNav)}
					>
						<TariffsIcon />
						<span>Тарифы и оплата</span>
					</NavLink>
					<NavLink
						to='employees'
						onClick={() => setIsShowNav(isShowNav => !isShowNav)}
					>
						<EmployeesIcon />
						<span>Сотрудники</span>
					</NavLink>
					<NavLink
						to='password-change'
						onClick={() => setIsShowNav(isShowNav => !isShowNav)}
					>
						<PasswordIcon />
						<span>Смена пароля</span>
					</NavLink>
					<NavLink
						to='help'
						onClick={() => setIsShowNav(isShowNav => !isShowNav)}
					>
						<HelpIcon />
						<span>Помощь</span>
					</NavLink>
					<NavLink
						to={'chat'}
						onClick={() => setIsShowNav(isShowNav => !isShowNav)}
					>
						<ChatIcon />
						<span>Чат поддержки</span>
					</NavLink>
					<NavLink
						to='partner'
						onClick={() => setIsShowNav(isShowNav => !isShowNav)}
					>
						<PartnerIcon />
						<span>Стать партнером</span>
					</NavLink>

					<NavLink
						to='supplier'
						onClick={() => setIsShowNav(isShowNav => !isShowNav)}
					>
						<PartnerIcon />
						<span>Стать поставщиком</span>
					</NavLink>

					<NavLink
						to='sources-statistics'
						onClick={() => setIsShowNav(isShowNav => !isShowNav)}
					>
						<SourcesStatisticsIcon />
						<span>Источники и статистика</span>
					</NavLink>

					<NavLink
						to='request-suppliers'
						onClick={() => setIsShowNav(isShowNav => !isShowNav)}
					>
						<RequestSuppliersIcon />
						<span>Запросы партнерам</span>
					</NavLink>
				</nav>

				<div
					className={
						[`/account/chat`, `/account/admin-chat`].includes(location.pathname)
							? authState
								? 'content-chat'
								: 'content'
							: [`/account/tariffs`, `/account/sources-statistics`].includes(
									location.pathname
							  )
							? 'tariff-content'
							: 'content'
					}
				>
					{authState ? (
						location.pathname === '/account/partner' &&
						tariff === 'Бесплатный' ? (
							<>
								<div className='profile-overlay'>
									<div className='profile-overlay-content'>
										{userData?.first_name ? (
											<p>
												Для того, чтобы стать нашим партнером, смените тариф на
												подходящий в разделе{' '}
												<Link to='/account/tariffs'>“Тарифы и оплата”</Link>
											</p>
										) : (
											<p>
												Для того, чтобы стать нашим партнером, заполните форму
												партнера в разделе{' '}
												<Link to='/account/profile'>Профиль компании</Link>
											</p>
										)}
									</div>
								</div>
								<Outlet />
							</>
						) : (
							<Outlet />
						)
					) : (
						<div className='profile-overlay'>
							<div className='profile-overlay-content'>
								<p>
									Сейчас вы гость, для полного функционала пожалуйста пройдите
									<Link to='/register/'> регистрацию</Link>
								</p>
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default ProfileNav;
