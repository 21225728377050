import { getSupplerIcon } from 'assets/SupplersIcons';
import clsx from 'clsx';

import { ProductToProjectPopup } from 'features/product/ui';
import { FC } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { Link } from 'react-router-dom';
import { countryCodeName, URL_SITE } from 'shared/constants';
import { useValueCurrency } from 'shared/hooks';
import { TProduct, TSubscription, TUtils } from 'shared/services/types';
import { BitDot } from 'shared/ui';
import { TextToggle } from 'shared/ui/collapsible';
import { PromptText } from 'shared/ui/prompt-text';
import { getFormattedDate } from 'shared/utils';
import { distributerList } from '..';
import { TableMobile } from './TableMobile';

interface IProps {
	settingsParam?: string[];
	productsList: TProduct.Data[];
	sortTable: Record<string, 'asc' | 'desc'>;
	onSortTableProducts: (name: string) => void;
	currentCurrency: any;
	tariff: string;
	authState: boolean;
	countries: TUtils.DataCountry[];
	data?: TSubscription.TariffUser;
}

export const tariffsBlur = [undefined, 5, 7];

export const TableProducts: FC<IProps> = ({
	settingsParam,
	productsList,
	onSortTableProducts,
	currentCurrency,
	authState,
	tariff,
	countries,
	data,
	sortTable
}) => {
	const { getCurrencyCounty } = useValueCurrency(false, 0);

	const currentPriceValue = (prices: any[], unrounded?: boolean) => {
		const label = currentCurrency?.label;
		return currentCurrency
			? getCurrencyCounty(
					prices?.[label === 'RUB' ? 2 : 0]?.price,
					unrounded
			  ) || 'Уточняйте'
			: Number(prices?.[2]?.price);
	};

	return (
		<>
			<div className='product-table'>
				<table>
					<thead>
						<tr>
							{settingsParam?.includes('country_id') && (
								<th className='country-col'>Страна</th>
							)}

							{settingsParam?.includes('article') && (
								<th className='article-col'>Артикул</th>
							)}
							{settingsParam?.includes('name') && (
								<th className={clsx('article-col', 'article-col-name')}>
									Название
								</th>
							)}
							{settingsParam?.includes('stock_available') && (
								<th
									className={clsx(
										'available-col cursor',
										sortTable['stock_available']
									)}
									onClick={() => onSortTableProducts('stock_available')}
								>
									<div className={'flex'}>
										Доступно
										<IoIosArrowDown />
									</div>
								</th>
							)}
							{settingsParam?.includes('stock_quantity') && (
								<th
									className={clsx(
										'transit-col cursor',
										sortTable['stock_quantity']
									)}
									onClick={() => onSortTableProducts('stock_quantity')}
								>
									<div className={'flex'}>
										На складе
										<IoIosArrowDown />
									</div>
								</th>
							)}

							{settingsParam?.includes('transit_available') && (
								<th
									className={clsx(
										'transit-col cursor',
										sortTable['transit_available']
									)}
									onClick={() => onSortTableProducts('transit_available')}
								>
									<div className={'flex'}>
										Транзит
										<IoIosArrowDown />
									</div>
								</th>
							)}
							{settingsParam?.includes('prices') && (
								<th
									className={clsx('currency-col cursor', sortTable['price'])}
									onClick={() => onSortTableProducts('price')}
								>
									<div className={'flex'}>
										{productsList[0]?.supplier?.prices?.[0]?.currency_id ||
											'USD'}
										<IoIosArrowDown />
									</div>
								</th>
							)}

							{settingsParam?.includes('prices') &&
								currentCurrency?.label !== 'USD' && (
									<th
										onClick={() => onSortTableProducts('price')}
										className={clsx('currency-col cursor', sortTable['price'])}
									>
										<div className={'flex'}>
											{currentCurrency?.label ||
												productsList[0]?.supplier?.prices?.[2]?.currency_id ||
												'RUB'}
											<IoIosArrowDown />
										</div>
									</th>
								)}

							{settingsParam?.includes('supplier') && (
								<th
									className={
										authState && tariff !== 'Бесплатный'
											? 'manufacturer-col'
											: 'manufacturer-col not-authorized'
									}
								>
									Поставщик
								</th>
							)}
							{authState && <th className='tolist-col'>В список</th>}
						</tr>
					</thead>
					<tbody>
						{productsList.map(i => {
							return (
								<tr key={i.id}>
									{settingsParam?.includes('country_id') && (
										<td className='country-col' aria-label='Страна'>
											{/* @ts-ignore */}
											<p>{countryCodeName[i.country_id]}</p>
											<img
												src={
													URL_SITE +
													countries?.find(l => i.country_id === l.id)?.flag
												}
												height='24'
												width='34'
											/>
										</td>
									)}
									{settingsParam?.includes('article') && (
										<td className='article-col' aria-label='Артикул'>
											<TextToggle text={i.article || ''} lines={1} />
										</td>
									)}
									{settingsParam?.includes('name') && (
										<td className='name-col' aria-label='Название'>
											<TextToggle text={i.name} lines={1} />
										</td>
									)}
									{settingsParam?.includes('stock_available') && (
										<td
											className={clsx(
												'available-col',
												i.stock_available.includes('*') && 'large-size'
											)}
											aria-label='Доступно'
											onClick={() => onSortTableProducts('stock_available')}
										>
											<p>{i.stock_available}</p>
										</td>
									)}
									{settingsParam?.includes('stock_quantity') && (
										<td
											className={clsx(
												'instock-col',
												i.stock_quantity.includes('*') && 'large-size'
											)}
											aria-label='На складе'
											onClick={() => onSortTableProducts('stock_quantity')}
										>
											<p>{i.stock_quantity}</p>
										</td>
									)}

									{settingsParam?.includes('transit_available') && (
										<td
											className='transit-col'
											aria-label='Транзит'
											onClick={() => onSortTableProducts('transit_available')}
										>
											<p>{i.transit_available}</p>
										</td>
									)}

									{settingsParam?.includes('prices') && (
										<td
											className='currency-col'
											aria-label={
												productsList[0].supplier?.prices?.[0].currency_id
											}
										>
											<BitDot
												text={
													getCurrencyCounty(
														i.supplier?.prices?.[0]?.price,
														true,
														true
													) || 'Уточняйте'
												}
											/>
										</td>
									)}

									{settingsParam?.includes('prices') &&
										currentCurrency?.label !== 'USD' && (
											<td className='currency-col'>
												<BitDot
													text={
														getCurrencyCounty(
															i.supplier?.prices?.[2]?.price,
															true,
															true
														) || 'Уточняйте'
													}
												/>
											</td>
										)}

									{settingsParam?.includes('supplier') && (
										<td
											className={clsx(
												'manufacturer-col',
												!(authState && tariff !== 'Бесплатный') &&
													'not-authorized'
											)}
											aria-label='Поставщик'
										>
											{!authState || tariff === 'Бесплатный' ? (
												<div className='blur '>
													{getSupplerIcon(i?.supplier?.type?.toLowerCase())}
													{i?.supplier?.name}
													{tariff === 'Бесплатный' ? (
														<Link
															className='btn-register radius-zero'
															to={'/account/tariffs/'}
														>
															Приобретите подписку
														</Link>
													) : (
														<Link className='btn-register' to={'/register/'}>
															Зарегистрируйтесь <br />
															для просмотра поставщиков
														</Link>
													)}
												</div>
											) : (
												<div className='manufacturer-col-wrap'>
													<PromptText
														text={`${
															distributerList.find(
																item => item.value === i?.supplier?.type
															)?.help
														}`}
													>
														<div
															style={{
																position: 'relative',
																width: '15px',
																height: '15px'
															}}
														>
															{getSupplerIcon(i?.supplier?.type?.toLowerCase())}
														</div>
														<Link
															className='distributor-col'
															to={`/suppliers/${i?.supplier?.slug}`}
														>
															{i?.supplier?.name || 'Unknown'}
														</Link>
													</PromptText>
													<PromptText
														text={`Дата последнего обновления прайс-листа`}
													>
														<div className={'distributor-update'}>
															({getFormattedDate(i.last_update, 'dd.MM.yyyy')})
														</div>
													</PromptText>
												</div>
											)}
										</td>
									)}
									{authState && (
										<ProductToProjectPopup
											dataProduct={i}
											isBlur={tariffsBlur.includes(data?.tariff?.id)}
										/>
									)}
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>

			<TableMobile
				data={data}
				authState={authState}
				countries={countries}
				currentPriceValue={currentPriceValue}
				productsList={productsList}
				tariff={tariff}
				settingsParam={settingsParam}
			/>
		</>
	);
};
