import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import CompanyService from 'shared/services/company.service';

export const useCompanyTypes = () => {
	const { isPending, isError, isSuccess, data, error } = useQuery({
		queryFn: () => CompanyService.getTypesCompany(),
		queryKey: ['get types company'],
		select: ({ data }) => data
	});

	const typesOptions = useMemo(() => {
		return (
			data?.map(item => ({
				label: item.name,
				value: item.key,
				help: item.name,
			})) || []
		);
	}, [data]);

	return {
		isPending,
		isError,
		isSuccess,
		companyTypesData: data,
		error,
		typesOptions
	};
};
