export const formatTimeZone = (timeZone: string) => {
	if (!timeZone) return null;
	// Разделяем строку по символу '/' и преобразуем первую букву в заглавную
	const parts = timeZone.split('/');
	if (parts.length !== 2) {
		throw new Error('Неверный формат часового пояса');
	}

	// Преобразуем части в удобочитаемый формат
	const region =
		parts[0].charAt(0).toUpperCase() + parts[0].slice(1).toLowerCase();
	const city =
		parts[1].charAt(0).toUpperCase() + parts[1].slice(1).toLowerCase();

	return `${region}/${city}`;
};
