import React, { FC } from 'react'

interface IProps {
	count: number
	allCount?: number
	nextText?: string
}

export const Rating: FC<IProps> = ({ count, allCount, nextText }) => {
	const star = [1, 2, 3, 4, 5]
	return (
		<>
			<ul className='stars'>
				{star.map(item => (
					<li key={item}>
						<svg
							width='25'
							height='23'
							viewBox='0 0 25 23'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
							preserveAspectRatio='none'
						>
							<path
								d='M12.5 3.68429L14.5832 8.35773L14.9354 9.14793L15.7958 9.23874L20.8842 9.77579L17.0833 13.2012L16.4406 13.7804L16.6201 14.6267L17.6817 19.6321L13.2494 17.0756L12.5 16.6434L11.7506 17.0756L7.31825 19.6321L8.3799 14.6267L8.55941 13.7804L7.91672 13.2012L4.11576 9.77579L9.2042 9.23874L10.0646 9.14793L10.4168 8.35773L12.5 3.68429Z'
								fill={item <= count ? '#F1C644' : ''}
								stroke='#F1C644'
								strokeWidth='1'
							/>
						</svg>
					</li>
				))}
			</ul>
			<p className='points'>
				{count} {allCount ? `/${allCount}` : ''}
				<span>{nextText}</span>
			</p>
		</>
	)
}
