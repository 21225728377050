import { FC } from 'react';
import { Link } from 'react-router-dom';
import { URL_SITE } from 'shared/constants';

interface IProps {
	title?: string;
	data?: any[];
}
export const ListPartners: FC<IProps> = ({ data, title }) => {
	return (
		<div className='supplier-partner'>
			<p>{title}</p>
			<ul className='supplier-partner__list'>
				{data?.map((item, i) => (
					<li key={item?.name}>
						{item?.logo ? (
							<>
								{item.slug ? (
									<img
										key={item.name}
										alt={item?.name}
										src={URL_SITE + '/' + item?.logo}
									/>
								) : (
									<Link key={item.name} to={`/suppliers/${item.slug}`}>
										<img alt={item?.name} src={URL_SITE + '/' + item?.logo} />
									</Link>
								)}
							</>
						) : (
							<Link to={`/suppliers/${item.name}`}>{item.name}</Link>
						)}
					</li>
				))}
			</ul>
		</div>
	);
};
