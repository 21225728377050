import clsx from 'clsx';
import DeleteRequest from 'entities/suppliers/delete-request';
import { useRequestToSupplier } from 'features/suppliers/api/useRequestToSupplier';
import { CardRequest } from 'features/suppliers/ui/card-request';
import DealTookPlace from 'features/suppliers/ui/deal-took-place';
import { useEffect, useState } from 'react';
import { TSupplier } from 'shared/services/types';
import { TPartner } from 'shared/services/types/partner.types';
import './RequestSuppliers.scss';

const RequestSuppliers = () => {
	const [currentRequest, setCurrentRequest] =
		useState<TPartner.RequestToPartner>();
	const [archiveVisible, setArchiveVisible] = useState(true);
	const { data: requestList, isPending } = useRequestToSupplier();

	const [archiveList, setArchiveList] = useState<TSupplier.RequestDataMini[]>(
		[]
	);
	const [requestsList, setRequestsList] = useState<TSupplier.RequestDataMini[]>(
		[]
	);
	const [isModalVisible, setModalVisible] = useState(false);
	const [isModalVisibleDealTookPlace, setModalVisibleDealTookPlace] =
		useState(false);

	const onToggleVisibleArchive = () => {
		setArchiveVisible(prev => !prev);
	};
	const openModal = (data: TPartner.RequestToPartner) => {
		setCurrentRequest(data);
		setModalVisible(true);
	};

	const closeModal = () => {
		setModalVisible(false);
	};

	const openModalDealTookPlace = (data: TPartner.RequestToPartner) => {
		setCurrentRequest(data);
		setModalVisibleDealTookPlace(true);
	};

	const closeModalDealTookPlace = () => {
		setModalVisibleDealTookPlace(false);
	};

	useEffect(() => {
		// const archive = requestList?.results?.filter(item => item.archived);
		const archive = requestList?.results?.filter(item => !item.id);
		const requestsList = requestList?.results?.filter(item => item.id);
		setArchiveList(archive || []);
		setRequestsList(requestsList || []);
	}, [requestList?.results]);

	return (
		<div className='requests-suppliers'>
			<h1>Запросы в работе:</h1>
			<div className='requests-suppliers__list'>
				{!isPending && !requestList?.results?.length && <>Нет обращений</>}
				{requestsList?.map(item => (
					<CardRequest
						key={item.id}
						data={item}
						onCloseRequest={openModal}
						onDealTookPlace={openModalDealTookPlace}
					/>
				))}
				{!!archiveList.length && (
					<div
						className={clsx(
							`request-supplier__archive-btn`,
							archiveVisible && 'active'
						)}
					>
						<button onClick={onToggleVisibleArchive}>
							Архив
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='19'
								height='18'
								viewBox='0 0 19 18'
								fill='none'
							>
								<path
									d='M14.75 8.75L9.5 13.25L4.25 8.75'
									stroke='#333333'
									strokeWidth='1.5'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
							</svg>
						</button>
					</div>
				)}
				{archiveVisible &&
					archiveList?.map(item => (
						<CardRequest
							data={item}
							key={item.id}
							onCloseRequest={openModal}
							onDealTookPlace={openModalDealTookPlace}
						/>
					))}
			</div>
			<DeleteRequest
				isOpen={isModalVisible}
				onClose={closeModal}
				data={currentRequest!}
			/>
			<DealTookPlace
				data={currentRequest}
				isOpen={isModalVisibleDealTookPlace}
				onClose={closeModalDealTookPlace}
			/>
		</div>
	);
};

export default RequestSuppliers;
