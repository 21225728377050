import RecoveryPass from 'features/auth/ui/recovery';
import SourcesStatistics from 'features/statistics/ui/sources-statistics';
import AccountPage from 'pages/account';
import { ProfilePage } from 'pages/account/profile';
import BuyersPage from 'pages/buyers';
import ForSuppliersPage from 'pages/for-suppliers';
import LoginPage from 'pages/login';
import Main from 'pages/main';
import NotFound from 'pages/not-found';
import PrivacyPolicy from 'pages/privacy_policy';
import Projects from 'pages/projects';
import ProjectsDetail from 'pages/projects/[id]';
import RegisterPage from 'pages/register';
import Report from 'pages/report';
import SuppliersPage from 'pages/suppliers';
import SupplierPage from 'pages/suppliers/[id]';
import Term from 'pages/term';
import { createBrowserRouter } from 'react-router-dom';
import { Layout } from 'shared/ui/layout';
import ProfileChat from 'widgets/chat/profile-chat';
import ProfileEmployees from 'widgets/user-profile/employees';
import ProfileHelp from 'widgets/user-profile/help';
import BecomePartners from 'widgets/user-profile/partner';
import ProfilePassChange from 'widgets/user-profile/pass-change';
import RequestSuppliers from 'widgets/user-profile/request-suppliers';
import BecomeSupplier from 'widgets/user-profile/supplier';
import ProfileTariffs from 'widgets/user-profile/tarrifs';
import { ProtectedRoute } from '../protected-route';

export const router = createBrowserRouter([
	{
		path: '/*',
		element: <NotFound />
	},
	{
		path: '/',
		element: <Main />
	},
	{
		path: 'product',
		element: <Main />
	},
	{
		path: 'report',
		element: <Report />
	},

	{
		path: '/login',
		element: <LoginPage />
	},
	{
		path: '/register/*',
		element: <RegisterPage />
	},
	{
		path: '/recovery/*',
		element: (
			<Layout>
				<RecoveryPass />
			</Layout>
		)
	},
	{
		path: '/account',
		element: (
			<ProtectedRoute>
				<AccountPage />
			</ProtectedRoute>
		),
		children: [
			{
				path: 'profile',
				element: <ProfilePage />
			},
			{
				path: 'tariffs',
				element: <ProfileTariffs />
			},
			{
				path: 'employees',
				element: <ProfileEmployees />
			},
			{
				path: 'password-change/*',
				element: <ProfilePassChange />
			},
			{
				path: 'help',
				element: <ProfileHelp />
			},
			{
				path: 'chat',
				element: <ProfileChat />
			},
			{
				path: 'partner',
				element: <BecomePartners />
			},
			{
				path: 'supplier',
				element: <BecomeSupplier />
			},
			{
				path: 'sources-statistics',
				element: <SourcesStatistics />
			},
			{
				path: 'request-suppliers',
				element: <RequestSuppliers />
			}
		]
	},
	{
		path: '/projects/',
		children: [
			{
				index: true,
				element: <Projects />
			},
			{
				path: ':id',
				element: <ProjectsDetail />
			}
		]
	},

	{
		path: '/for-suppliers',
		element: <ForSuppliersPage />
	},
	{
		path: '/buyers',
		element: <BuyersPage />
	},

	{
		path: '/suppliers',
		children: [
			{
				index: true,
				element: <SuppliersPage />
			},
			{
				path: ':name',
				element: <SupplierPage />
			}
		]
	},

	{
		path: '/terms_of_use',
		element: <Term />
	},
	{
		path: '/privacy_policy',
		element: <PrivacyPolicy />
	}
]);
