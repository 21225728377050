import clsx from 'clsx';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import './CustomSelect.scss';

const allOption = ({ innerProps, label, data }) => (
	<div
		className='custom-option'
		style={{
			display: 'flex',
			alignItems: 'center',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			gap: '10px'
		}}
		{...innerProps}
	>
		<img src={data.flag} alt={label} width='24' height='16' />
		<span>{label}</span>
	</div>
);

export const MultiSelectAdditional = ({
	multiSelectOptions,
	onChange,
	defaultRef
}) => {
	const [value, setValue] = useState([]);
	const customStyles = {
		valueContainer: provided => ({
			...provided,
			height: '100%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center'
		}),
		control: provided => ({
			...provided,
			fontFamily: "'Montserrat', sans-serif",
			minHeight: '40px',
			width: '100%',
			border: '1px solid #8A7DE7',
			borderRadius: '10px',
			marginTop: '10px',
			marginBottom: '15px',
			paddingLeft: '10px'
		}),
		multiValue: provided => ({
			...provided,
			borderRadius: '5px',
			background: '#8A7DE7',
			color: 'white'
		}),
		multiValueLabel: provided => ({
			...provided,
			color: 'white'
		})
	};

	const handleChange = selectedOptions => {
		setValue(selectedOptions);
		if (onChange) {
			onChange(selectedOptions);
		}
	};
	const defaultValue =
		defaultRef &&
		defaultRef.map(i => {
			return {
				value: i.id,
				label: i.name,
				flag: i.flag || i.country_id
			};
		});

	useEffect(() => {
		const defaultValue =
			defaultRef &&
			multiSelectOptions.filter(i => {
				const ids = defaultRef;
				return ids.includes(i.value);
			});

		setValue(defaultValue);
	}, [defaultRef]);

	const MenuWithSearch = props => {
		const { innerProps } = props;
		const { inputValue } = props.selectProps;

		return (
			<div className='multi-select-search__menu' {...innerProps}>
				<div className='multi-select-search__search'>
					<label>
						<input
							type='text'
							placeholder='Поиск'
							value={inputValue}
							onChange={e => props.selectProps.onInputChange(e.target.value)}
						/>
						<i>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='27'
								height='27'
								viewBox='0 0 27 27'
								fill='none'
							>
								<path
									d='M23.625 23.625L16.875 16.875M3.375 11.25C3.375 12.2842 3.57869 13.3082 3.97445 14.2636C4.3702 15.2191 4.95027 16.0872 5.68153 16.8185C6.4128 17.5497 7.28093 18.1298 8.23637 18.5256C9.19181 18.9213 10.2158 19.125 11.25 19.125C12.2842 19.125 13.3082 18.9213 14.2636 18.5256C15.2191 18.1298 16.0872 17.5497 16.8185 16.8185C17.5497 16.0872 18.1298 15.2191 18.5256 14.2636C18.9213 13.3082 19.125 12.2842 19.125 11.25C19.125 10.2158 18.9213 9.19181 18.5256 8.23637C18.1298 7.28093 17.5497 6.4128 16.8185 5.68153C16.0872 4.95027 15.2191 4.3702 14.2636 3.97445C13.3082 3.57869 12.2842 3.375 11.25 3.375C10.2158 3.375 9.19181 3.57869 8.23637 3.97445C7.28093 4.3702 6.4128 4.95027 5.68153 5.68153C4.95027 6.4128 4.3702 7.28093 3.97445 8.23637C3.57869 9.19181 3.375 10.2158 3.375 11.25Z'
									stroke='#6A6A6B'
									strokeWidth='1.5'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
							</svg>
						</i>
					</label>
				</div>
				{props.children}
			</div>
		);
	};

	const CustomIndicator = props => {
		return (
			<div className='multi-select-search__add-btn' {...props.innerProps}>
				<svg
					width='14'
					height='14'
					viewBox='0 0 14 14'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M1 7H7M7 7H13M7 7V13M7 7V1'
						stroke='#8A7DE7'
						strokeWidth='1.5'
						strokeLinecap='round'
					/>
				</svg>
			</div>
		);
	};

	const customNoOptionsMessage = () => 'Язык не найден';

	const customOption = allOption;
	return (
		<Select
			options={multiSelectOptions}
			isMulti
			classNamePrefix={'multi-select-search'}
			getOptionValue={option => option.value}
			getOptionLabel={option => option.label}
			onChange={handleChange}
			placeholder=''
			value={value}
			defaultValue={defaultValue}
			noOptionsMessage={customNoOptionsMessage}
			searchable={true}
			components={{
				Menu: MenuWithSearch,
				DropdownIndicator: CustomIndicator,
				Option: customOption
			}}
		/>
	);
};

export const MultiSelectCompanyTypes = ({
	multiSelectOptions,
	onChange,
	disabled,
	defaultRef
}) => {
	const [value, setValue] = useState(defaultRef);
	const setDefaultValue = data => {
		const matchingValues = [];
		for (let i = 0; i < multiSelectOptions.length; i++) {
			if (data.includes(multiSelectOptions[i].id)) {
				matchingValues.push(multiSelectOptions[i]);
			}
		}
		return matchingValues;
	};

	useEffect(() => {
		const defaultValue = defaultRef && setDefaultValue(defaultRef);
		setValue(defaultValue);
	}, [defaultRef]);

	const handleChange = selectedOptions => {
		setValue(selectedOptions);

		if (disabled) return null;

		if (selectedOptions.length <= 3) {
			if (onChange) {
				onChange(selectedOptions);
			}
		}
	};

	return (
		<div className={clsx('select-block', !!value?.length && 'active')}>
			<div className='input-placeholder'>
				Вид предприятия (не более 3-х категорий)<span>*</span>
			</div>
			<Select
				options={multiSelectOptions}
				isMulti
				isDisabled={disabled}
				required
				getOptionValue={option => option.id}
				getOptionLabel={option => option.name}
				onChange={handleChange}
				value={value}
				placeholder=''
				classNamePrefix={'multi-select-stroke'}
			/>
		</div>
	);
};

export const HeaderCountriesSelect = ({ onChange, selectOptions }) => {
	const KIRA_COUNTRY = Cookies.get('kira-country');
	const [defaultValue, setDefaultValue] = useState();
	const [value, setValue] = useState();
	const [isActive, setIsActive] = useState(false);

	const customOption = ({ innerProps, label, data }) => (
		<div className='settings-select__option' {...innerProps}>
			<img src={data.flag} />
			<span>{label}</span>
		</div>
	);

	const CustomIndicator = props => {
		return (
			<div className='settings-select__arrow' {...props.innerProps}>
				<svg
					width='14'
					height='8'
					viewBox='0 0 14 8'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M12.8327 1.5L6.99935 6.5L1.16602 1.5'
						stroke='#333333'
						strokeWidth='1.5'
						strokeLinecap='round'
						strokeLinejoin='round'
					/>
				</svg>
			</div>
		);
	};

	const singleAllValue = ({ data }) => (
		<div className='settings-select__custom-value'>
			<img src={data.flag} alt={data.label} />
			<span>{data.label}</span>
		</div>
	);

	const handleChange = selectedOption => {
		setIsActive(!!selectedOption);
		setValue(selectedOption);
		Cookies.set('kira-country', selectedOption.value);
		if (onChange) {
			onChange(selectedOption);
		}
	};

	useEffect(() => {
		const defaultValue =
			KIRA_COUNTRY && selectOptions.find(i => KIRA_COUNTRY === i.value);

		setDefaultValue(defaultValue);
		setIsActive(!!defaultValue);
	}, [KIRA_COUNTRY, selectOptions]);

	useEffect(() => {
		if (!defaultValue) return;
		const country = {
			...defaultValue,
			label: defaultValue.label
		};

		setValue(country);
	}, [defaultValue]);

	return (
		<div className={`setting-select ${isActive ? 'active' : ''}`}>
			<p className='setting-select__placeholder'>Выберите страну:</p>

			<Select
				options={selectOptions}
				isSearchable={false}
				classNamePrefix={'settings-select'}
				components={{
					Option: customOption,
					DropdownIndicator: CustomIndicator,
					SingleValue: singleAllValue
				}}
				onChange={handleChange}
				// defaultValue={defaultValue}
				value={value}
				placeholder=''
			/>
		</div>
	);
};

const CustomIndicator = props => {
	return (
		<div className='settings-select__arrow' {...props.innerProps}>
			<svg
				width='14'
				height='8'
				viewBox='0 0 14 8'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M12.8327 1.5L6.99935 6.5L1.16602 1.5'
					stroke='#333333'
					strokeWidth='1.5'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
			</svg>
		</div>
	);
};

export const SourceSelect = ({ sourceOptions, onChange, defaultRef }) => {
	const [isActive, setIsActive] = useState(false);

	const customOption = ({ innerProps, label, data }) => (
		<div className='settings-select__option' {...innerProps}>
			<span>{label}</span>
		</div>
	);

	const CustomIndicator = props => {
		return (
			<div className='settings-select__arrow' {...props.innerProps}>
				<svg
					width='14'
					height='8'
					viewBox='0 0 14 8'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M12.8327 1.5L6.99935 6.5L1.16602 1.5'
						stroke='#333333'
						strokeWidth='1.5'
						strokeLinecap='round'
						strokeLinejoin='round'
					/>
				</svg>
			</div>
		);
	};

	const singleAllValue = ({ data }) => (
		<div className='settings-select__custom-value'>
			<span>{data.label}</span>
		</div>
	);

	const selectOptions = sourceOptions
		? sourceOptions.map(i => ({
				value: i.id,
				label: i.name
		  }))
		: [];

	const handleChange = selectedOption => {
		setIsActive(!!selectedOption);
		if (onChange) {
			onChange(selectedOption);
		}
	};

	const defaultValue =
		defaultRef && selectOptions.find(i => defaultRef === i.label);

	return (
		<div className={`setting-select ${isActive ? 'active' : ''}`}>
			<p className='setting-select__placeholder'>Выберите источник</p>

			<Select
				options={selectOptions}
				isSearchable={false}
				classNamePrefix={'settings-select'}
				components={{
					Option: customOption,
					DropdownIndicator: CustomIndicator,
					SingleValue: singleAllValue
				}}
				onChange={handleChange}
				defaultValue={defaultValue}
				placeholder=''
			/>
		</div>
	);
};

export const MultiSelectCounties = ({
	countriesOptions,
	onChange,
	defaultRef,
	isOpen,
	onClose
}) => {
	const handleChange = selectedOptions => {
		if (onChange) {
			onChange(selectedOptions);
		}
	};

	const selectOptions = countriesOptions
		? countriesOptions.map(i => ({
				value: i.id,
				label: i.name,
				flag: i.flag
		  }))
		: [];

	const defaultValue =
		defaultRef && selectOptions.find(i => defaultRef === i.label);

	const MenuWithSearch = props => {
		const { innerProps } = props;
		const { inputValue } = props.selectProps;

		return (
			<div className='multi-select-search__menu' {...innerProps}>
				<div className='multi-select-search__search'>
					<label>
						<input
							type='text'
							placeholder='Поиск'
							value={inputValue}
							onChange={e => props.selectProps.onInputChange(e.target.value)}
						/>
						<i>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='27'
								height='27'
								viewBox='0 0 27 27'
								fill='none'
							>
								<path
									d='M23.625 23.625L16.875 16.875M3.375 11.25C3.375 12.2842 3.57869 13.3082 3.97445 14.2636C4.3702 15.2191 4.95027 16.0872 5.68153 16.8185C6.4128 17.5497 7.28093 18.1298 8.23637 18.5256C9.19181 18.9213 10.2158 19.125 11.25 19.125C12.2842 19.125 13.3082 18.9213 14.2636 18.5256C15.2191 18.1298 16.0872 17.5497 16.8185 16.8185C17.5497 16.0872 18.1298 15.2191 18.5256 14.2636C18.9213 13.3082 19.125 12.2842 19.125 11.25C19.125 10.2158 18.9213 9.19181 18.5256 8.23637C18.1298 7.28093 17.5497 6.4128 16.8185 5.68153C16.0872 4.95027 15.2191 4.3702 14.2636 3.97445C13.3082 3.57869 12.2842 3.375 11.25 3.375C10.2158 3.375 9.19181 3.57869 8.23637 3.97445C7.28093 4.3702 6.4128 4.95027 5.68153 5.68153C4.95027 6.4128 4.3702 7.28093 3.97445 8.23637C3.57869 9.19181 3.375 10.2158 3.375 11.25Z'
									stroke='#6A6A6B'
									strokeWidth='1.5'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
							</svg>
						</i>
					</label>
				</div>
				{props.children}
			</div>
		);
	};

	const CustomIndicator = props => {
		return (
			<div className='multi-select-search__add-btn' {...props.innerProps}>
				<svg
					width='14'
					height='14'
					viewBox='0 0 14 14'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M1 7H7M7 7H13M7 7V13M7 7V1'
						stroke='#8A7DE7'
						strokeWidth='1.5'
						strokeLinecap='round'
					/>
				</svg>
			</div>
		);
	};

	const customNoOptionsMessage = () => 'Страна не найдена';

	const customOption = allOption;

	return (
		<Select
			options={selectOptions}
			isMulti
			classNamePrefix={'multi-select-search'}
			getOptionValue={option => option.value}
			getOptionLabel={option => option.label}
			onChange={handleChange}
			placeholder=''
			defaultValue={defaultValue}
			noOptionsMessage={customNoOptionsMessage}
			searchable={true}
			// menuIsOpen
			components={{
				Menu: MenuWithSearch,
				DropdownIndicator: CustomIndicator,
				Option: customOption
			}}
		/>
	);
};
