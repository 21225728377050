import { FC } from 'react';
import { CustomLink } from 'shared/ui/custom-link';
import { createLink } from 'shared/utils';
import MainLogo from '../../assets/MainLogo';
import './Footer.scss';

interface IProps {
	isOtherDomain?: boolean;
}
export const Footer: FC<IProps> = ({ isOtherDomain }) => {
	const currentDate = new Date().getFullYear();

	return (
		<div className='footer'>
			<div className='container'>
				<div className='footer__top'>
					<div className='footer__menu'>
						{/* <Link to='/about'>О проекте</Link> */}
						<CustomLink
							external={isOtherDomain}
							to={createLink('/', isOtherDomain)}
						>
							Поиск товаров
						</CustomLink>
						<CustomLink
							external={isOtherDomain}
							to={createLink('/suppliers', isOtherDomain)}
						>
							Каталог поставщиков
						</CustomLink>
						<CustomLink
							external={isOtherDomain}
							to={createLink('/account/partner', isOtherDomain)}
						>
							Стать партнером
						</CustomLink>
						<CustomLink
							external={isOtherDomain}
							to={createLink('/buyers', isOtherDomain)}
						>
							Покупателям
						</CustomLink>
						<CustomLink
							external={isOtherDomain}
							to={createLink('/for-suppliers', isOtherDomain)}
						>
							Поставщикам
						</CustomLink>
						<a
							target='_blank˝'
							href={`https://t.me/Kira_TovaryOptomDlyaProdazhi_Bot`}
						>
							Телеграм Бот{' '}
						</a>
					</div>
					<div style={{ width: '200px' }}>
						<div className='footer__logo'>
							<MainLogo />
							<p>Собрал поставщиков со всего мира</p>
						</div>
					</div>
				</div>
			</div>

			<div className='footer-bottom'>
				<div className={'container'}>
					<div style={{ display: 'flex' }}>
						<div className='footer-bottom__row'>
							<div className='bgsvg'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='1429'
									height='58'
									viewBox='0 0 1429 58'
									fill='none'
									preserveAspectRatio='none'
								>
									<path d='M0 0H1389.31L1429 58H0V0Z' fill='#EBF1F8' />
								</svg>
							</div>
							<p className='footer-bottom-p'>
								© {currentDate}{' '}
								<span>KИRA Сервис по поиску товаров и поставщиков.</span>
								<span> Все права защищены</span>
							</p>
							<p>
								<CustomLink
									external={isOtherDomain}
									to={createLink('/terms_of_use', isOtherDomain)}
								>
									Пользовательское соглашение
								</CustomLink>
							</p>
							<p>
								<CustomLink
									external={isOtherDomain}
									to={createLink('/privacy_policy', isOtherDomain)}
								>
									Политика конфиденциальности
								</CustomLink>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
