import { useHelpAction } from 'features/help/api/useHelp';
import { useGetUser } from 'features/profile/api/useGetUser';
import { FC, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useAlertMessage, useOutsideClick } from 'shared/hooks';
import { FormWrapper } from 'shared/ui/form/form-wrapper';
import { Input } from 'shared/ui/form/input';

interface IProps {
	isOpen: boolean;
	onClose: () => void;
}

const HelpModal: FC<IProps> = ({ isOpen, onClose }) => {
	const { sendFeedback } = useHelpAction();
	const { userData } = useGetUser();
	const { ref } = useOutsideClick(onClose);
	const methods = useForm();

	const [selectedFile, setSelectedFile] = useState<File | undefined>();
	const [selectedFileName, setSelectedFileName] = useState('');

	const handleImageChange = (e: any) => {
		const file = e.target.files[0];

		if (file) {
			setSelectedFileName(file);

			const reader = new FileReader();

			methods.clearErrors('message');

			reader.onload = event => {
				//@ts-ignore
				setSelectedFile(file);
				setSelectedFileName(file.name);
			};

			reader.readAsDataURL(file);
		}
	};

	const handleRemoveImage = () => {
		setSelectedFile(undefined);
		setSelectedFileName('');
	};

	const { addAlertMessage } = useAlertMessage();

	const onSubmit = async (dataForm: FieldValues) => {
		const data = {
			theme: 'Проблема с регистрацией',
			...dataForm,
			//@ts-ignore
			email: userData.email,
			file: selectedFile
		} as any;

		try {
			await sendFeedback.mutateAsync(data);
			addAlertMessage('Информация успешно отправлена', 'success');

			onClose();
		} catch (error: any) {
			addAlertMessage(error, 'error');
		}
	};

	return (
		<>
			<div className={`modal ${isOpen ? 'show' : 'hide'}`}>
				<div className='modal__window' ref={ref}>
					<div className='modal__header'>
						<p>
							Отправьте нам свои реквизиты и мы поможем вам зарегистрироваться
						</p>
						<button className='modal__close' onClick={onClose}>
							<svg
								width='32'
								height='32'
								viewBox='0 0 32 32'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M19.75 12.25L12.25 19.75M12.25 12.25L19.7499 19.75'
									stroke='#8A7DE7'
									strokeWidth='1.5'
									strokeLinecap='round'
								/>
								<path
									d='M31 16C31 23.0711 31 26.6066 28.8033 28.8033C26.6066 31 23.0711 31 16 31C8.92893 31 5.3934 31 3.1967 28.8033C1 26.6066 1 23.0711 1 16C1 8.92893 1 5.3934 3.1967 3.1967C5.3934 1 8.92893 1 16 1C23.0711 1 26.6066 1 28.8033 3.1967C30.2639 4.65731 30.7533 6.70983 30.9173 10'
									stroke='#8A7DE7'
									strokeWidth='1.5'
									strokeLinecap='round'
								/>
							</svg>
						</button>
					</div>
					<div className='modal__form'>
						<FormWrapper methods={methods} onSubmit={onSubmit}>
							<Input
								control={methods.control}
								name={`fio`}
								label='ФИО'
								placeholder='Введите ФИО'
								required
								error={methods.formState.errors}
								rules={{ required: 'Обязательное поле для заполнения' }}
							/>

							<Input
								control={methods.control}
								name={`message`}
								label='Сообщение'
								textArea
								placeholder='Введите сообщение '
								required={!selectedFile}
								error={methods.formState.errors}
								rules={{
									required: !selectedFile
										? 'Обязательное поле для заполнения'
										: undefined
								}}
							/>

							<div className='input-file'>
								<label>
									<input
										type='file'
										accept='.pdf,.doc,.docx,.txt,.xlsx'
										onChange={handleImageChange}
									/>
									Прикрепите файл
									<i>
										<svg
											width='18'
											height='18'
											viewBox='0 0 18 18'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												d='M14.2579 9.82478C16.2474 7.92037 16.2474 4.83272 14.2579 2.92831C12.2683 1.0239 9.04267 1.0239 7.05314 2.92831M5.93813 13.3551L11.8563 7.69016C12.5668 7.01001 12.5668 5.90728 11.8563 5.22713C11.1457 4.54699 9.99372 4.54698 9.28317 5.22713L3.4079 10.851C2.05786 12.1433 2.05786 14.2385 3.4079 15.5308C4.75794 16.8231 6.94678 16.8231 8.29681 15.5308L11.2773 12.6778M2.25 7.52596L4.65157 5.22713'
												stroke='#333333'
												strokeWidth='1.5'
												strokeLinecap='round'
											/>
										</svg>
									</i>
								</label>
								{selectedFile && (
									<p className='file-name'>
										<span className='icon-doc'>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='21'
												height='21'
												viewBox='0 0 21 21'
												fill='none'
											>
												<path
													fillRule='evenodd'
													clipRule='evenodd'
													d='M2.9888 0.00195312C2.06939 0.00195312 1.32422 0.747781 1.32422 1.66686V19.3334C1.32422 20.2532 2.06939 20.9983 2.9888 20.9983H18.0084C18.9275 20.9983 19.6733 20.2532 19.6733 19.3334V6.66617L13.4711 0.00195312H2.9888Z'
													fill='#45B058'
												/>
												<path
													d='M7.68723 17.6983C7.61636 17.6983 7.55106 17.6687 7.51005 17.6097L6.2868 15.9789L5.05797 17.6097C5.01663 17.6687 4.95166 17.6983 4.88078 17.6983C4.75084 17.6983 4.64453 17.5978 4.64453 17.462C4.64453 17.4148 4.65602 17.3616 4.69178 17.3203L5.96819 15.6366L4.7807 14.0531C4.75117 14.0118 4.73312 13.9645 4.73312 13.9173C4.73312 13.8051 4.82763 13.681 4.9697 13.681C5.04058 13.681 5.11145 13.7165 5.1587 13.7755L6.2868 15.2941L7.41489 13.7696C7.45656 13.7106 7.5212 13.681 7.59241 13.681C7.71644 13.681 7.83456 13.7814 7.83456 13.9173C7.83456 13.9645 7.82275 14.0118 7.79289 14.0531L6.60541 15.6307L7.88214 17.3203C7.91167 17.3616 7.92316 17.4088 7.92316 17.4561C7.92316 17.586 7.81684 17.6983 7.68723 17.6983ZM10.9649 17.6569H9.17495C8.97972 17.6569 8.82025 17.4974 8.82025 17.3025V13.9173C8.82025 13.7873 8.92656 13.681 9.07422 13.681C9.20416 13.681 9.3108 13.7873 9.3108 13.9173V17.2198H10.9649C11.0827 17.2198 11.1775 17.3143 11.1775 17.4325C11.1775 17.5624 11.0827 17.6569 10.9649 17.6569ZM13.2558 17.7278C12.6711 17.7278 12.2098 17.5329 11.8735 17.2372C11.8203 17.19 11.7967 17.125 11.7967 17.0601C11.7967 16.9419 11.8853 16.812 12.027 16.812C12.0743 16.812 12.1274 16.8238 12.1691 16.8592C12.4408 17.1014 12.8188 17.2904 13.2739 17.2904C13.9771 17.2904 14.2012 16.9124 14.2012 16.6108C14.2012 15.5946 11.8679 16.1561 11.8679 14.7497C11.8679 14.1 12.4467 13.6567 13.2207 13.6567C13.7287 13.6567 14.1661 13.8103 14.485 14.0761C14.5382 14.1233 14.568 14.1883 14.568 14.2533C14.568 14.3714 14.4673 14.4895 14.3373 14.4895C14.2898 14.4895 14.2366 14.4718 14.1956 14.4364C13.9059 14.2001 13.5459 14.0938 13.1853 14.0938C12.7128 14.0938 12.3758 14.3478 12.3758 14.7202C12.3758 15.6065 14.7094 15.0982 14.7094 16.5695C14.7094 17.1431 14.3196 17.7278 13.2558 17.7278Z'
													fill='white'
												/>
												<path
													fillRule='evenodd'
													clipRule='evenodd'
													d='M19.673 6.67778V7.00591H15.4727C15.4727 7.00591 13.4015 6.59247 13.4622 4.80518C13.4622 4.80518 13.5302 6.67778 15.4317 6.67778H19.673Z'
													fill='#349C42'
												/>
												<path
													opacity='0.5'
													fillRule='evenodd'
													clipRule='evenodd'
													d='M13.4688 0V4.77783C13.4688 5.3212 13.831 6.678 15.4716 6.678H19.672L13.4688 0Z'
													fill='white'
												/>
											</svg>
										</span>

										{selectedFileName}

										<span className='remove-img' onClick={handleRemoveImage}>
											<svg
												width='10'
												height='10'
												viewBox='0 0 10 10'
												fill='none'
												xmlns='http://www.w3.org/2000/svg'
											>
												<path
													d='M9 1.00003L1.00003 9M1 1L8.99997 8.99997'
													stroke='#333333'
													strokeOpacity='0.4'
													strokeLinecap='round'
												/>
											</svg>
										</span>
									</p>
								)}
							</div>
							<button type='submit' className='btn'>
								Отправить
							</button>
						</FormWrapper>
					</div>
				</div>
			</div>
		</>
	);
};

export default HelpModal;
