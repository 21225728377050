import Slider from '@mui/material/Slider';
import { SearchIcon, SortIcon } from 'assets/SearchBarIcons';
import { CardSuppliers } from 'entities/suppliers/card-suppliers';
import { FilterDropdown } from 'entities/suppliers/filter-dropdown';
import { useCompanyTypes } from 'features/company/api/useCompanyTypes';
import { useManufactureList } from 'features/product/api';
import { useSupplierList } from 'features/suppliers/api';
import { useGetCountriesRegion } from 'features/utils/api';

import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useQueryUrlHelper } from 'shared/hooks';
import { TCommon } from 'shared/services/types';
import { Accordion } from 'shared/ui/accordion';
import { DownloadMore } from 'shared/ui/form/download-more';
import { Loader } from 'shared/ui/loader';
import { CurrencyBlock } from 'widgets/currency-block';
import './Suppliers.scss';

const defaultFilter = {
	page_size: 50
};

const Suppliers = () => {
	const { objectToQueryString, queryStringToObject } = useQueryUrlHelper();

	const urlQuery = queryStringToObject('', [
		'types',
		'country_ids',
		'manufacturers_ids'
	]);

	const navigate = useNavigate();
	const [filter, setFilter] = useState<any>({
		...defaultFilter,
		...urlQuery
	});

	const [searchValue, setSearchValue] = useState(urlQuery?.search || '');
	const [viewToggle, setViewToggle] = useState<'line' | 'grid'>('grid');
	const [openSidebar, setOpenSidebar] = useState(false);

	const { data: manufactureList } = useManufactureList();
	const { data } = useGetCountriesRegion();

	const { companyTypesData } = useCompanyTypes();

	useEffect(() => {
		const { page_size, ...rest } = filter;
		const newQueryString = objectToQueryString(rest);
		navigate(`?${newQueryString}`);
	}, [filter]);

	const manufactureListOptions = useMemo(() => {
		return manufactureList?.map(item => ({ id: item.id, name: item.name }));
	}, [manufactureList]);

	const countries = useMemo(() => {
		const res = Object.entries(data || {});
		return res.reduce((acc: any, item) => {
			const country = item[1];

			return [...acc, ...country];
		}, []);
	}, [data]);

	const countriesListOptions = useMemo(() => {
		const res = Object.entries(data || {});
		return res.reduce((acc: any, item) => {
			const name = item[0];
			const country = item[1];
			const resCountries = country?.map(item => ({
				id: item.id,
				name: item.name
			}));
			return { ...acc, [name]: resCountries };
		}, {});
	}, [data]);

	const companyListOptions = useMemo(() => {
		return companyTypesData?.map(item => ({ id: item.key, name: item.name }));
	}, [companyTypesData]);

	const {
		supplierData,
		data: dataAllSupplier,
		fetchNextPage,
		isFetching,
		isLoading
	} = useSupplierList(filter);

	const [ownFilter, setOwnFilter] = useState<any>({
		rating: undefined
	});

	const onChangeOwnFilter = (name: string, item: any) => {
		if (['number', 'string'].includes(typeof item)) {
			setFilter((prev: any) => {
				const getValues = (name: string, item: any) => {
					const val = prev?.[name]?.filter((v: any) => v !== item);
					return val?.length ? val : undefined;
				};

				return {
					...prev,
					[name]: prev[name]?.includes(item)
						? getValues(name, item)
						: [...(prev[name] || []), item]
				};
			});
		} else if (Array.isArray(item)) {
			setFilter((prev: any) => {
				const [value] = item;
				return {
					...prev,
					[name]: value
				};
			});
		} else {
			setFilter((prev: any) => {
				return {
					...prev,
					[name]: prev[name]?.includes(item)
						? prev[name].filter((v: any) => v.id !== item.id)
						: [...(prev[name] || []), item]
				};
			});
		}
	};

	const resetFilter = () => {
		setOwnFilter({
			country_ids: [],
			types: [],
			manufacturers_ids: [],
			rating: undefined
		});

		navigate(``);
		setSearchValue('');
	};

	const onSearchSuppliers = () => {
		const country = ownFilter?.country_id?.map((item: any) => item.id);
		const companyType = ownFilter?.companyType?.map((item: any) => item.id);
		const manufacturers = ownFilter?.manufacturer?.map((item: any) => item.id);
		const rating = ownFilter?.rating;

		const min_rating = rating?.length ? Math.min(...rating) : undefined;

		const max_rating = rating?.length > 1 ? Math.max(...rating) : undefined;

		const data = {
			country_ids: country?.length ? country : undefined,
			types: companyType?.length ? companyType : undefined,
			manufacturers_ids: manufacturers?.length ? manufacturers : undefined,
			search: searchValue || undefined,

			min_rating: min_rating,
			max_rating: max_rating
		};
		setFilter((prev: any) => ({ ...prev, ...data }));
	};

	useEffect(() => {
		const isOne = Object.keys(ownFilter).length === 1;
		if (!isOne) {
			onSearchSuppliers();
		}
	}, [ownFilter]);

	const toggleSidebar = () => {
		setOpenSidebar(!openSidebar);
	};

	const toggleViewListLine = (isLine?: boolean) => {
		setViewToggle(isLine ? 'line' : 'grid');
	};

	const getDefaultValue = (key: string, list?: TCommon.TOptionOther[]) => {
		const queryValue = urlQuery?.[key];
		const queryArray = !Array.isArray(queryValue) ? [queryValue] : queryValue;

		return list?.filter(item => (queryArray || []).includes(item.id));
	};

	const defaultSlider = [
		urlQuery?.min_rating || 0,
		urlQuery?.max_rating || 5
	].map(item => Number(item));

	return (
		<div className='suppliers'>
			<div className='container '>
				<div className='container '>
					<CurrencyBlock />
				</div>
				<div className={`product-searchbar`}>
					<form
						onSubmit={e => {
							e.preventDefault();
						}}
					>
						<div className='product-searchbar-block'>
							<input
								type='text'
								value={searchValue}
								placeholder='Введите название поставщика'
								onChange={e => setSearchValue(e.target.value)}
							/>
						</div>
						<div className='product-searchbar-buttons'>
							<button
								className='product-searchbar-button'
								onClick={onSearchSuppliers}
							>
								<SearchIcon />
								Поиск
							</button>
							<button
								type='button'
								className='product-searchbar-button button-filter-mob'
								onClick={toggleSidebar}
							>
								<SortIcon />
							</button>
						</div>
					</form>
				</div>
				<div className='suppliers-list-view'>
					<div
						className={`view-toggle  ${
							viewToggle === 'line' ? 'line' : 'grid'
						}`}
					>
						<button
							type='button'
							className={`${viewToggle === 'line' ? 'active' : ''}`}
							onClick={() => toggleViewListLine(true)}
						>
							<svg
								width='23'
								height='23'
								viewBox='0 0 23 23'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<g opacity='0.4'>
									<path
										d='M2.62891 4.27168C2.62891 3.18745 2.62891 2.64533 3.97622 2.30851C5.32352 1.97168 7.49199 1.97168 11.8289 1.97168C16.1658 1.97168 18.3343 1.97168 19.6816 2.30851C21.0289 2.64533 21.0289 3.18745 21.0289 4.27168C21.0289 5.35591 21.0289 5.89803 19.6816 6.23485C18.3343 6.57168 16.1658 6.57168 11.8289 6.57168C7.49199 6.57168 5.32352 6.57168 3.97622 6.23485C2.62891 5.89803 2.62891 5.35591 2.62891 4.27168Z'
										fill='#1E2321'
									/>
									<path
										d='M2.62891 11.5002C2.62891 10.416 2.62891 9.87385 3.97622 9.53702C5.32352 9.2002 7.49198 9.2002 11.8289 9.2002C16.1658 9.2002 18.3343 9.2002 19.6816 9.53702C21.0289 9.87385 21.0289 10.416 21.0289 11.5002C21.0289 12.5844 21.0289 13.1265 19.6816 13.4634C18.3343 13.8002 16.1658 13.8002 11.8289 13.8002C7.49198 13.8002 5.32352 13.8002 3.97622 13.4634C2.62891 13.1265 2.62891 12.5844 2.62891 11.5002Z'
										fill='#1E2321'
									/>
									<path
										d='M2.62891 18.7287C2.62891 17.6445 2.62891 17.1024 3.97622 16.7655C5.32352 16.4287 7.49198 16.4287 11.8289 16.4287C16.1658 16.4287 18.3343 16.4287 19.6816 16.7655C21.0289 17.1024 21.0289 17.6445 21.0289 18.7287C21.0289 19.8129 21.0289 20.3551 19.6816 20.6919C18.3343 21.0287 16.1658 21.0287 11.8289 21.0287C7.49198 21.0287 5.32352 21.0287 3.97622 20.6919C2.62891 20.3551 2.62891 19.8129 2.62891 18.7287Z'
										fill='#1E2321'
									/>
								</g>
							</svg>
						</button>

						<button
							type='button'
							className={`${viewToggle === 'grid' ? 'active' : ''}`}
							onClick={() => toggleViewListLine()}
						>
							<svg
								width='23'
								height='23'
								viewBox='0 0 23 23'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<g opacity='0.4'>
									<path
										d='M1.91602 6.22949C1.91602 4.19656 1.91602 3.18009 2.54757 2.54854C3.17912 1.91699 4.19558 1.91699 6.22852 1.91699C8.26145 1.91699 9.27791 1.91699 9.90946 2.54854C10.541 3.18009 10.541 4.19656 10.541 6.22949C10.541 8.26242 10.541 9.27889 9.90946 9.91044C9.27791 10.542 8.26145 10.542 6.22852 10.542C4.19558 10.542 3.17912 10.542 2.54757 9.91044C1.91602 9.27889 1.91602 8.26242 1.91602 6.22949Z'
										fill='#1E2321'
									/>
									<path
										d='M12.459 16.7705C12.459 14.7376 12.459 13.7211 13.0905 13.0896C13.7221 12.458 14.7386 12.458 16.7715 12.458C18.8044 12.458 19.8209 12.458 20.4524 13.0896C21.084 13.7211 21.084 14.7376 21.084 16.7705C21.084 18.8034 21.084 19.8199 20.4524 20.4515C19.8209 21.083 18.8044 21.083 16.7715 21.083C14.7386 21.083 13.7221 21.083 13.0905 20.4515C12.459 19.8199 12.459 18.8034 12.459 16.7705Z'
										fill='#1E2321'
									/>
									<path
										d='M1.91602 16.7705C1.91602 14.7376 1.91602 13.7211 2.54757 13.0896C3.17912 12.458 4.19558 12.458 6.22852 12.458C8.26145 12.458 9.27791 12.458 9.90946 13.0896C10.541 13.7211 10.541 14.7376 10.541 16.7705C10.541 18.8034 10.541 19.8199 9.90946 20.4515C9.27791 21.083 8.26145 21.083 6.22852 21.083C4.19558 21.083 3.17912 21.083 2.54757 20.4515C1.91602 19.8199 1.91602 18.8034 1.91602 16.7705Z'
										fill='#1E2321'
									/>
									<path
										d='M12.459 6.22949C12.459 4.19656 12.459 3.18009 13.0905 2.54854C13.7221 1.91699 14.7386 1.91699 16.7715 1.91699C18.8044 1.91699 19.8209 1.91699 20.4524 2.54854C21.084 3.18009 21.084 4.19656 21.084 6.22949C21.084 8.26242 21.084 9.27889 20.4524 9.91044C19.8209 10.542 18.8044 10.542 16.7715 10.542C14.7386 10.542 13.7221 10.542 13.0905 9.91044C12.459 9.27889 12.459 8.26242 12.459 6.22949Z'
										fill='#1E2321'
									/>
								</g>
							</svg>
						</button>
					</div>
				</div>
				<div className='suppliers__row'>
					<div
						onClick={toggleSidebar}
						className={`overlay ${openSidebar ? 'open' : ''}`}
					/>
					<div className={`suppliers-filter ${openSidebar ? 'open' : ''}`}>
						<div className='suppliers-filter__header'>
							Фильтр
							<svg
								onClick={toggleSidebar}
								width='32'
								height='32'
								viewBox='0 0 32 32'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M19.75 12.25L12.25 19.75M12.25 12.25L19.7499 19.75'
									stroke='white'
									strokeWidth='1.5'
									strokeLinecap='round'
								/>
								<path
									d='M31 16C31 23.0711 31 26.6066 28.8033 28.8033C26.6066 31 23.0711 31 16 31C8.92893 31 5.3934 31 3.1967 28.8033C1 26.6066 1 23.0711 1 16C1 8.92893 1 5.3934 3.1967 3.1967C5.3934 1 8.92893 1 16 1C23.0711 1 26.6066 1 28.8033 3.1967C30.2639 4.65731 30.7533 6.70983 30.9173 10'
									stroke='white'
									strokeWidth='1.5'
									strokeLinecap='round'
								/>
							</svg>
						</div>

						<div className='suppliers-filter__body'>
							<Accordion title='Тип компании'>
								<FilterDropdown
									isSearch
									options={companyListOptions || []}
									defaultValue={getDefaultValue('types', companyListOptions)}
									onSelect={data => onChangeOwnFilter('types', data.id)}
								/>
							</Accordion>

							<Accordion title='По странам'>
								<FilterDropdown
									isSearch
									isClear={!!location.search}
									countries={data}
									defaultValue={getDefaultValue('country_ids', countries)}
									onSelect={data => onChangeOwnFilter('country_ids', data.id)}
								/>
							</Accordion>

							<Accordion title='Производитель'>
								<FilterDropdown
									isSearch
									options={manufactureListOptions || []}
									defaultValue={getDefaultValue(
										'manufacturers_ids',
										manufactureListOptions
									)}
									onSelect={data =>
										onChangeOwnFilter('manufacturers_ids', data.id)
									}
								/>
							</Accordion>

							<Accordion title='Рейтинг'>
								<div className={'slider-rating'}>
									<Slider
										aria-label='Always visible'
										defaultValue={defaultSlider}
										min={0}
										className='slider'
										value={[filter.min_rating || 0, filter.max_rating || 5]}
										getAriaLabel={() => 'Minimum distance'}
										step={1}
										max={5}
										onChange={(e: any) => {
											const [min_rating, max_rating] = e.target?.value || [];
											onChangeOwnFilter('min_rating', [min_rating]);
											setTimeout(() => {
												onChangeOwnFilter('max_rating', [max_rating]);
											}, 100);
										}}
										marks={[
											{
												value: 0,
												label: (
													<div className='mark-item'>
														<span>0</span>{' '}
														<svg
															width='25'
															height='23'
															viewBox='0 0 25 23'
															fill='none'
															xmlns='http://www.w3.org/2000/svg'
															preserveAspectRatio='none'
														>
															<path
																d='M12.5 3.68429L14.5832 8.35773L14.9354 9.14793L15.7958 9.23874L20.8842 9.77579L17.0833 13.2012L16.4406 13.7804L16.6201 14.6267L17.6817 19.6321L13.2494 17.0756L12.5 16.6434L11.7506 17.0756L7.31825 19.6321L8.3799 14.6267L8.55941 13.7804L7.91672 13.2012L4.11576 9.77579L9.2042 9.23874L10.0646 9.14793L10.4168 8.35773L12.5 3.68429Z'
																fill={''}
																stroke='#F1C644'
																strokeWidth='3'
															/>
														</svg>
													</div>
												)
											},
											{
												value: 5,
												label: (
													<div className='mark-item'>
														<span>5</span>{' '}
														<svg
															width='25'
															height='23'
															viewBox='0 0 25 23'
															fill='none'
															xmlns='http://www.w3.org/2000/svg'
															preserveAspectRatio='none'
														>
															<path
																d='M12.5 3.68429L14.5832 8.35773L14.9354 9.14793L15.7958 9.23874L20.8842 9.77579L17.0833 13.2012L16.4406 13.7804L16.6201 14.6267L17.6817 19.6321L13.2494 17.0756L12.5 16.6434L11.7506 17.0756L7.31825 19.6321L8.3799 14.6267L8.55941 13.7804L7.91672 13.2012L4.11576 9.77579L9.2042 9.23874L10.0646 9.14793L10.4168 8.35773L12.5 3.68429Z'
																fill={'#F1C644'}
																stroke='#F1C644'
																strokeWidth='3'
															/>
														</svg>
													</div>
												)
											}
										]}
										valueLabelDisplay='auto'
									/>
									{/* <Slider
										range
										min={0}
										max={5}
										value={ownFilter.rating}
										marks={{
											0: {
												label: (
													<div className='mark-item'>
														<span>0</span>{' '}
														<svg
															width='25'
															height='23'
															viewBox='0 0 25 23'
															fill='none'
															xmlns='http://www.w3.org/2000/svg'
															preserveAspectRatio='none'
														>
															<path
																d='M12.5 3.68429L14.5832 8.35773L14.9354 9.14793L15.7958 9.23874L20.8842 9.77579L17.0833 13.2012L16.4406 13.7804L16.6201 14.6267L17.6817 19.6321L13.2494 17.0756L12.5 16.6434L11.7506 17.0756L7.31825 19.6321L8.3799 14.6267L8.55941 13.7804L7.91672 13.2012L4.11576 9.77579L9.2042 9.23874L10.0646 9.14793L10.4168 8.35773L12.5 3.68429Z'
																fill={''}
																stroke='#F1C644'
																strokeWidth='3'
															/>
														</svg>
													</div>
												)
											},
											5: {
												label: (
													<div className={'mark-item'}>
														<span>5</span>
														<svg
															width='25'
															height='23'
															viewBox='0 0 25 23'
															fill='none'
															xmlns='http://www.w3.org/2000/svg'
															preserveAspectRatio='none'
														>
															<path
																d='M12.5 3.68429L14.5832 8.35773L14.9354 9.14793L15.7958 9.23874L20.8842 9.77579L17.0833 13.2012L16.4406 13.7804L16.6201 14.6267L17.6817 19.6321L13.2494 17.0756L12.5 16.6434L11.7506 17.0756L7.31825 19.6321L8.3799 14.6267L8.55941 13.7804L7.91672 13.2012L4.11576 9.77579L9.2042 9.23874L10.0646 9.14793L10.4168 8.35773L12.5 3.68429Z'
																fill={'#F1C644'}
																stroke='#F1C644'
																strokeWidth='3'
															/>
														</svg>
													</div>
												)
											}
										}}
										defaultValue={[0, 5]}
										trackStyle={{ background: '#8a7de7' }}
										handleStyle={{
											border: '2px solid #8a7de7',
											boxShadow: '0 0 5px #4e41ab'
										}}
										handleRender={(node, handleProps) => {
											return (
												<Tooltip
													overlay={+handleProps.value}
													placement='bottom'
												>
													{node}
												</Tooltip>
											)
										}}
										onChange={e => {
											onChangeOwnFilter('rating', e)
										}}
									/> */}
								</div>
							</Accordion>

							<button className='btn rest-filter' onClick={resetFilter}>
								Очистить
							</button>
						</div>
					</div>

					<div className='suppliers-list-block'>
						{!isFetching && !supplierData.results?.length && (
							<p className='emptyproducts-message'>Поставщики не найдены</p>
						)}
						<div
							className={`${
								viewToggle === 'line'
									? 'suppliers-list-line'
									: 'suppliers-list-grid'
							}`}
						>
							{isLoading && <Loader isAbsolute isMini />}

							{supplierData.results?.map(item => (
								<CardSuppliers
									key={item.id}
									data={item}
									countries={countries}
									companyTypesData={companyTypesData}
								/>
							))}
						</div>
						<div style={{ position: 'relative' }}>
							{isFetching && <Loader isAbsolute isMini />}
						</div>

						{(supplierData.results?.length || 0) <
							(dataAllSupplier?.pages?.[0].count || 0) && (
							<DownloadMore
								disabled={isFetching}
								onClick={() => fetchNextPage()}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Suppliers;
