import clsx from 'clsx';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { TCommon, TUtils } from 'shared/services/types';
import { GroupBlock } from './slices';

interface IProps {
	place?: string;
	defaultValue?: TCommon.TOptionOther[];
	isSearch?: boolean;
	options?: TCommon.TOptionOther[];
	countries?: TUtils.RegionCounty;
	onSelect: (data: TCommon.TOptionOther) => void;
	isClear?: boolean;
}

export const FilterDropdown: FC<IProps> = ({
	place,
	options = [],
	onSelect,
	countries,
	defaultValue = []
}) => {
	const [manufacturesList, setManufacturesList] =
		useState<TCommon.TOptionOther[]>(options);

	const [fullManufacturers, setFullManufacturers] = useState(false);

	const [search, setSearch] = useState('');

	const [isOpen, setIsOpen] = useState(false);
	const [currentValue, setCurrentValue] = useState<TCommon.TOptionOther[]>(
		defaultValue || []
	);

	useEffect(() => {
		setCurrentValue(defaultValue);
	}, [JSON.stringify(defaultValue)]);

	const onChangeSelect = (item: TCommon.TOptionOther) => {
		const currentValues = currentValue.includes(item)
			? currentValue.filter(v => v.id !== item.id)
			: [...currentValue, item];
		setCurrentValue(currentValues);
		onSelect?.(item);
	};

	const onSearchManufactures = (e: ChangeEvent<HTMLInputElement>) => {
		const val = e.target.value;
		setSearch(val);
		if (options) {
			const result = options?.filter(item =>
				item.name.toLowerCase().includes(val.toLowerCase())
			);

			setManufacturesList(result);
		}
	};
	const res = Object.entries(countries || {});

	useEffect(() => {
		if (options.length) {
			setManufacturesList(options);
		}
	}, [options]);

	if (!res.length)
		return (
			<div className='open'>
				<div className='suppliers-filter-item__search'>
					<input
						placeholder='Поиск'
						value={search}
						onChange={onSearchManufactures}
					/>
					<button type='submit'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='19'
							height='19'
							viewBox='0 0 19 19'
							fill='none'
						>
							<path
								d='M16.625 16.625L11.875 11.875M2.375 7.91667C2.375 8.64441 2.51834 9.36503 2.79683 10.0374C3.07533 10.7097 3.48353 11.3206 3.99812 11.8352C4.51271 12.3498 5.12362 12.758 5.79596 13.0365C6.46831 13.315 7.18892 13.4583 7.91667 13.4583C8.64441 13.4583 9.36503 13.315 10.0374 13.0365C10.7097 12.758 11.3206 12.3498 11.8352 11.8352C12.3498 11.3206 12.758 10.7097 13.0365 10.0374C13.315 9.36503 13.4583 8.64441 13.4583 7.91667C13.4583 7.18892 13.315 6.46831 13.0365 5.79596C12.758 5.12362 12.3498 4.51271 11.8352 3.99812C11.3206 3.48353 10.7097 3.07533 10.0374 2.79683C9.36503 2.51834 8.64441 2.375 7.91667 2.375C7.18892 2.375 6.46831 2.51834 5.79596 2.79683C5.12362 3.07533 4.51271 3.48353 3.99812 3.99812C3.48353 4.51271 3.07533 5.12362 2.79683 5.79596C2.51834 6.46831 2.375 7.18892 2.375 7.91667Z'
								stroke='#6A6A6B'
								strokeWidth='1.1'
								strokeLinecap='round'
								strokeLinejoin='round'
							/>
						</svg>
					</button>
				</div>
				<div
					className={clsx(
						`filter-manufacturers-list`,
						fullManufacturers && 'full'
					)}
				>
					{manufacturesList?.map(item => {
						return (
							<label key={item.id}>
								<input
									type='checkbox'
									name='cng'
									checked={currentValue.includes(item)}
									onChange={() => onChangeSelect(item)}
								/>
								<span>{item.name}</span>
							</label>
						);
					})}

					{!!manufacturesList?.length && manufacturesList?.length > 10 && (
						<button
							className='manufacturers-more'
							onClick={() => setFullManufacturers(!fullManufacturers)}
						>
							{fullManufacturers ? 'Свернуть' : 'Показать все'}
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='12'
								height='12'
								viewBox='0 0 12 12'
								fill='none'
							>
								<path
									d='M9.5 4.5L6 7.5L2.5 4.5'
									stroke='#8A7DE7'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
							</svg>
						</button>
					)}
				</div>
			</div>
		);

	return res.map(([name, values]) => {
		return (
			<GroupBlock
				data={values}
				name={name}
				onSelect={onChangeSelect}
				defaultValue={defaultValue || currentValue}
			/>
			// <div className={clsx('suppliers-filter-item__sub', isOpen && 'open')}>
			// 	<div
			// 		className='suppliers-filter-item__sub-title'
			// 		onClick={() => setIsOpen(!isOpen)}
			// 	>
			// 		{name}

			// 		<i>
			// 			<svg
			// 				xmlns='http://www.w3.org/2000/svg'
			// 				width='20'
			// 				height='20'
			// 				viewBox='0 0 20 20'
			// 				fill='none'
			// 			>
			// 				<path
			// 					d='M15.8307 7.5L9.9974 12.5L4.16406 7.5'
			// 					stroke='#838383'
			// 					strokeWidth='1.5'
			// 					strokeLinecap='round'
			// 					strokeLinejoin='round'
			// 				/>
			// 			</svg>
			// 		</i>
			// 	</div>
			// 	<div className='suppliers-filter-item__sub-list'>
			// 		{values?.map(item => (
			// 			<label key={item.id}>
			// 				<input
			// 					type='checkbox'
			// 					name='cng'
			// 					checked={[...(defaultValue || currentValue)].includes(item)}
			// 					onClick={() => onChangeSelect(item)}
			// 				/>
			// 				<span>{item.name}</span>
			// 			</label>
			// 		))}
			// 	</div>
			// </div>
		);
	});
};
