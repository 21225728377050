import 'assets/input.scss';
import { usePartnersActions } from 'features/partners/api/usePartnersActions';
import { useManufactureList } from 'features/product/api';
import { FC, useEffect } from 'react';
import { UseFormReturn, useFieldArray } from 'react-hook-form';
import { useAlertMessage } from 'shared/hooks';
import { TSupplier } from 'shared/services/types';
import { Input } from 'shared/ui/form/input';
import { ReactSelect } from 'shared/ui/form/react-select';
import { PromptText } from 'shared/ui/prompt-text';
import './ProfilePartnersCapacities.scss';

interface IProps {
	defaultData?: TSupplier.DataDetail;
	methods: UseFormReturn<any, any, undefined>;
}

const ProfilePartnersCapacities: FC<IProps> = ({ defaultData, methods }) => {
	const { manufactureOptions } = useManufactureList();
	const { addAlertMessage } = useAlertMessage();
	const {
		watch,
		control,
		formState: { errors }
	} = methods;

	const distributors = watch('distributors');

	// const { fields, append, remove } = useFieldArray({
	// 	control,
	// 	name: 'vendors'
	// });

	const {
		fields: manufacturersFields,
		append: manufacturersOpen,
		remove: manufacturersRemove
	} = useFieldArray({
		control,
		name: 'manufacturers'
	});

	const { updatePartner } = usePartnersActions();

	useEffect(() => {
		if (defaultData) {
			const manufacturers =
				defaultData?.manufacturers?.map(item => {
					const manufacturer = manufactureOptions.find(
						v => v.value === item.id
					);
					return {
						status: item.status,
						manufacturer: manufacturer
					};
				}) || [];
			methods.reset({
				...methods.getValues(),
				manufacturers: manufacturers
			});
		}
	}, [defaultData, manufactureOptions]);

	const onSubmit = async () => {
		methods.trigger();

		await new Promise(resolve => setTimeout(resolve, 100));

		const value = methods.getValues();
		const error = methods.formState.errors;
		const dataObject = {
	
			supplier: {
				manufacturers: value?.manufacturers?.map((item: any) => ({
					manufacturer_id: item.manufacturer.value,
					status: item.status
				}))
			}
		};

		const isError = Object.keys(dataObject).find(item => {
			return error[item];
		});

		if (isError) return null;

		try {
			//TODO навести тут порядок
			//@ts-ignore
			await updatePartner.mutateAsync(dataObject);
			addAlertMessage('Сохранено', 'success');
		} catch (error: any) {
			addAlertMessage(error, 'error');
		}
	};

	// const addVendor = () => {
	// 	append({ name: '', status: '' });
	// };

	// const removeBill = (id: number) => {
	// 	remove(id);
	// };

	const addManufacturers = () => {
		manufacturersOpen({ manufacturer: '', status: '' });
	};

	const removeManufacturers = (id: number) => {
		manufacturersRemove(id);
	};

	useEffect(() => {
		// methods.setValue('distributors', distributors)
	}, [distributors?.length]);

	return (
		<>
			<div className='capacities-form'>
				{/* <div
					style={{
						display: 'flex',
						alignItems: 'center',
						gap: '10px',
						paddingBottom: '1rem',
						paddingRight: '10px'
					}}
				>
					<p>
						Наличие партнерских отношений с вендорами<span>*</span>
					</p>
					<div style={{ position: 'relative', width: '15px', height: '15px' }}>
						<PromptText
							text={
								'Укажите бренд + партнерский статус, если они есть. Это поможет пользователю принять решение о сотрудничестве.'
							}
						/>
					</div>
				</div>

				{fields?.map((input, index) => {
					return (
						<div key={index} className='inputs-row'>
							<Input
								control={control}
								name={`vendors.${index}.vendor`}
								label='Вендор'
								placeholder='Вендор'
								required
								// @ts-ignore
								error={errors?.vendors?.[index]?.vendor?.message}
								rules={{ required: 'Обязательное поле для заполнения' }}
							/>
							<Input
								control={control}
								name={`vendors.${index}.status`}
								label='Статус'
								required
								placeholder='Статус'
								// @ts-ignore
								error={errors?.vendors?.[index]?.status?.message}
								rules={{ required: 'Обязательное поле для заполнения' }}
							/>

							{fields?.length && (
								<button
									type='button'
									className='remove-row'
									onClick={() => removeBill(index)}
								>
									<svg
										width='9'
										height='9'
										viewBox='0 0 9 9'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
										preserveAspectRatio='none'
									>
										<path
											d='M8 1.00003L1.00003 8M1 1L7.99997 7.99997'
											stroke='#8A7DE7'
											strokeOpacity='1'
											strokeLinecap='round'
										/>
									</svg>
								</button>
							)}
						</div>
					);
				})}

				<div style={{ marginBottom: '1rem' }}>
					<button className='btn mobi-full' type='button' onClick={addVendor}>
						Добавить
					</button>
				</div> */}

				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						gap: '10px',
						paddingBottom: '1rem',
						paddingRight: '10px'
					}}
				>
					<p>
						Наличие партнерских отношений с производителями<span>*</span>
					</p>
					<div style={{ position: 'relative', width: '15px', height: '15px' }}>
						<PromptText
							text={
								'Укажите бренд + партнерский статус, если они есть. Это поможет пользователю принять решение о сотрудничестве.'
							}
						/>
					</div>
				</div>

				{manufacturersFields?.map((input, index) => {
					return (
						<div key={index} className='inputs-row'>
							<ReactSelect
								control={control}
								name={`manufacturers.${index}.manufacturer`}
								label='Производитель'
								placeholder='Производитель'
								required
								// @ts-ignore
								error={errors?.manufacturers?.[index]?.manufacturer?.message}
								options={manufactureOptions}
								rules={{ required: 'Обязательное поле для заполнения' }}
							/>

							<Input
								control={control}
								name={`manufacturers.${index}.status`}
								label='Статус'
								required
								placeholder='Статус'
								// @ts-ignore
								error={errors?.manufacturers?.[index]?.status?.message}
								rules={{ required: 'Обязательное поле для заполнения' }}
							/>

							{manufacturersFields?.length && (
								<button
									type='button'
									className='remove-row'
									onClick={() => removeManufacturers(index)}
								>
									<svg
										width='9'
										height='9'
										viewBox='0 0 9 9'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
										preserveAspectRatio='none'
									>
										<path
											d='M8 1.00003L1.00003 8M1 1L7.99997 7.99997'
											stroke='#8A7DE7'
											strokeOpacity='1'
											strokeLinecap='round'
										/>
									</svg>
								</button>
							)}
						</div>
					);
				})}

				<div style={{ marginBottom: '1rem' }}>
					<button
						className='btn mobi-full'
						type='button'
						onClick={addManufacturers}
					>
						Добавить
					</button>
				</div>

				{/* <Input
					control={control}
					name={`service_centers`}
					className='service_center'
					label='Наличие сервисного центра'
					required
					placeholder='Наличие сервисного центра'
					error={errors}
					info='Укажите город, если сервисный центр один или страну если сервисных центров много.'
				/> */}

				{/* <div className='select-block'>
					<p>
						Наличие сервисного центра, куда клиент может обратится за помощью
					</p>

					<ReactSelectMulti
						control={control}
						options={serviceCenter}
						name='service_centers'
						messageNoOption='Нет сервисных центров'
						placeholder=''
						error={errors}
					/>
				</div> */}

				{/* <label className="important">
          Наличие партнерских отношений с поставщиками
        </label>
        {vendorsList.map((input) => {
          return (
            <div key={input.id} className="two-on-line">
              <div>
                <p>Поставщик</p>
                <select
                  value={input.vender}
                  onChange={(e) => handleVendorChange(e, input.id)}
                >
                  <option>...</option>
                  {distributors ? (
                    distributors.map((i) => (
                      <option data-key={i.id}>{i.name}</option>
                    ))
                  ) : (
                    <></>
                  )}
                </select>
              </div>
              <div>
                <p>Статус</p>
                <input
                  type="text"
                  value={input.status}
                  onChange={(e) => handleStatusChange(e, input.id)}
                  required
                />
              </div>
            </div>
          );
        })}
        <button type="button" onClick={addVendor}>
          Добавить
        </button> */}

				{/* <button
          className="distributor-request"
          type="button"
          onClick={() => setIsOpen(!isOpen)}
        >
          Отправить запрос
        </button> */}

				{/* <label className="important">Выбранные вами службы доставки</label>
        {deliveryList.map((input) => {
          return (
            <div key={input.id} className="two-on-line">
              <div>
                <p>Служба доставки</p>
                <select
                  value={input.name}
                  // onChange={(e) => handleDeliveryChange(e, input.id)}
                >
                  <option>...</option>
                  {deliveryServices ? (
                    deliveryServices.map((i) => <option>{i.name}</option>)
                  ) : (
                    <></>
                  )}
                </select>
              </div>
              <div>
                <p>Статус</p>
                <input
                  type="text"
                  value={input.deliveryStatus}
                  // onChange={(e) => handleDeliveryStatusChange(e, input.id)}
                  required
                />
              </div>
            </div>
          );
        })}
        <button type="button" onClick={()=>{}}>
          Добавить
        </button>
        <label>Текстовое поле для инструкции</label>
        <p>
          Расскажите с чего начать клиенту, как ему оформить заказ в максимально
          короткие сроки, какие документы подготовить для сделки.
        </p>
        <textarea
          placeholder="..."
          value={instruction}
          onChange={(e) => setInstruction(e.target.value)}
        /> */}
				{defaultData && (
					<div className='block-save'>
						<button className='btn' type='button' onClick={onSubmit}>
							Сохранить
						</button>
					</div>
				)}
			</div>
		</>
	);
};

export default ProfilePartnersCapacities;
