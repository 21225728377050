import { getSupplerIcon } from 'assets/SupplersIcons';

import { ProductToProjectPopup } from 'features/product/ui';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { countryCodeName, URL_SITE } from 'shared/constants';
import { useValueCurrency } from 'shared/hooks';
import { TProduct } from 'shared/services/types/products.types';
import { TSubscription } from 'shared/services/types/subscription.types';
import { TUtils } from 'shared/services/types/utils.types';
import { BitDot } from 'shared/ui';
import { PromptText } from 'shared/ui/prompt-text';
import { getFormattedDate } from 'shared/utils';
import { tariffsBlur } from '.';
import { distributerList } from '..';

export interface IProps {
	productsList: TProduct.Data[];
	settingsParam?: string[];
	countries: TUtils.DataCountry[];
	authState: boolean;
	tariff: string;
	data?: TSubscription.TariffUser;
	currentPriceValue: (prices: any[], unrounded?: boolean) => string | number;
}

export const TableMobile: FC<IProps> = ({
	productsList,
	settingsParam,
	countries,
	authState,
	tariff,
	data,
	currentPriceValue
}) => {
	const { getCurrencyCounty, currency } = useValueCurrency(false, 0);

	return (
		<div className='products-mob'>
			{productsList.map(i => {
				return (
					<div className='products-mob-item' key={i.id}>
						{
							<div className='products-mob-item__row'>
								<div className='products-mob-item__col-name'>
									{settingsParam?.includes('country_id') && 'Страна'}
									<br />
									Артикул
								</div>
								<div className='products-mob-item__col-value'>
									{settingsParam?.includes('country_id') && (
										<>
											<img
												className='flag'
												src={
													URL_SITE +
													countries?.find(l => i.country_id === l.id)?.flag
												}
												height='24'
												width='34'
											/>

											<p className='code'>
												{/* @ts-ignore */}
												{countryCodeName[i.country_id]}
											</p>
										</>
									)}

									<p className='article'>{i.article}</p>
								</div>
							</div>
						}
						<div className='products-mob-item__row'>
							<div className='products-mob-item__col-name'>Название</div>
							<div className='products-mob-item__col-value'>
								<p>{i.name}</p>
							</div>
						</div>
						{!!settingsParam?.includes('stock_available') && (
							<div className='products-mob-item__row'>
								<div className='products-mob-item__col-name'>В наличии</div>
								<div
									className='products-mob-item__col-value'
									aria-label='Доступно'
								>
									<p>{i.stock_available}</p>
								</div>
							</div>
						)}
						{!!settingsParam?.includes('transit_available') && (
							<div className='products-mob-item__row'>
								<div className='products-mob-item__col-name'>Транзит</div>
								<div
									className='products-mob-item__col-value'
									aria-label='В транзите'
								>
									<p>{i.transit_available}</p>
								</div>
							</div>
						)}
						{!!settingsParam?.includes('supplier') && i.supplier && (
							<div className='products-mob-item__row'>
								<div className='products-mob-item__col-name'>Поставщик</div>
								<div
									className='products-mob-item__col-value'
									aria-label='Поставщик'
								>
									{authState && tariff !== 'Бесплатный' ? (
										<div className='distributor'>
											<div className='distributor-relative'>
												<PromptText
													align='right'
													text={`${
														distributerList.find(
															item => item.value === i?.supplier?.type
														)?.help
													}`}
												>
													{getSupplerIcon(i?.supplier?.type?.toLowerCase())}
												</PromptText>
											</div>

											<Link
												className='distributor-col'
												to={`/suppliers/${i?.supplier?.slug}`}
											>
												{i.supplier.name}
											</Link>
											<span className={'distributor-update'}>
												<PromptText
													zIndex={1}
													align='top'
													text={`Дата последнего обновления прайс-листа`}
												>
													({getFormattedDate(i.last_update, 'dd.MM.yyyy')})
												</PromptText>{' '}
											</span>
										</div>
									) : (
										<div className='distributor blur-block'>
											{getSupplerIcon(i?.supplier?.type?.toLowerCase())}
											<span className='distributor-col'>{i.supplier.name}</span>
										</div>
									)}
								</div>
							</div>
						)}
						{!!settingsParam?.includes('prices') && (
							<div className='products-mob-item__row'>
								<div className='products-mob-item__col-name products-mob-item__col-name-center'>
									Цена
								</div>
								<div className='products-mob-item__col-value'>
									<div className='price'>
										<p>
											<BitDot
												text={
													getCurrencyCounty(
														i.supplier?.prices?.[2]?.price,
														true,
														true
													) || 'Уточняйте'
												}
											/>{' '}
											{!isNaN(Number(i.supplier?.prices?.[2]?.price))
												? currency
												: ''}
										</p>
									</div>
									{authState && !tariffsBlur.includes(data?.tariff?.id) ? (
										// @ts-ignore
										<ProductToProjectPopup dataProduct={i} />
									) : authState && tariffsBlur.includes(data?.tariff?.id) ? (
										<Link className='btn' to='/account/tariffs/'>
											Приобретите <br />
											подписку
										</Link>
									) : (
										<Link className='btn' to='/register/'>
											Зарегистрируйтесь <br />
											для просмотра поставщиков
										</Link>
									)}
								</div>
							</div>
						)}
					</div>
				);
			})}
		</div>
	);
};
