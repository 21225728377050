export const getWeekDay = (dayNumber: number): string => {
	if (dayNumber < 1 || dayNumber > 7) {
		throw new Error('Введите число от 1 до 7');
	}

	// Массив с названиями дней недели на русском языке
	const weekDays = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];

	// Возвращаем день недели по индексу (dayNumber - 1)
	return weekDays[dayNumber - 1];
};
