import clsx from 'clsx';
import BannerSupport from 'entities/banner-support';
import { useGetTariffs } from 'features/tariff/api/useGetTariffs';
import { useTariffsActions } from 'features/tariff/api/useTariffsActions';
import { ConfirmModalTariff } from 'features/tariff/ui/confirm-modal';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useRoleUser, useValueCurrency } from 'shared/hooks';
import { TSubscription } from 'shared/services/types/subscription.types';
import { calculateOriginalPrice } from 'shared/utils';
import './ProfileTariffs.scss';
import { TariffHelp } from './TariffHelp';

const ProfileTariffs = () => {
	const { data } = useGetTariffs();

	const { isOwnerCompany } = useRoleUser();
	const { getCurrencyCounty, currency } = useValueCurrency(false, 0);

	const [activeTab, setActiveTab] = useState<1 | 3 | 6 | 12>(12);
	const [isOpen, setIsOpen] = useState(false);
	const [currentData, setCurrentData] =
		useState<TSubscription.DataTariff | null>(null);

	const { updateTariff, requestTariff } = useTariffsActions();
	const [tariffData, setTariffData] = useState<{ main: any[]; second: any[] }>({
		main: [],
		second: []
	});

	const tariffList = [
		{
			name: `1 месяц`,
			value: 1
		},
		{
			name: `3 месяца`,
			discount: 10,
			value: 3
		},
		{
			name: `6 месяцев`,
			discount: 20,
			value: 6
		},
		{
			name: `1 год`,
			discount: 30,
			value: 12
		}
	];

	const onUpdateTariff = async (data: any) => {
		setCurrentData(data);
		setIsOpen(true);
	};

	useEffect(() => {
		if (data) {
			setTariffData({
				main: data?.tariffs?.slice(0, 4),
				second: data?.tariffs?.slice(4)
			});
		}
	}, [data]);

	return (
		<>
			<div className='tariffs-container'>
				{!isOwnerCompany && (
					<div className='profile-overlay'>
						<div className='profile-overlay-content'>
							<p>
								<>Для работы с тарифами нужно быть владельцем компании</>
							</p>
						</div>
					</div>
				)}
				<div className='tariffs'>
					<div className='tariffs__title'>
						<h2>Подберите подходящий тариф</h2>

						<p className='sales-description'>
							-50% на все тарифы до конца февраля
						</p>
					</div>
					<div className='tariffs__periods'>
						{tariffList.map(item => (
							<div
								key={item.value}
								className={clsx(
									'tariffs-period',
									activeTab === item.value && 'active'
								)}
								//@ts-ignore
								onClick={() => setActiveTab(item.value)}
							>
								<span className='circle'></span>
								<p>{item.name}</p>
								{item.discount && (
									<span className='discount'>-{item.discount}%</span>
								)}
							</div>
						))}
					</div>
					<div className='tariffs__list'>
						{tariffData.main?.map(item => {
							const price = activeTab
								? getCurrencyCounty(
										item.sales_prices[activeTab]?.one,
										false,
										false,
										false
								  )
								: getCurrencyCounty(item.price, false, false, true);

							//@ts-ignore
							const numberPrice = price?.split(' ')[0];

							const currentPrice = numberPrice?.replace(' ', '');

							const sale = item.sale;

							return (
								<div className='tariff' key={item.id}>
									<div className='tariff__header'>
										{!!sale && (
											<div className={'tariff-sale'}>Скидка {sale} %</div>
										)}

										<div className='tariff__name'>{item.name}</div>
										<div className='tariff__price'>
											<span className='price'>
												{!!sale && (
													<div className='prev_price'>
														{calculateOriginalPrice(Number(currentPrice), sale)}
													</div>
												)}
												<span>
													<strong>{price}</strong> <>{currency}</>
												</span>
											</span>
											<span className='period'> / мес</span>
										</div>
									</div>
									<div className='tariff__options'>
										<ul>
											{item?.features?.map((value: string) => (
												<li key={value} className={clsx(!!value && 'active')}>
													<span>{value}</span>
													<TariffHelp value={value} />
												</li>
											))}
										</ul>
									</div>
									<div className='tariff__footer'>
										<div className='tariff__btn'>
											<button
												disabled={
													updateTariff.isPending ||
													requestTariff.isPending ||
													!isOwnerCompany
												}
												onClick={() => {
													onUpdateTariff(item);
												}}
											>
												Подключить
											</button>
										</div>
										<div className='tariff__more'>
											<Link to='/account/chat'>
												Узнать подробнее
												<i>
													<svg
														width='13'
														height='13'
														viewBox='0 0 13 13'
														fill='none'
														xmlns='http://www.w3.org/2000/svg'
													>
														<g clipPath='url(#clip0_1203_46397)'>
															<path
																d='M1.35449 3.9335C1.13358 3.9335 0.954492 4.11258 0.954492 4.3335C0.954492 4.55441 1.13358 4.7335 1.35449 4.7335V3.9335ZM11.917 3.9335H1.35449V4.7335H11.917V3.9335Z'
																fill='#222222'
															/>
															<path
																d='M5.68783 1.354L3.79199 4.33317'
																stroke='#222222'
																strokeWidth='0.8'
																strokeLinecap='round'
															/>
															<path
																d='M9.20833 1.35425L7.3125 4.33342'
																stroke='#222222'
																strokeWidth='0.8'
																strokeLinecap='round'
															/>
															<path
																d='M8.12533 7.854C8.12533 7.51093 7.76674 7.27956 7.04956 6.81683C6.32258 6.34777 5.95909 6.11324 5.68804 6.28544C5.41699 6.45764 5.41699 6.92309 5.41699 7.854C5.41699 8.78491 5.41699 9.25037 5.68804 9.42257C5.95909 9.59477 6.32258 9.36024 7.04956 8.89118C7.76673 8.42844 8.12533 8.19707 8.12533 7.854Z'
																stroke='#222222'
																strokeWidth='0.8'
																strokeLinecap='round'
															/>
															<path
																d='M11.9163 6.50016C11.9163 9.0536 11.9163 10.3303 11.1231 11.1236C10.3298 11.9168 9.05312 11.9168 6.49967 11.9168C3.94623 11.9168 2.66951 11.9168 1.87626 11.1236C1.08301 10.3303 1.08301 9.0536 1.08301 6.50016C1.08301 3.94672 1.08301 2.67 1.87626 1.87675C2.66951 1.0835 3.94623 1.0835 6.49967 1.0835C9.05312 1.0835 10.3298 1.0835 11.1231 1.87675C11.6505 2.40419 11.8273 3.14538 11.8865 4.3335'
																stroke='#222222'
																strokeWidth='0.8'
																strokeLinecap='round'
															/>
														</g>
														<defs>
															<clipPath id='clip0_1203_46397'>
																<rect width='13' height='13' fill='white' />
															</clipPath>
														</defs>
													</svg>
												</i>
											</Link>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>

				<div className='tariffs tariffs-other'>
					<div className='tariffs__title'>
						<h2>Не планируете искать товары?</h2>
						<p>
							Разместить прайс-лист или стать партнером, функция поиска <br />{' '}
							поставщиков и партнеров будет не доступна.
						</p>
					</div>
					<div className='tariffs__list'>
						{tariffData.second.map(item => {
							const price = getCurrencyCounty(
								item.sales_prices[activeTab]?.full
							);
							//@ts-ignore
							const numberPrice = price?.split(' ')[0];

							const currentPrice = numberPrice?.replace(' ', '');

							const sale = item.sale;

							return (
								<div className='tariff' key={item.id}>
									<div className='tariff__header'>
										{!!sale && (
											<div className={'tariff-sale'}>Скидка {sale} %</div>
										)}
										<div className='tariff__name'>{item.name}</div>
										<div className='tariff__price'>
											<span className='price'>
												{!!sale && (
													<div className='prev_price'>
														{calculateOriginalPrice(Number(currentPrice), sale)}
													</div>
												)}
												<span>
													<strong>{price}</strong> <>{currency}</>
												</span>
											</span>
											<span className='period'> / год</span>
										</div>
									</div>
									<div className='tariff__options'>
										<ul>
											{item.features.slice(0, 4).map((value: string) => (
												<li key={value} className={clsx(!!value && 'active')}>
													<span>{value}</span>
												</li>
											))}
										</ul>
									</div>
									<div className='tariff__footer'>
										<div
											className='tariff__btn'
											onClick={() => {
												onUpdateTariff(item);
											}}
										>
											<button disabled={!isOwnerCompany}>Подключить</button>
										</div>
										<div className='tariff__more'>
											<Link to='/account/chat'>
												Узнать подробнее
												<i>
													<svg
														width='13'
														height='13'
														viewBox='0 0 13 13'
														fill='none'
														xmlns='http://www.w3.org/2000/svg'
													>
														<g clipPath='url(#clip0_1203_46397)'>
															<path
																d='M1.35449 3.9335C1.13358 3.9335 0.954492 4.11258 0.954492 4.3335C0.954492 4.55441 1.13358 4.7335 1.35449 4.7335V3.9335ZM11.917 3.9335H1.35449V4.7335H11.917V3.9335Z'
																fill='#222222'
															/>
															<path
																d='M5.68783 1.354L3.79199 4.33317'
																stroke='#222222'
																strokeWidth='0.8'
																strokeLinecap='round'
															/>
															<path
																d='M9.20833 1.35425L7.3125 4.33342'
																stroke='#222222'
																strokeWidth='0.8'
																strokeLinecap='round'
															/>
															<path
																d='M8.12533 7.854C8.12533 7.51093 7.76674 7.27956 7.04956 6.81683C6.32258 6.34777 5.95909 6.11324 5.68804 6.28544C5.41699 6.45764 5.41699 6.92309 5.41699 7.854C5.41699 8.78491 5.41699 9.25037 5.68804 9.42257C5.95909 9.59477 6.32258 9.36024 7.04956 8.89118C7.76673 8.42844 8.12533 8.19707 8.12533 7.854Z'
																stroke='#222222'
																strokeWidth='0.8'
																strokeLinecap='round'
															/>
															<path
																d='M11.9163 6.50016C11.9163 9.0536 11.9163 10.3303 11.1231 11.1236C10.3298 11.9168 9.05312 11.9168 6.49967 11.9168C3.94623 11.9168 2.66951 11.9168 1.87626 11.1236C1.08301 10.3303 1.08301 9.0536 1.08301 6.50016C1.08301 3.94672 1.08301 2.67 1.87626 1.87675C2.66951 1.0835 3.94623 1.0835 6.49967 1.0835C9.05312 1.0835 10.3298 1.0835 11.1231 1.87675C11.6505 2.40419 11.8273 3.14538 11.8865 4.3335'
																stroke='#222222'
																strokeWidth='0.8'
																strokeLinecap='round'
															/>
														</g>
														<defs>
															<clipPath id='clip0_1203_46397'>
																<rect width='13' height='13' fill='white' />
															</clipPath>
														</defs>
													</svg>
												</i>
											</Link>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
			<ConfirmModalTariff
				type={data?.type}
				months={activeTab}
				data={currentData!}
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
			/>

			<BannerSupport />
		</>
	);
};

export default ProfileTariffs;
