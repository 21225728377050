import { useLocation } from 'react-router';

interface QueryParams {
	[key: string]: string | number | string[]; // Ключи могут быть строками, а значения - строками, числами или массивами строк
}

export const useQueryUrlHelper = () => {
	const location = useLocation();

	function objectToQueryString(params: Record<string, any>) {
		const searchParams = new URLSearchParams();

		for (const key in params) {
			const value = params[key];

			if (value !== undefined) {
				if (Array.isArray(value)) {
					value.forEach(item => searchParams.append(key, item));
				} else {
					searchParams.append(key, value);
				}
			}
		}

		return searchParams.toString();
	}

	function queryStringToObject(
		queryString?: string,
		keysArray?: string[]
	): QueryParams {
		const params = new URLSearchParams(queryString || location.search);
		const result: QueryParams = {};

		//@ts-ignore
		for (const [key, value] of params.entries()) {
			const numericValue = !isNaN(Number(value)) ? Number(value) : value;

			if (result[key]) {
				if (Array.isArray(result[key])) {
					//@ts-ignore
					result[key].push(numericValue as string);
				} else {
					result[key] = [result[key] as string, numericValue];
				}
			} else if (keysArray?.includes(key)) {
				result[key] = [numericValue];
			} else {
				result[key] = numericValue;
			}
		}

		return result;
	}

	return { objectToQueryString, queryStringToObject };
};
