import clsx from 'clsx';

import { FC } from 'react';
import { countryCodeName, URL_SITE } from 'shared/constants';
import { useValueCurrency } from 'shared/hooks';
import { TProduct, TSubscription, TUtils } from 'shared/services/types';
import { TextToggle } from 'shared/ui/collapsible';
import { TableMobile } from './TableMobile';

interface IProps {
	settingsParam?: string[];
	productsList: TProduct.Data[];
	sortTable: Record<string, 'asc' | 'desc'>;
	onSortTableProducts: (name: string) => void;
	currentCurrency: any;
	tariff: string;
	authState: boolean;
	countries: TUtils.DataCountry[];
	data?: TSubscription.TariffUser;
}

export const tariffsBlur = [undefined, 5, 7];

export const TableProducts: FC<IProps> = ({
	settingsParam,
	productsList,
	onSortTableProducts,
	currentCurrency,
	authState,
	tariff,
	countries,
	data,
	sortTable
}) => {
	const { getCurrencyCounty } = useValueCurrency(false, 0);

	const currentPriceValue = (prices: any[], unrounded?: boolean) => {
		const label = currentCurrency?.label;
		return currentCurrency
			? getCurrencyCounty(
					prices?.[label === 'RUB' ? 2 : 0]?.price,
					unrounded
			  ) || 'Уточняйте'
			: Number(prices?.[2]?.price);
	};

	return (
		<>
			<div className='product-table'>
				<table>
					<thead>
						<tr>
							<th className='country-col'>Страна</th>

							<th className='article-col'>ИНН</th>
							<th className={clsx('article-col', 'article-col-name')}>
								Название
							</th>
							<th className={clsx('available-col ')}>Дата регистрации</th>
							<th className={clsx('available-col ')}>Юр. адрес</th>
							<th className={clsx('available-col ')}>Уставной капитал</th>
							{<th className='tolist-col'></th>}
						</tr>
					</thead>
					<tbody>
						{productsList.map(i => {
							return (
								<tr key={i.id}>
									<td className='country-col' aria-label='Страна'>
										{/* @ts-ignore */}
										<p>{countryCodeName[i.country_id]}</p>
										<img
											src={
												URL_SITE +
												countries?.find(l => i.country_id === l.id)?.flag
											}
											height='24'
											width='34'
										/>
									</td>
									<td className='article-col' aria-label='ИНН'>
										<TextToggle text={i.article || ''} lines={1} />
									</td>
									<td className='name-col' aria-label='Название'>
										<TextToggle text={i.name} lines={1} />
									</td>
									<td className={clsx('available-col')} aria-label='Доступно'>
										<p>{i.stock_available}</p>
									</td>
									<td className={clsx('instock-col')} aria-label='На складе'>
										<p>{i.stock_quantity}</p>
									</td>

									<td className='transit-col' aria-label='Транзит'>
										<p>{i.transit_available}</p>
									</td>

									<td
										className={clsx(
											'tolist-col manufacturer-col',
											'tolist-button '
										)}
										aria-label='В список'
									>
										<>
											<p>{'Купить'}</p>
										</>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>

			<TableMobile
				data={data}
				authState={authState}
				countries={countries}
				currentPriceValue={currentPriceValue}
				productsList={productsList}
				tariff={tariff}
				settingsParam={settingsParam}
			/>
		</>
	);
};
