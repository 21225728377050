import { useAuthActions } from 'features/auth/api';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { GoogleJSX, VkJSX, YandexJSX } from 'shared/assets/svg';
import { useAlertMessage, useQueryParams } from 'shared/hooks';
import AuthService from 'shared/services/auth/auth.service';
import { TAuth } from 'shared/services/types';
import { parseUrl } from 'shared/utils';

const getDomain = () => {
	const { protocol, host } = window.location;
	return `${protocol}//${host}`;
};

const oAuthDataList = [
	{
		state: 'yandex',
		icon: YandexJSX,
		// color: '#F1F2F5'
		color: '#fff'
	},

	{
		state: 'google',
		icon: GoogleJSX,
		color: '#fff'
	},
	{
		state: 'vk',
		icon: VkJSX,
		color: '#0077FF'
	}
];
export const OAuthServices = () => {
	const params = useQueryParams();
	const { addAlertMessage } = useAlertMessage();
	const navigate = useNavigate();

	const { onGetOAuthUrl } = useAuthActions();
	const redirectUrl = `${getDomain()}/login`;

	const onSendOAuthRequest = async (state: string) => {
		const data = await onGetOAuthUrl.mutateAsync({
			provider: state,
			callback_url: redirectUrl
		});
		const queryParams = parseUrl(data.data).queryParams;
		if (queryParams?.code_verifier) {
			localStorage.setItem('code_verifier', queryParams?.code_verifier);
		}

		window.location.href = data.data;
	};

	const onLoginOAuth = async () => {
		try {
			const code_verifier = localStorage.getItem('code_verifier');
			const { scope, authuser, prompt, ...urlParams } = params;

			const data = {
				...urlParams,
				code_verifier,
				redirect_uri: redirectUrl
			} as TAuth.RequestOAuthLogin;
			await AuthService.loginOAuth(data);
			localStorage.removeItem('code_verifier');

			navigate('/');
		} catch (error: any) {
			navigate('/login');

			addAlertMessage(error, 'error');
		}
	};

	useEffect(() => {
		if (params.state && params.code) {
			onLoginOAuth();
		}
	}, [params]);

	return (
		<div className='auth-block__or'>
			<p>или войти через</p>
			<div className='auth-block-socials'>
				{oAuthDataList.map(v => {
					return (
						<div
							onClick={() => {
								onSendOAuthRequest(v.state);
							}}
							key={v.state}
							style={{ background: v.color }}
							className={`auth-block-social auth-block-social__item ${v.state}`}
						>
							<v.icon />
						</div>
					);
				})}
			</div>
		</div>
	);
};
