import { axiosInstance } from 'shared/api';
import { getHelpUrl } from 'shared/api/config';
import { toFormData } from 'shared/utils';
import { THelper } from './types';

const HelpService = {
	async addNewDistributer(data: THelper.IDistributorRequest) {
		const response = await axiosInstance.post<string>(
			getHelpUrl('/supplier/request'),
			data
		);

		return response;
	},

	async getFqa() {
		const response = await axiosInstance.get<THelper.IHelp[]>(
			getHelpUrl('/faq')
		);
		return response;
	},

	async sendFeedback(data: THelper.FeedbackRequest) {
		const response = await axiosInstance.post<string>(
			getHelpUrl(`/feedback`),
			toFormData(data)
		);
		return response;
	}
};

export default HelpService;
