import Cookies from 'js-cookie';

export interface ITokens {
	access_token: string;
	refresh_token: string;
	token_type: string;
}

export const saveTokensStorage = (data: ITokens) => {
	localStorage.setItem('kira_login', 'true');
	localStorage.setItem('kira_token', data.access_token);

	window.dispatchEvent(new Event('storage'));
	window.dispatchEvent(new Event('cookiechange'));
};

export const removeTokensStorage = () => {
	Cookies.remove('kira_token');
	Cookies.remove('kira-country');
	Cookies.remove('kira_currency');
	localStorage.removeItem('kira_token');

	window.dispatchEvent(new Event('storage'));
	// window.dispatchEvent(new Event('cookiechange'));
};
