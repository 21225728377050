import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { ProductService } from 'shared/services';

export const useManufactureList = () => {
	const manufactureList = useQuery({
		queryFn: () => ProductService.getManufactureList(),
		queryKey: ['request partners list'],
		select: ({ data }) => data,
		retry: 0
	});

	const manufactureOptions = useMemo(() => {
		return (
			manufactureList.data?.map(({ id, name }) => ({
				value: id,
				label: name
			})) || []
		);
	}, [manufactureList.data]);

	return { ...manufactureList, manufactureOptions };
};
