import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

interface IProps extends PropsWithChildren {
	to: string;
	external?: boolean;
}

export const CustomLink: React.FC<IProps> = ({
	to,
	external = false,
	children
}) => {
	if (external) {
		return (
			<a href={to} rel='noopener noreferrer'>
				{children}
			</a>
		);
	}
	return <Link to={to}>{children}</Link>;
};
