import clsx from 'clsx';
import { useNavigate } from 'react-router';
import styles from './button.module.scss';
export const ButtonBack = () => {
	const navigate = useNavigate();
	const goBack = () => {
		navigate(-1);
	};
	return (
		<div className={clsx(styles.wrapper, 'btn-back')}>
			<button type='button' onClick={goBack}>
				Назад
			</button>
		</div>
	);
};
