import { FirstNumber, SecondNumber, ThirdNumber } from 'assets/Numbers';
import { PartnerPermissionModal } from 'entities/partners/permission-modal';
import VerificationStatus from 'entities/partners/verification-status';
import { useCompanyTypes } from 'features/company/api';
import { usePartnersActions } from 'features/partners/api/usePartnersActions';
import { useGetUser } from 'features/profile/api/useGetUser';
import {
	useMySupplierDetail,
	useSuppliersActions
} from 'features/suppliers/api';
import { useGetTariff } from 'features/tariff/api/useGetTariff';
import { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useAlertMessage } from 'shared/hooks';
import { TSupplier } from 'shared/services/types';
import Dropdown from 'shared/ui/dropdown';
import { FormWrapper } from 'shared/ui/form/form-wrapper';
import { tokenParse } from 'shared/utils';
import './ProfilePartners.scss';
import ProfilePartnersCapacities from './capacities';
import ProfilePartnersSurvey from './survey';
import ProfilePartnersTerms from './terms';

const partnerListAccess = [
	`Партнёр`,
	`Корпоративный партнёр`,
	`Прайс-лист с меткой "Под заказ"`,
	`Размещение прайс-листа`,
	'Стать партнёром на год'
];

export function ensureHttps(url: string): string {
	if (!url) return url;
	// Проверяем, начинается ли URL с 'http://' или 'https://'
	if (!/^https?:\/\//i.test(url)) {
		// Если нет, добавляем 'https://'
		return `https://${url}`;
	}
	return url;
}

const BecomeSupplier = () => {
	const methods = useForm();

	const { createSupplier } = useSuppliersActions();
	const { data: partnerInfo, ...partnerInfoRest } = useMySupplierDetail();
	const { typesOptions } = useCompanyTypes();

	const { addLogo } = usePartnersActions();
	const { userData } = useGetUser();
	const { tariffName, isPending } = useGetTariff();
	const { is_boss } = tokenParse();

	const { addAlertMessage } = useAlertMessage();
	const [showOverlay, setShowOverlay] = useState<string | boolean>(false);

	const [isOpen, setIsOpen] = useState(false);

	const onCloseModal = () => {
		setIsOpen(false);
		if (isOpen) {
			methods.setValue('types', typesOptions[0]);
		}
	};

	const handlePartnerData = async (data: FieldValues) => {
		const distributors = data?.distributors?.map((item: any) => item.value);

		const manufacturers = data?.manufacturers?.map((item: any) => item.value);

		const logo = data.logo;

		const warehouseCitiesIds = data?.warehouse_cities_ids?.map(
			(item: any) => item.value
		);

		const countriesIds = data.countries?.map((item: any) => item?.value);

		const dataObject: TSupplier.Create = {
			name: data.name,
			type: data.types.value,
			site_url: ensureHttps(data?.site_url),
			email: data?.email,
			city_id: data.city.value,
			description: data?.description,
			phone_number: data?.phone_number,
			countries_ids: countriesIds,
			warehouse_cities_ids: warehouseCitiesIds,
			manufacturers: manufacturers,
			suppliers_ids: distributors
		};

		try {
			await createSupplier.mutateAsync(dataObject as TSupplier.Create);
			addAlertMessage('Заявка на регистрацию поставщика отправлена', 'info');
			if (logo) {
				await addLogo.mutateAsync(logo);
			}
		} catch (error: any) {
			addAlertMessage(error, 'error');
			console.log(error);
		}
	};

	useEffect(() => {
		const { error, isFetching } = partnerInfoRest;

		if (!isFetching && error) {
			//@ts-ignore
			const errorMessage = error?.response?.data?.detail?.reason;
			if (!errorMessage?.includes('Компания не является поставщи')) {
				setShowOverlay(errorMessage);
			}
		} else if (partnerInfo?.type === 'PARTNER') {
			setShowOverlay(
				'К сожалению, вы не можете стать поставщиком, так как уже являетесь нашим партнером.'
			);
		}
	}, [partnerInfo, partnerInfoRest.error]);

	const checkSub = () => {
		return partnerListAccess.includes(tariffName);
	};

	const isCoorpTariff = checkSub();

	const types = methods.watch('types')?.value as TSupplier.SupplerType;
	const typesName = methods.watch('types')?.label;

	useEffect(() => {
		if (
			!['DISTRIBUTOR', 'MANUFACTURER'].includes(types) &&
			!isCoorpTariff &&
			!isPending
		) {
			setIsOpen(true);
		}
	}, [types, isCoorpTariff]);

	useEffect(() => {
		if (!is_boss && partnerInfo) {
			setShowOverlay('Вы не руководитель компании');
		}
	}, [is_boss, partnerInfo]);

	return (
		<>
			{partnerInfo?.status && (
				<VerificationStatus status={partnerInfo?.status} />
			)}

			<div className='profile-container partners-container'>
				<div className='warning-text'>
					<p>
						Пожалуйста, вводите достоверные данные, которые, при необходимости,
						сможете подтвердить документально
					</p>
					<p>
						<strong style={{ color: 'red' }}>*</strong> - обязательное поле для
						заполнения
					</p>
				</div>
				<FormWrapper
					methods={methods}
					onSubmit={handlePartnerData}
					className='terms-form'
					noValidateForm
				>
					<Dropdown
						title='Анкета'
						content={
							<ProfilePartnersSurvey
								methods={methods}
								defaultData={partnerInfo}
							/>
						}
						number={<FirstNumber />}
					/>
					<Dropdown
						title='Расскажите об условиях'
						content={
							<ProfilePartnersTerms
								methods={methods}
								defaultData={partnerInfo}
							/>
						}
						number={<SecondNumber />}
					/>
					{!['WEB_STORE', 'MANUFACTURER'].includes(types) && (
						<Dropdown
							title='Ваши компетенции'
							content={
								<ProfilePartnersCapacities
									methods={methods}
									defaultData={partnerInfo}
								/>
							}
							number={<ThirdNumber />}
						/>
					)}

					{!partnerInfo && (
						<button type='submit' className='btn partnerrequest-button'>
							Отправить на проверку
						</button>
					)}
				</FormWrapper>

				{(!!showOverlay || !is_boss) && (
					<div className='profile-overlay'>
						<div className='profile-overlay-content'>
							<p>
								{typeof showOverlay === 'string' ? (
									showOverlay
								) : (
									<>
										Для того чтобы стать поставщиком смените тариф на подходящий
										в разделе
										<Link to='/account/tariffs'> “Тарифы и оплата”</Link>
									</>
								)}
							</p>
						</div>
					</div>
				)}

				<PartnerPermissionModal
					isOpen={isOpen}
					onClose={onCloseModal}
					title={`Тип "${typesName}" можно приобрести на следующих тарифах:`}
					data={partnerListAccess}
				/>
			</div>
		</>
	);
};

export default BecomeSupplier;
