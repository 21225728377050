/**
 * Парсит URL и возвращает объект с хостом и параметрами запроса.
 *
 * @param {string} url - Строка URL для парсинга.
 * @returns {{ host: string; queryParams: Record<string, string> }} - Объект с хостом и параметрами запроса.
 */
export const parseUrl = (
	url: string
): { host: string; queryParams: Record<string, string> } => {
	try {
		const urlObj = new URL(url);
		const host = urlObj.host;
		const queryParams: Record<string, string> = {};

		// Заполняем объект queryParams
		urlObj.searchParams.forEach((value, key) => {
			queryParams[key] = value;
		});

		return {
			host,
			queryParams
		};
	} catch (error) {
		console.error('Invalid URL:', error);
		return {
			host: '',
			queryParams: {}
		};
	}
};
