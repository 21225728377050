import { FC, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import CommonContext from 'services/AuthContext';
import { URL_SITE } from 'shared/constants';
import { useAlertMessage } from 'shared/hooks';
import { TCompany, TSupplier, TUtils } from 'shared/services/types';
import { Rating } from 'shared/ui/rating';

interface IProps {
	data: TSupplier.Data;
	countries: TUtils.DataCountry[] | undefined;
	companyTypesData?: TCompany.CompanyType[];
}

export const CardSuppliers: FC<IProps> = ({
	data,
	countries,
	companyTypesData
}) => {
	const { addAlertMessage } = useAlertMessage();
	const { authState } = useContext(CommonContext);
	const navigate = useNavigate();

	const companies = useMemo(() => {
		if (data.company_types) {
			return data.company_types.map(item => {
				const name = companyTypesData?.find(v => v.key === item);
				return { name: name?.name, id: item };
			});
		}
		return companyTypesData?.filter(item => item.key === data.type) || [];
	}, [companyTypesData, data]);

	const onNavigatePartner = (data: TSupplier.Data) => {
		if (authState) return navigate(`/suppliers/${data.slug}`);
		addAlertMessage('Для просмотра нужно авторизоваться', 'info');
	};

	const country = countries?.find(item => data?.country_id === item.id);

	return (
		<div className='suppliers-item'>
			<div className='suppliers-item__card'>
				<div
					style={{ cursor: 'pointer' }}
					onClick={() => onNavigatePartner(data)}
				>
					<div className='suppliers-item__top'>
						<div className='suppliers-item__logo'>
							<img src={URL_SITE + data.logo} />
						</div>
						<div className='suppliers-item__country'>
							<img src={URL_SITE + country?.flag} />

							<p>
								{country?.name}
								<span>{data?.city}</span>
							</p>
						</div>
					</div>
					<div className='suppliers-item__languages'>
						<ul>
							{data?.countries?.map(item => (
								<li key={item.id}>
									<img
										className={'suppliers-item__languages-img'}
										alt={item.name}
										src={URL_SITE + item.flag}
									/>
								</li>
							))}
						</ul>
					</div>
					<div className='suppliers-item__rating'>
						<Rating count={Number(data?.rating)} />
					</div>
				</div>

				<div className='suppliers-item__site'>
					<span>
						<svg
							width='22'
							height='22'
							viewBox='0 0 22 22'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
							preserveAspectRatio='none'
						>
							<path
								d='M6.41732 3.05934C7.76561 2.2794 9.331 1.83301 11.0007 1.83301C16.0633 1.83301 20.1673 5.93706 20.1673 10.9997C20.1673 16.0623 16.0633 20.1663 11.0007 20.1663C5.93804 20.1663 1.83398 16.0623 1.83398 10.9997C1.83398 9.33002 2.28037 7.76464 3.06032 6.41634'
								stroke='#1E2321'
								strokeWidth='1.5'
								strokeLinecap='round'
							/>
							<path
								d='M13.5934 17.4821C13.2529 18.3333 12.8487 19.0086 12.4038 19.4692C11.959 19.9299 11.4822 20.167 11.0007 20.167C10.5191 20.167 10.0423 19.9299 9.59748 19.4692C9.15262 19.0086 8.74841 18.3333 8.40793 17.4821C8.06744 16.6309 7.79736 15.6204 7.61309 14.5083C7.42883 13.3961 7.33398 12.2041 7.33398 11.0003C7.33398 9.79654 7.42883 8.60455 7.61309 7.4924C7.79736 6.38024 8.06744 5.36972 8.40793 4.51851C8.74841 3.66731 9.15262 2.9921 9.59748 2.53143C10.0423 2.07076 10.5191 1.83366 11.0007 1.83366C11.4822 1.83366 11.959 2.07076 12.4038 2.53143C12.8487 2.9921 13.2529 3.66731 13.5934 4.51851C13.9339 5.36972 14.2039 6.38024 14.3882 7.49239C14.5725 8.60455 14.6673 9.79654 14.6673 11.0003C14.6673 12.2041 14.5725 13.3961 14.3882 14.5083'
								stroke='#1E2321'
								strokeWidth='1.5'
								strokeLinecap='round'
							/>
							<path
								d='M1.83398 11H9.16732M20.1673 11H12.834'
								stroke='#1E2321'
								strokeWidth='1.5'
								strokeLinecap='round'
							/>
						</svg>
					</span>

					<p>{data?.site_url?.replace(/^https?:\/\//, '')}</p>
				</div>
				<div className='suppliers-item__bottom'>
					<div className='time-zone'>
						<p>
							Телефон
							<span>{data?.phone_number}</span>
						</p>
					</div>
					<div className='response-time'>
						<p>
							Почта:
							<span>{data?.email}</span>
						</p>
					</div>
				</div>
			</div>
			<div className='suppliers-item__desc'>
				<p>
					<p>{data?.description}</p>
				</p>
				<ul className='tags'>
					{companies.map(item => (
						<li key={item.id}>{item.name}</li>
					))}
				</ul>
			</div>
		</div>
	);
};
