import clsx from 'clsx';
import { ButtonUp } from 'features/button-up';
import { useProductList } from 'features/product/api';
import { useGetTariff } from 'features/tariff/api/useGetTariff';
import { useGetCountries, useGetCurrencies } from 'features/utils/api';
import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CommonContext from 'services/AuthContext';
import { useQueryParams } from 'shared/hooks';
import { TProduct } from 'shared/services/types';

import { reportImg } from 'shared/assets/img/report';
import { ShowMore } from 'shared/ui/form/show-more';
import { Loader } from 'shared/ui/loader';
import './ReportPage.scss';
import { getQueryParams } from './helders';
import { TableProducts } from './table';

const ReportPage = () => {
	const params = useQueryParams() as any;

	const [searchText, setSearchText] = useState('');
	const [pagesData, setPagesData] = useState<any>();
	const [productsList, setProductsList] = useState<TProduct.Data[] | null>(
		null
	);
	const [emptyProducts, setEmptyProducts] = useState(false);
	const [openSidebar, setOpenSidebar] = useState(false);
	const [isEmptySearch, setIsEmptySearch] = useState(false);

	const defaultSort = { price: 'asc' };
	const defaultSortParams = params?.order_by
		? {
				[params?.order_by.replace('-', '')]: params?.order_by.includes('-')
					? 'desc'
					: 'asc'
		  }
		: defaultSort;

	const [sortTable, setSortTable] =
		useState<Record<any, any>>(defaultSortParams);

	const [openTableSettings, setOpenTableSettings] = useState(false);
	const { data, tariffName: tariff } = useGetTariff();

	const { productList } = useProductList(params, 50);
	const { data: countries } = useGetCountries();
	const { data: currencies } = useGetCurrencies();

	const [searchbarButtonsMob, setSearchbarButtonsMob] = useState(false);
	const { authState, currentCurrency } = useContext(CommonContext);

	const currencyValue = currentCurrency.value || 'RUB';

	const [quantityParam, setQuantityParam] = useState<any[]>([]);
	const [hasWarehouse, setHasWarehouse] = useState<any>();

	const [distributerParam, setDistributerParam] = useState<string[]>(
		getQueryParams()?.supplier_type || []
	);

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		setSearchText(value);
	};

	const location = useLocation();
	const navigate = useNavigate();

	const toggleSidebar = () => {
		setOpenSidebar(!openSidebar);
	};

	const toggleSearchbarButtonsMob = () => {
		setSearchbarButtonsMob(!searchbarButtonsMob);
	};

	const toggleTableSettings = () => {
		setOpenTableSettings(!openTableSettings);
	};

	const getProducts = async () => {
		const currentPath = window.location.href.split('/');
		const hasWareHouseUrl = getQueryParams()?.has_warehouse;
		const quantity = getQueryParams()?.quantity;

		const search = getQueryParams()?.search;
		setSearchText(decodeURI(search || '').replace('$', '#'));

		setQuantityParam(quantity || []);
		if (hasWareHouseUrl) setHasWarehouse(hasWareHouseUrl);

		if (currentPath.length > 4) {
			const path1 = currentPath[4].split('?')[1];
			const path2 = path1?.split('&');
			const path3 = path2?.find(element => element?.includes('fields='));
			const path4 = path3?.split('=')[1].split(',');
		} else {
			setProductsList(null);
			setPagesData(null);
		}
	};

	useEffect(() => {
		const { data, error } = productList;

		if (data?.results?.length && !error) {
			setEmptyProducts(false);
			setPagesData(data);

			setProductsList(data.results);
		} else if ((data?.results && error) || error) {
			setEmptyProducts(true);
			setPagesData(null);

			setProductsList(null);
		}
	}, [productList.data?.results, productList.error]);

	useLayoutEffect(() => {
		getProducts();
		setIsEmptySearch(false);
	}, [location.pathname, location.search]);

	useLayoutEffect(() => {
		if (getQueryParams()?.search) {
			setSearchPath();
		}
	}, [currencyValue, !!getQueryParams()?.search, distributerParam]);

	const setSearchPath = (isChange?: boolean) => {
		const search = getQueryParams()?.search;
		const searchRes = (searchText || search)?.replace('#', '$');

		setOpenTableSettings(false);
		setOpenSidebar(false);
		if (productList.isLoading) return;
		if (!search?.trim() && !searchText?.trim()) {
			return setIsEmptySearch(true);
		}
		setIsEmptySearch(false);

		const searchPath = `/report/?${searchRes ? `search=${searchRes}` : ''}`;

		navigate(searchPath);
	};

	useEffect(() => {
		if (location.pathname === '/') {
			setSortTable(defaultSort);
			setSearchText('');
		}
	}, [location]);

	const onSortTableProducts = (name: string) => {
		const newSort = sortTable[name]
			? { [name]: sortTable[name] === 'asc' ? 'desc' : 'asc' }
			: { [name]: 'asc' };

		setSortTable(newSort);
	};

	useEffect(() => {
		if (productList.data.results) {
			setSearchPath(true);
		}
	}, [sortTable]);

	return (
		<section className='report-page'>
			<section className='landing-banner'>
				<div className='container'>
					<div className='landing-banner__block'>
						<div className='landing-banner__info'>
							<h1>KИRA.ДОСЬЕ</h1>
							<p>
								KIRA собрал всю информацию о компаниях - о руководителях и
								учредителях, финансовые результаты и бухгалтерская отчетность,
								участие в контрактах и тендерах, все проверки и исполнительные
								производства в отношении компании.
							</p>
							<div
								className={clsx(
									'product-searchbar',
									searchbarButtonsMob && 'index-top'
								)}
							>
								<form
									onSubmit={e => {
										e.preventDefault();
										setSearchPath();
									}}
								>
									<div className='product-searchbar-block'>
										<input
											value={searchText}
											onChange={handleSearchChange}
											type='text'
											placeholder='Введите ИНН или название компании'
											required
										/>
										<button className='button-green'>
											<span>Проверить компанию</span>
										</button>
									</div>
								</form>
							</div>
						</div>
						<div className='landing-banner__img'>
							<img src={reportImg} alt='Поставщикам' />
						</div>
					</div>
				</div>
			</section>
			<div className='container main'>
				{productList.isLoading && <Loader isFixed />}

				<div className='product-page__row '>
					{!!emptyProducts && (
						<p className='emptyproducts-message'>
							К сожалению, по данному запросу не было найдено нужных вам
							компаний
						</p>
					)}

					{!!isEmptySearch && (
						<p className='emptyproducts-message'>Заполните поле поиска</p>
					)}
					{!!productsList && !!countries && !!currencies && (
						<TableProducts
							authState={authState}
							countries={countries}
							currentCurrency={currentCurrency}
							data={data}
							sortTable={sortTable}
							onSortTableProducts={onSortTableProducts}
							tariff={tariff}
							settingsParam={[]}
							productsList={productsList}
						/>
					)}

					{!!pagesData && (
						<div className='next-previous-page'>
							{productList.isFetching && !productList.isLoading && (
								<Loader isAbsolute isMini />
							)}

							{(productList.data.count || 0) >
								(productList.data.results?.length || 0) && (
								<ShowMore
									onClick={() => {
										productList.fetchNextPage();
									}}
								/>
							)}
						</div>
					)}
				</div>
			</div>
			<ButtonUp />
		</section>
	);
};

export default ReportPage;
