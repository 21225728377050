import { Outlet } from 'react-router';
import { Layout } from 'shared/ui/layout';
import ReportPage from 'widgets/reports';

const Report = () => {
	return (
		<Layout isOtherDomain>
			<Outlet />
			<ReportPage />
		</Layout>
	);
};

export default Report;
