import clsx from 'clsx';
import { FC, useState } from 'react';
import { TUtils } from 'shared/services/types';

interface IProps {
	data: TUtils.DataCountry[];
	name: string;
	defaultValue?: any;
	onSelect?: (data: TUtils.DataCountry) => void;
}

export const GroupBlock: FC<IProps> = ({
	data,
	name,
	defaultValue,
	onSelect
}) => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<div className={clsx('suppliers-filter-item__sub', isOpen && 'open')}>
			<div
				className='suppliers-filter-item__sub-title'
				onClick={() => setIsOpen(!isOpen)}
			>
				{name || 'Неизвестно'}

				<i>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='20'
						height='20'
						viewBox='0 0 20 20'
						fill='none'
					>
						<path
							d='M15.8307 7.5L9.9974 12.5L4.16406 7.5'
							stroke='#838383'
							strokeWidth='1.5'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
					</svg>
				</i>
			</div>
			<div className='suppliers-filter-item__sub-list'>
				{data?.map(item => (
					<label key={item.id}>
						<input
							type='checkbox'
							name='cng'
							checked={[...defaultValue].includes(item)}
							onClick={() => onSelect?.(item)}
						/>
						<span>{item.name}</span>
					</label>
				))}
			</div>
		</div>
	);
};
