import 'assets/input.scss';
import { FC, useEffect, useState } from 'react';
import './ProfilePartnersTerms.scss';

import { usePartnersActions } from 'features/partners/api';
import { useGetUser } from 'features/profile/api';
import {
	useGetCities,
	useGetCountries,
	useGetCurrencies,
	useGetLanguages
} from 'features/utils/api';
import moment from 'moment-timezone';
import { UseFormReturn, useFieldArray } from 'react-hook-form';
import { useAlertMessage } from 'shared/hooks';
import { REGEX_VERIFY } from 'shared/regex';
import { TSupplier } from 'shared/services/types';
import { Input } from 'shared/ui/form/input';
import { ReactSelect } from 'shared/ui/form/react-select';
import { MyOptionType } from 'shared/ui/form/types';
import {
	optionsDaysWork,
	optionsDocument,
	optionsNdsSelect
} from './constants';

interface IProps {
	defaultData?: TSupplier.DataDetail;
	methods: UseFormReturn<any, any, undefined>;
}

const ProfilePartnersTerms: FC<IProps> = ({ defaultData, methods }) => {
	const { userData } = useGetUser();

	const { citiesOptions } = useGetCities(
		userData?.spoken_language?.country_id,
		false
	);

	const {
		control,
		formState: { errors }
	} = methods;

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'workDays'
	});

	const { addAlertMessage } = useAlertMessage();
	const { updatePartner } = usePartnersActions();
	const { data: countries } = useGetCountries();
	const { data: currencies } = useGetCurrencies();
	const { data: languages } = useGetLanguages();

	const [languagesData, setLanguagesData] = useState<MyOptionType[]>([]);

	useEffect(() => {
		if (defaultData) {
			const partnerInfo = defaultData?.partner_info;

			const workDays = partnerInfo?.work_days?.map(item => ({
				day: optionsDaysWork.find(v => item.day === v.value),
				time: item.time.replaceAll('.', ':').split(' - ')
			}));

			const nds = optionsNdsSelect.find(
				item =>
					(item.value === 'yes' && partnerInfo?.nds) ||
					(item.value === 'no' && !partnerInfo?.nds)
			);

			const product_documents = optionsDocument.find(
				item => item.value === partnerInfo?.product_documents
			);

			const city = citiesOptions.find(item => item.label === defaultData.city);

			methods.reset({
				...methods.getValues(),
				product_delivery: partnerInfo?.product_delivery,
				surcharge: defaultData?.partner_info?.surcharge,
				email: defaultData.email,
				phone_number: defaultData.phone_number,
				answer_time: partnerInfo?.answer_time,
				site_url: defaultData.site_url,
				description: defaultData.description,
				workDays: workDays,
				nds: nds,
				product_documents: product_documents,
				city
			});
		}
	}, [defaultData, languagesData, citiesOptions]);

	useEffect(() => {
		if (countries && currencies && languages) {
			const languagesResponseData = languages?.map(i => {
				const foundFlag = countries.find(k => k.id === i.country_id);
				return {
					value: i.id,
					label: i.name,
					flag: foundFlag?.flag
				};
			});
			setLanguagesData(languagesResponseData);
		}
	}, [countries, currencies, languages]);

	const infoSubmit = async () => {
		methods.trigger();

		await new Promise(resolve => setTimeout(resolve, 100));

		const value = methods.getValues();
		const error = methods.formState.errors;

		const workdays = value?.workDays?.map((item: any) => ({
			day: item.day.value,
			time: item.time.join(' - ')
		}));

		const dataObject = {
			supplier: {
				email: value?.email,
				phone_number: value?.phone_number,
				site_url: value?.site_url,
				description: value?.description
			},
			answer_time: value?.answer_time,
			product_delivery: value?.product_delivery,
			product_documents: value?.product_documents?.value,

			nds: value?.nds?.value,
			time_zone: moment.tz.guess(),

			surcharge: value?.surcharge,

			work_days: workdays
		};

		const isError = Object.keys(error).find(item => {
			return error[item];
		});

		if (isError) return null;

		try {
			await updatePartner.mutateAsync(dataObject);
			addAlertMessage('Сохранено', 'success');
		} catch (error: any) {
			addAlertMessage(error, 'error');
		}
	};

	const addVendor = () => {
		const lastWorkday = fields.at(-1);

		const dayIndex = optionsDaysWork.findIndex(
			//@ts-ignore
			item => item.value === lastWorkday?.day?.value
		);

		const nextDay = optionsDaysWork[dayIndex + 1];

		//@ts-ignore
		append({ day: nextDay, time: lastWorkday.time });
	};

	const removeBill = (id: number) => {
		remove(id);
	};

	return (
		<>
			<ReactSelect
				control={control}
				name='nds'
				label='НДС'
				placeholder=''
				error={errors}
				rules={{ required: 'Обязательное поле для заполнения' }}
				required
				options={optionsNdsSelect}
			/>
			<div className='inputs-row'>
				<Input
					control={control}
					name='surcharge'
					label='Наценка до'
					placeholder='Наценка до'
					info='Партнерский статус предпологает возможность выкупа через партнера товара у определенного дистрибьютора.                                                                                                                                     Укажите максимальную наценку на товар, которую вы хотели бы получить с клиента, но помните клиент может с Вами поторговаться, не отказывайте ему в этом удовольствии.                           Сильно занизив % вы привлечете больше клиентов, но рискуете прибылью. Если сильно завысить % клиент может не обратится к Вам. Рекомендация KIRA - не ставить ниже 3% и не больше 10%.'
					required
					error={errors}
					rules={{
						required: 'Обязательное поле для заполнения',

						pattern: {
							value: REGEX_VERIFY.NUMBER,
							message: 'Укажите валидное значение'
						}
					}}
				/>

				<ReactSelect
					control={control}
					name='product_documents'
					label='Документы на товар'
					placeholder=''
					error={errors}
					rules={{ required: 'Обязательное поле для заполнения' }}
					required
					options={optionsDocument}
					info='Некоторые пользователи могут находится за границей и им потрубется ряд документов для прохождения таможни, в том числе такие данные как ГТД. Если вы сможете предоставить все нужные данные и подготовить документы укажите "С таможней"'
				/>
			</div>

			<ReactSelect
				control={control}
				name='city'
				label='Город'
				placeholder=''
				error={errors}
				rules={{ required: 'Обязательное поле для заполнения' }}
				required
				options={citiesOptions}
			/>

			<Input
				control={control}
				name='product_delivery'
				label='Доставка товара:'
				placeholder='Доставка товара:'
				required
				error={errors}
				info='Укажите регион доставки. К примеру если вы можете доставить товар только по г. Москве, так и укажите. Если вы можете отправлять товар по всей России или за рубеж, напишите об этом.'
				rules={{ required: 'Обязательное поле для заполнения' }}
			/>

			<Input
				control={control}
				name='email'
				label='Email:'
				placeholder='Email:'
				required
				info='Укажите электронную почту'
				error={errors}
				rules={{
					required: 'Обязательное поле для заполнения',
					pattern: {
						value: REGEX_VERIFY.EMAIL_REGEX,
						message: 'Укажите валидный email'
					}
				}}
			/>

			<div className='inputs-row'>
				<Input
					control={control}
					name='phone_number'
					label='Номер телефона'
					required
					placeholder='Номер телефона'
					error={errors}
					rules={{
						required: 'Обязательное поле для заполнения',
						pattern: {
							message: 'Введите корректный номер телефона',
							value: REGEX_VERIFY.PHONE_REGEX
						}
					}}
				/>

				<Input
					control={control}
					name='answer_time'
					label='Время ответа на запрос:'
					required
					type='time'
					min='00:00'
					max='23:59'
					placeholder='Время ответа на запрос:'
					info='Как быстро вы отвечаете на запросы, укажите время в часах или минутах. К примеру: 1 час или 30 мин.'
					error={errors}
					rules={{ required: 'Обязательное поле для заполнения' }}
				/>
			</div>

			<Input
				control={control}
				name='site_url'
				label='Адрес сайта:'
				required
				placeholder='Адрес сайта:'
				error={errors}
				rules={{
					required: 'Обязательное поле для заполнения',
					pattern: {
						value: REGEX_VERIFY.URL_SITE,
						message: 'Укажите валидный адрес сайта'
					}
				}}
			/>

			{fields?.map((input, index) => {
				return (
					<div key={index} className='inputs-row'>
						<div className={'select'}>
							<ReactSelect
								control={control}
								name={`workDays.${index}.day`}
								label='Рабочий день'
								placeholder=''
								// @ts-ignore
								error={errors?.workDays?.[index]?.day?.message}
								rules={{ required: 'Обязательное поле для заполнения' }}
								required
								options={optionsDaysWork}
							/>
						</div>

						<div className={'time-picker'}>
							<Input
								control={control}
								name={`workDays.${index}.time.0`}
								label='От'
								required
								type='time'
								min='00:00'
								max='23:59'
								placeholder='От'
								//@ts-ignore
								error={errors?.workDays?.[index]?.time[0]?.message}
								rules={{ required: 'Обязательное поле для заполнения' }}
							/>
							<div style={{ marginBottom: '20px' }}>-</div>
							<Input
								control={control}
								name={`workDays.${index}.time.1`}
								label='До'
								required
								type='time'
								min='00:00'
								max='23:59'
								placeholder='До'
								//@ts-ignore
								error={errors?.workDays?.[index]?.time[1]?.message}
								rules={{ required: 'Обязательное поле для заполнения' }}
							/>
						</div>

						{fields?.length && (
							<button
								type='button'
								className='remove-row'
								onClick={() => removeBill(index)}
							>
								<svg
									width='9'
									height='9'
									viewBox='0 0 9 9'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
									preserveAspectRatio='none'
								>
									<path
										d='M8 1.00003L1.00003 8M1 1L7.99997 7.99997'
										stroke='#8A7DE7'
										strokeOpacity='1'
										strokeLinecap='round'
									/>
								</svg>
							</button>
						)}
					</div>
				);
			})}

			{fields.length < 7 && (
				<div style={{ marginBottom: '1rem' }}>
					<button className='btn mobi-full' type='button' onClick={addVendor}>
						Добавить день
					</button>
				</div>
			)}

			<Input
				control={control}
				className='textarea-block'
				textArea
				name='description'
				label='Текстовое поле для инструкции'
				placeholder='Текстовое поле для инструкции'
				info='Расскажите немного о своей компании, как пользователю оформить заказ в максимально короткие сроки, какие документы подготовить для сделки. Так же вы можете указать дополнительную информацию которая поможет пользователю принять решение о сотрудничестве
				'
				error={errors}
			/>

			{defaultData && (
				<div className='block-save'>
					<button className='btn' type='button' onClick={infoSubmit}>
						Сохранить
					</button>
				</div>
			)}
		</>
	);
};

export default ProfilePartnersTerms;
function useGetCity() {
	throw new Error('Function not implemented.');
}
