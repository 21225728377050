import { BlurOverlay } from 'entities/blur-overlay';
import { DialogFooter, DialogHeader, MessageItem } from 'entities/chat';
import { useChatActions, useWebSocketClient } from 'features/chat/api';
import { useChatMessages } from 'features/chat/api/useChat';
import { groupByDate } from 'features/chat/utils';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAlertMessage } from 'shared/hooks';
import { Loader } from 'shared/ui/loader';
import { createUTCDateNow, fileToBase64, tokenParse } from 'shared/utils';
import './ProfileChat.scss';
import { IChatHistory } from './chat.interface';

const ProfileChat = () => {
	const { addAlertMessage } = useAlertMessage();
	const { user_id, is_staff } = tokenParse();
	const methods = useForm();
	const userId = methods.watch('user')?.value;

	const ref = useRef<HTMLDivElement>(null);
	const [profileError, setProfileError] = useState<string>();

	const [message, setMessage] = useState('');

	const { onSendFile } = useChatActions();

	const { data, error, isLoading } = useChatMessages(
		{
			page: 1,
			page_size: 100,
			receiver_id: userId
		},
		is_staff
	);

	const { lastMessage, sendJsonMessage } = useWebSocketClient(error =>
		setProfileError(error)
	);

	const [chatHistory, setChatHistory] = useState<IChatHistory[]>([]);
	const [chatFile, setChatFile] = useState<File | null>(null);

	const chatHistoryDate = useMemo(() => {
		if (chatHistory) {
			return groupByDate(chatHistory);
		}
		return [];
	}, [chatHistory]);

	const handleFileUpload = (file: File) => {
		setChatFile(file);
	};

	const getChatHistory = async () => {
		//@ts-ignore
		const responseError = error?.response;

		if (error && responseError?.status === 403) {
			setProfileError(responseError.data.detail.reason);
		} else if (data?.results) {
			const result = [...(data?.results || [])]?.reverse();
			setChatHistory(result);
		}
	};

	useEffect(() => {
		getChatHistory();
	}, [data, error]);

	useEffect(() => {
		if (lastMessage?.data) {
			const wsData = JSON.parse(lastMessage.data);
			const fromUserId = wsData.from_user_id;
			if ((is_staff && userId === fromUserId) || !is_staff) {
				setChatHistory(prev => [
					...prev.map(i => ({ ...i, is_read: true })),
					{ ...wsData }
				]);
			}
		}
	}, [lastMessage]);

	const sendFirstFile = async () => {
		try {
			if (chatFile) {
				const array = [...chatHistory].at(-1);
				const number = message ? 1 : 0;
				const id = array ? array.id + number : number;

				const extraData = { message_id: id };

				await onSendFile.mutateAsync({
					file: chatFile,
					...extraData
				});
			}
			setMessage('');
			setChatFile(null);
		} catch (error: any) {
			addAlertMessage(error, 'error');
			setChatFile(null);
			setMessage('');
		}
	};

	const sendChatMessage = async () => {
		try {
			if (message || chatFile) {
				const base64String = await fileToBase64(chatFile);
				const dataMessage = {
					content: message,
					file: base64String,
					...(is_staff
						? {
								to_user_id: userId
						  }
						: {})
				};
				sendJsonMessage(dataMessage);

				setChatHistory(prev => [
					...prev,
					{
						id: prev?.at?.(-1)?.id || 0 + 1,
						content: message,
						created_at: createUTCDateNow(),
						from_user_id: user_id,
						to_user_id: null,
						is_read: false,
						isCreate: true,
						file: chatFile || null
					}
				]);
			}
			setChatFile(null);
			setMessage('');
			// await sendFirstFile();
		} catch (error: any) {
			addAlertMessage(error, 'error');
			setChatFile(null);
			setMessage('');
		}
	};

	const scrollToBottom = () => {
		const element = ref.current;

		if (element) {
			element.scrollTo({
				top: element.scrollHeight + 100000,
				behavior: 'smooth'
			});
		}
	};

	useEffect(() => {
		setTimeout(() => {
			scrollToBottom();
		}, 200);
	}, [chatHistory, ref]);

	return (
		<div className='chat'>
			<DialogHeader methods={methods} isAdmin={is_staff} />
			{profileError && <BlurOverlay error={profileError} isWindow />}

			<div className='chat-content' ref={ref}>
				{isLoading && <Loader isAbsolute isMini />}
				{/* {!!chatHistory.length && <MessageCommand chatHistory={chatHistory} />} */}

				{chatHistoryDate.map(item => (
					<>
						<div className='chat-dialog__date' key={item.date}>
							{item.date}
						</div>
						{item.items.map(i => (
							<MessageItem
								key={i?.id}
								isAdmin={is_staff}
								i={i}
								isMe={i?.from_user_id !== user_id}
							/>
						))}
					</>
				))}

				{!!onSendFile.isPending && <Loader isMini />}
			</div>

			{((userId && is_staff) || !is_staff) && (
				<DialogFooter
					handleFileUpload={handleFileUpload}
					onSubmit={sendChatMessage}
					chatFile={chatFile}
					message={message}
					setMessage={setMessage}
					disabled={onSendFile.isPending}
					clearFile={() => {
						setChatFile(null);
						setMessage('');
					}}
				/>
			)}
		</div>
	);
};

export default ProfileChat;
