import { FC, PropsWithChildren } from 'react';
import {
	FieldValues,
	FormProvider,
	SubmitErrorHandler,
	SubmitHandler,
	UseFormReturn
} from 'react-hook-form';

type TProps = PropsWithChildren & {
	onSubmit?: SubmitHandler<any>;
	onError?: SubmitErrorHandler<FieldValues>;
	methods: UseFormReturn<any, any, undefined>;
	className?: string;
	noValidateForm?: boolean;
	style?: {
		[key: string]: string;
	};
};

export const FormWrapper: FC<TProps> = ({
	children,
	onSubmit = () => {},
	onError = () => {},
	methods,
	className,
	style = {},
	noValidateForm
}) => {
	return (
		<FormProvider {...methods}>
			<form
				className={className}
				onSubmit={e => {
					e.stopPropagation();
					methods.handleSubmit(onSubmit, onError)(e);
				}}
				onError={e => console.log(e)}
				style={style}
				noValidate={noValidateForm}
			>
				{children}
			</form>
		</FormProvider>
	);
};
