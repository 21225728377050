import 'assets/input.scss';
import { useManufactureList } from 'features/product/api';
import { AddDistributorPopup } from 'features/profile/ui/add-distributor-modal';
import { useSuppliersActions } from 'features/suppliers/api';
import { FC, useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useAlertMessage } from 'shared/hooks';
import { TSupplier } from 'shared/services/types';
import { ReactSelectMulti } from 'shared/ui/form/react-select-multi';
import { PromptText } from 'shared/ui/prompt-text';
import './ProfilePartnersCapacities.scss';

interface IProps {
	defaultData?: TSupplier.DataDetail;
	methods: UseFormReturn<any, any, undefined>;
}

const ProfilePartnersCapacities: FC<IProps> = ({ defaultData, methods }) => {
	const [isOpen, setIsOpen] = useState(false);
	const { manufactureOptions } = useManufactureList();
	const { addAlertMessage } = useAlertMessage();
	const {
		control,
		formState: { errors }
	} = methods;

	const { updateSupplier } = useSuppliersActions();

	useEffect(() => {
		if (defaultData) {
			const manufacturers = defaultData?.manufacturers?.map(v => ({
				value: v.id,
				label: v.manufacturer
			}));

			methods.reset({
				...methods.getValues(),
				manufacturers
			});
		}
	}, [defaultData, manufactureOptions]);

	const onSubmit = async () => {
		methods.trigger();

		await new Promise(resolve => setTimeout(resolve, 100));

		const value = methods.getValues();
		const error = methods.formState.errors;
		const dataObject = {
			manufacturers: value?.manufacturers?.map((item: any) => item.value)
		};

		const isError = Object.keys(dataObject).find(item => {
			return error[item];
		});

		if (isError) return null;

		try {
			//TODO навести тут порядок
			//@ts-ignore
			await updateSupplier.mutateAsync(dataObject);
			addAlertMessage('Сохранено', 'success');
		} catch (error: any) {
			addAlertMessage(error, 'error');
		}
	};

	return (
		<>
			<div className='capacities-form'>
				<div className='select-block' id='distributor-field'>
					<p style={{ position: 'relative' }}>
						Действующим дистрибьютором каких вендоров являетесь?
						<div
							style={{ position: 'relative', width: '15px', height: '15px' }}
						>
							<PromptText
								text={`Выберите поставщиков с которыми вы работаете, если таковые
							отсутствуют в списке, отправить запрос на добавление.`}
							/>
						</div>
					</p>
					<ReactSelectMulti
						control={control}
						options={manufactureOptions}
						name='manufacturers'
						placeholder=''
						error={errors}
					/>

					<div className='not-found-distributors'>
						<p>
							Не нашли вендора в списке? Отправьте нам запрос на добавление!
						</p>
						<button onClick={() => setIsOpen(!isOpen)} type='button'>
							Отправить запрос
						</button>
					</div>
				</div>

				{defaultData && (
					<div className='block-save'>
						<button className='btn' type='button' onClick={onSubmit}>
							Сохранить
						</button>
					</div>
				)}
			</div>
			<AddDistributorPopup
				isVendor
				isOpen={isOpen}
				onClose={() => {
					setIsOpen(false);
				}}
			/>
		</>
	);
};

export default ProfilePartnersCapacities;
