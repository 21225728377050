import { FC } from 'react';
import MainLogo from '../../assets/MainLogo';
import { CabLogo, ListLogo, SettingsLogo } from '../../entities/header/logos';
import LanguagesFlags from '../../features/header/ui/language-flags';
import './Header.scss';

interface IProps {
	openMenu?: () => void;
	openProfile: () => void;
	openSettings?: (onClose?: boolean) => void;
	onCloseSettings: any;
	isOtherDomain?: boolean;
}

export const Header: FC<IProps> = ({
	openMenu,
	openProfile,
	openSettings,
	onCloseSettings,
	isOtherDomain
}) => {
	return (
		<header className='header'>
			<div className='container'>
				<nav className='header__nav'>
					<div className='logos'>
						<LanguagesFlags openSettings={onCloseSettings} />
						{!isOtherDomain && <SettingsLogo turnSettings={openSettings} />}
					</div>
					<div onClick={onCloseSettings}>
						<MainLogo />
					</div>
					<div className='logos'>
						<ListLogo isOtherDomain={isOtherDomain} />

						<CabLogo turnProfile={openProfile}  isOtherDomain={isOtherDomain}/>
					</div>
				</nav>
			</div>
		</header>
	);
};
