import { FC } from 'react';

import './ListWarehouseCities.scss';

interface IProps {
	list: string[];
}
export const ListWarehouseCities: FC<IProps> = ({ list }) => {
	return (
		<div className='ListWarehouseCitiesWrapper'>
			<div className='title'>Имеет склады в городах</div>
			<div className='list'>
				{list.map(v => (
					<div key={v} className='item'>
						{v}
					</div>
				))}
			</div>
		</div>
	);
};
