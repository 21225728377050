import React from 'react';
import { TPartner } from 'shared/services/types';
import { getWeekDay } from './utils/getWeekDay';

interface WorkDay {
	time: string;
	day: number;
}

const groupWorkDays = (workDays?: TPartner.Workdays[]) => {
	const grouped: { [key: string]: number[] } = {};

	workDays?.forEach(({ time, day }) => {
		if (!grouped[time]) {
			grouped[time] = [];
		}
		grouped[time].push(day);
	});

	return grouped;
};

const formatGroupedWorkDays = (grouped: { [key: string]: number[] }) => {
	return Object.entries(grouped).map(([time, days]) => {
		const uniqueDays = Array.from(new Set(days));
		const startDay = Math.min(...uniqueDays);
		const endDay = Math.max(...uniqueDays);

		const dayRange =
			startDay === endDay
				? getWeekDay(startDay)
				: `${getWeekDay(startDay)}-${getWeekDay(endDay)}`;

		return `${dayRange}: ${time}`;
	});
};

interface IProps {
	workDays?: TPartner.Workdays[];
}

export const WorkDays: React.FC<IProps> = ({ workDays }) => {
	const groupedWorkDays = groupWorkDays(workDays);
	const formattedWorkDays = formatGroupedWorkDays(groupedWorkDays);

	return (
		<div>
			{formattedWorkDays.map((item, index) => (
				<div key={index}>{item}</div>
			))}
		</div>
	);
};
