import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SupplierService } from 'shared/services';
import ProjectService from 'shared/services/project.service';
import { TSupplier } from 'shared/services/types';
import { TProject } from 'shared/services/types/project.types';

export const useSuppliersActions = (id?: number) => {
	const queryClient = useQueryClient();

	const createSupplier = useMutation({
		mutationFn: (data: TSupplier.Create) =>
			SupplierService.createSupplier(data),
		mutationKey: ['create supplier'],
		onSuccess: ({ data }) => {
			queryClient.invalidateQueries({ queryKey: [`get project list`] });
			return data;
		}
	});

	const updateSupplier = useMutation({
		mutationFn: (data: TSupplier.Update) =>
			SupplierService.updateSupplier(data),
		mutationKey: ['update supplier'],
		onSuccess: ({ data }) => {
			queryClient.invalidateQueries({ queryKey: [`get project list`] });
			return data;
		}
	});

	const createProject = useMutation({
		mutationFn: (data: TProject.Create) => ProjectService.create(data),
		mutationKey: ['create project'],
		onSuccess: ({ data }) => {
			queryClient.invalidateQueries({ queryKey: [`get project list`] });
			return data;
		}
	});

	const updateProject = useMutation({
		mutationFn: (data: Omit<TProject.Update, 'id'>) =>
			ProjectService.updateProject(id!, data),
		mutationKey: ['update project'],
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [`get project list`] });
		}
	});

	const addProductProject = useMutation({
		mutationFn: (data: { data: TProject.AddProduct; id: number }) =>
			ProjectService.addProduct(data.id, data.data),
		mutationKey: ['add product project', id]
	});

	const deleteProject = useMutation({
		mutationFn: () => ProjectService.deleteProject(id!),
		mutationKey: ['delete project', id]
	});

	return {
		createSupplier,
		updateSupplier,
		addProductProject,
		createProject,
		deleteProject,
		updateProject
	};
};
