import 'app/styles/modal.scss';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { CloseIconJSX } from 'shared/assets/svg/common';
import { useOutsideClick } from 'shared/hooks';

interface IProps {
	isOpen: boolean;
	onClose: () => void;
	data: string[];
	title?: string;
}

export const PartnerPermissionModal: FC<IProps> = ({
	isOpen,
	onClose,
	title,
	data
}) => {
	const { ref } = useOutsideClick(onClose);
	return (
		<>
			<div className={`modal modal-complaint ${isOpen ? 'show' : 'hide'}`}>
				<div className='modal__window' ref={ref}>
					<div className='modal__header'>
						<h3>{title}</h3>
						<button className='modal__close' onClick={onClose}>
							<CloseIconJSX />
						</button>
					</div>
					<div className='modal__text partner-modal'>
						<p>
							{data.map(item => (
								<div>-{item}</div>
							))}
						</p>
						<button type='button' className='btn' onClick={onClose}>
							<Link to={'/account/tariffs'}>Тарифы и оплата</Link>
						</button>
					</div>
				</div>
			</div>
		</>
	);
};
