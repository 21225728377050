import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { URL_SITE } from 'shared/constants';

import UtilsService from 'shared/services/utils.service';

export const useGetLanguages = () => {
	return useQuery({
		queryFn: () => UtilsService.getLanguages(),
		queryKey: ['get languages'],
		select: ({ data }) => data,
		refetchOnWindowFocus: 'always',
		refetchOnReconnect: 'always',
		placeholderData: keepPreviousData,
		staleTime: 1000 * 60 * 5, // Данные будут считаться свежими в течение 5 минут
		retry: 5
	});
};

export const useGetCurrencies = (isOptions?: boolean) => {
	return useQuery({
		queryFn: () => UtilsService.getCurrencies(),
		queryKey: ['get currencies'],
		refetchOnWindowFocus: 'always',
		refetchOnReconnect: 'always',
		placeholderData: keepPreviousData,
		staleTime: 1000 * 60 * 5, // Данные будут считаться свежими в течение 5 минут
		select: ({ data }) => data,
		retry: 5
	});
};

export const useGetCountries = () => {
	const res = useQuery({
		queryFn: () => UtilsService.getCountries(),
		queryKey: ['get counties'],
		select: ({ data }) => data,
		refetchOnWindowFocus: 'always',
		refetchOnReconnect: 'always',
		placeholderData: keepPreviousData,
		staleTime: 1000 * 60 * 5, // Данные будут считаться свежими в течение 5 минут
		retry: 5
	});
	const countriesOptions = useMemo(() => {
		return res.data?.map(item => ({ label: item.name, value: item.id })) || [];
	}, [res.data]);
	return { ...res, countriesOptions };
};

export const useGetCities = (countryId?: string, isOnlyByCountry?: boolean) => {
	const { data } = useGetCountries();
	// Получаем данные из кеша по ключу

	const result = useQuery({
		queryFn: () => UtilsService.getCities(countryId),
		queryKey: ['get cities', countryId],
		select: ({ data }) => data,
		enabled: !isOnlyByCountry,
		refetchOnWindowFocus: 'always',
		refetchOnReconnect: 'always',
		placeholderData: keepPreviousData,
		staleTime: 1000 * 60 * 5 // Данные будут считаться свежими в течение 5 минут
	});

	const citiesOptions = useMemo(() => {
		return (
			result.data?.map(city => {
				const foundFlag = data?.find(k => k.id === city.country_id);

				return {
					label: city.name,
					value: city.id,
					flag: URL_SITE + foundFlag?.flag,

					extra: { countryId: city.country_id }
				};
			}) || []
		);
	}, [result.data, data]);

	return { ...result, citiesOptions };
};

export const useGetCountriesRegion = () => {
	return useQuery({
		queryFn: () => UtilsService.getCountriesRegion(),
		queryKey: ['get counties region'],
		select: ({ data }) => data,
		refetchOnWindowFocus: 'always',
		refetchOnReconnect: 'always',
		placeholderData: keepPreviousData,
		staleTime: 1000 * 60 * 5, // Данные будут считаться свежими в течение 5 минут
		retry: 5
	});
};

export const useGetExchange = () => {
	return useQuery({
		queryFn: () => UtilsService.getCurrenciesExchange(),
		queryKey: ['get exchange'],
		refetchOnWindowFocus: 'always',
		refetchOnReconnect: 'always',
		placeholderData: keepPreviousData,
		staleTime: 1000 * 60 * 5, // Данные будут считаться свежими в течение 5 минут
		select: ({ data }) => data,
		retry: 5
	});
};

export const useGetDelivery = () => {
	return useQuery({
		queryFn: () => UtilsService.getDeliveryServices(),
		queryKey: ['get delivery'],
		refetchOnWindowFocus: 'always',
		refetchOnReconnect: 'always',
		placeholderData: keepPreviousData,
		staleTime: 1000 * 60 * 5, // Данные будут считаться свежими в течение 5 минут
		select: ({ data }) => data,
		retry: 5
	});
};
