import 'assets/input.scss';
import { FC, useEffect, useMemo } from 'react';
import './ProfilePartnersTerms.scss';

import { useGetUser } from 'features/profile/api';
import { useSuppliersActions } from 'features/suppliers/api';
import {
	useGetCities,
	useGetCountries,
	useGetCurrencies,
	useGetLanguages
} from 'features/utils/api';
import { UseFormReturn } from 'react-hook-form';
import { useAlertMessage } from 'shared/hooks';
import { TSupplier } from 'shared/services/types';
import { ReactSelectMulti } from 'shared/ui/form/react-select-multi';
import { PromptText } from 'shared/ui/prompt-text';

interface IProps {
	defaultData?: TSupplier.DataDetail;
	methods: UseFormReturn<any, any, undefined>;
}

const ProfilePartnersTerms: FC<IProps> = ({ defaultData, methods }) => {
	const { countriesOptions, data: countries } = useGetCountries();
	const { userData } = useGetUser();

	const { citiesOptions } = useGetCities();

	const {
		control,
		formState: { errors }
	} = methods;

	const { addAlertMessage } = useAlertMessage();
	const { updateSupplier } = useSuppliersActions();
	const { data: currencies } = useGetCurrencies();
	const { data: languages } = useGetLanguages();

	const languagesData = useMemo(() => {
		if (countries && currencies && languages) {
			const languagesResponseData = languages?.map(i => {
				const foundFlag = countries.find(k => k.id === i.country_id);
				return {
					value: i.id,
					label: i.name,
					flag: foundFlag?.flag
				};
			});

			return languagesResponseData;
		}
		return [];
	}, [countries, currencies, languages]);

	useEffect(() => {
		if (defaultData) {
			const city = citiesOptions.find(item => item.label === defaultData.city);
			const warehouse_cities_ids = citiesOptions.filter(v =>
				defaultData.warehouse_cities?.map(item => item.id)?.includes(v.value)
			);

			const countries = countriesOptions.filter(v =>
				defaultData.countries_ids?.includes(v.value)
			);

			methods.reset({
				...methods.getValues(),
				...defaultData,
				email: defaultData.email,
				phone_number: defaultData.phone_number,
				site_url: defaultData.site_url,
				description: defaultData.description,
				warehouse_cities_ids,
				countries: countries,
				city
			});
		}
	}, [defaultData, languagesData, citiesOptions]);

	const infoSubmit = async () => {
		methods.trigger();

		await new Promise(resolve => setTimeout(resolve, 100));

		const value = methods.getValues();
		const error = methods.formState.errors;

		const warehouseCitiesIds = value?.warehouse_cities_ids?.map(
			(item: any) => item.value
		);

		const countriesIds = value.countries?.map((item: any) => item?.value);

		const dataObject = {
			countries_ids: countriesIds,
			warehouse_cities_ids: warehouseCitiesIds
		};

		const isError = Object.keys(error).find(item => {
			return error[item];
		});

		if (isError) return null;

		try {
			await updateSupplier.mutateAsync(dataObject);
			addAlertMessage('Сохранено', 'success');
		} catch (error: any) {
			addAlertMessage(error, 'error');
		}
	};

	return (
		<>
			<div className='select-block' id='distributor-field'>
				<p style={{ position: 'relative' }}>
					С какими странами работаете? <span>*</span>
					<div style={{ position: 'relative', width: '15px', height: '15px' }}>
						<PromptText
							text={`Выберите страны, в которые вы поставляете товары`}
						/>
					</div>
				</p>
				<ReactSelectMulti
					control={control}
					options={countriesOptions}
					name='countries'
					rules={{ required: 'Обязательное поле для заполнения' }}
					placeholder=''
					error={errors}
				/>
			</div>

			<div className='select-block' id='distributor-field'>
				<p style={{ position: 'relative' }}>
					В каких городах есть склады?
					<div style={{ position: 'relative', width: '15px', height: '15px' }}>
						<PromptText
							text={`В каких городах находятся склады вашей компании`}
						/>
					</div>
				</p>
				<ReactSelectMulti
					control={control}
					options={citiesOptions}
					name='warehouse_cities_ids'
					placeholder=''
					error={errors}
				/>
			</div>

			{defaultData && (
				<div className='block-save'>
					<button className='btn' type='button' onClick={infoSubmit}>
						Сохранить
					</button>
				</div>
			)}
		</>
	);
};

export default ProfilePartnersTerms;
