import { FC } from 'react';
import { Link } from 'react-router-dom';
import { countryCodeName, URL_SITE } from 'shared/constants';
import { useValueCurrency } from 'shared/hooks';
import { TProduct } from 'shared/services/types/products.types';
import { TSubscription } from 'shared/services/types/subscription.types';
import { TUtils } from 'shared/services/types/utils.types';
import { BitDot } from 'shared/ui';
import { tariffsBlur } from '.';

export interface IProps {
	productsList: TProduct.Data[];
	settingsParam?: string[];
	countries: TUtils.DataCountry[];
	authState: boolean;
	tariff: string;
	data?: TSubscription.TariffUser;
	currentPriceValue: (prices: any[], unrounded?: boolean) => string | number;
}

export const TableMobile: FC<IProps> = ({
	productsList,
	settingsParam,
	countries,
	authState,
	data
}) => {
	const { getCurrencyCounty, currency } = useValueCurrency(false, 0);

	return (
		<div className='products-mob'>
			{productsList.map(i => {
				return (
					<div className='products-mob-item' key={i.id}>
						{
							<div className='products-mob-item__row'>
								<div className='products-mob-item__col-name'>
									{settingsParam?.includes('country_id') && 'Страна'}
									<br />
									ИНН
								</div>
								<div className='products-mob-item__col-value'>
									<>
										<img
											className='flag'
											src={
												URL_SITE +
												countries?.find(l => i.country_id === l.id)?.flag
											}
											height='24'
											width='34'
										/>

										<p className='code'>
											{/* @ts-ignore */}
											{countryCodeName[i.country_id]}
										</p>
									</>

									<p className='article'>{i.article}</p>
								</div>
							</div>
						}
						<div className='products-mob-item__row'>
							<div className='products-mob-item__col-name'>Название</div>
							<div className='products-mob-item__col-value'>
								<p>{i.name}</p>
							</div>
						</div>
						<div className='products-mob-item__row'>
							<div className='products-mob-item__col-name'>Юр. адрес</div>
							<div
								className='products-mob-item__col-value'
								aria-label='Доступно'
							>
								<p>{i.stock_available}</p>
							</div>
						</div>
						<div className='products-mob-item__row'>
							<div className='products-mob-item__col-name'>
								Уставной капитал
							</div>
							<div
								className='products-mob-item__col-value'
								aria-label='В транзите'
							>
								<p>{i.transit_available}</p>
							</div>
						</div>

						<div className='products-mob-item__row'>
							<div className='products-mob-item__col-name products-mob-item__col-name-center'>
								Цена
							</div>
							<div className='products-mob-item__col-value'>
								<div className='price'>
									<p>
										<BitDot
											text={
												getCurrencyCounty(
													i.supplier?.prices?.[2]?.price,
													true,
													true
												) || 'Уточняйте'
											}
										/>{' '}
										{!isNaN(Number(i.supplier?.prices?.[2]?.price))
											? currency
											: ''}
									</p>
								</div>
								{authState && !tariffsBlur.includes(data?.tariff?.id) && (
									<Link className='btn' to='/account/tariffs/'>
										Купить отчет
									</Link>
								)}
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
};
