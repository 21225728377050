import { useCompanyTypes } from 'features/company/api';
import { usePartnersActions } from 'features/partners/api/usePartnersActions';
import { useGetUser } from 'features/profile/api';
import { AddDistributorPopup } from 'features/profile/ui/add-distributor-modal';
import { useSupplierList, useSuppliersActions } from 'features/suppliers/api';
import { useGetCities } from 'features/utils/api';
import { FC, useEffect, useRef, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { URL_SITE } from 'shared/constants';
import { useAlertMessage } from 'shared/hooks';
import { REGEX_VERIFY } from 'shared/regex';
import { TSupplier } from 'shared/services/types';
import { Input } from 'shared/ui/form/input';
import { ReactSelect } from 'shared/ui/form/react-select';
import { ReactSelectMulti } from 'shared/ui/form/react-select-multi';
import { PromptText } from 'shared/ui/prompt-text';
import './ProfilePartnersSurvey.scss';
interface IProps {
	defaultData?: TSupplier.DataDetail;
	methods: UseFormReturn<any, any, undefined>;
}
const ProfilePartnersSurvey: FC<IProps> = ({ defaultData, methods }) => {
	const {
		control,
		formState: { errors }
	} = methods;
	const { addAlertMessage } = useAlertMessage();
	const { userData } = useGetUser();

	const { citiesOptions } = useGetCities(userData?.country?.id, false);
	const { addLogo } = usePartnersActions();

	const { supplierOptions } = useSupplierList({
		page: 1,
		page_size: 100
	});

	const { typesOptions } = useCompanyTypes();

	const { updateSupplier } = useSuppliersActions();

	const [logo, setLogo] = useState<any>({});

	const [isOpen, setIsOpen] = useState(false);

	const inputFileRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (defaultData) {
			setLogo({ binary: defaultData.logo });
			const types = typesOptions.find(v => v.value === defaultData.type);
			methods.reset({ ...methods.getValues(), types });
		} else {
			methods.setValue('types', typesOptions[0]);
		}
	}, [defaultData, typesOptions]);

	const infoSubmit = async () => {
		methods.trigger();

		if (logo?.file) {
			try {
				await addLogo.mutateAsync(logo.file);
			} catch (error: any) {
				addAlertMessage(error, 'error');
			}
		}

		await new Promise(resolve => setTimeout(resolve, 100));

		const error = methods.formState.errors;

		const value = methods.getValues();

		const types = value.types.value;
		const city_id = value.city.value;
		const distributors = value.distributors?.map((v: any) => v.value);

		const dataObject = {
			types,
			email: value.email,
			description: value.description,
			phone: value.phone_number,
			city_id,
			suppliers_ids: distributors
		} as TSupplier.Update;

		const isError = Object.keys(dataObject).find(item => {
			return error[item];
		});

		if (isError) return null;

		try {
			await updateSupplier.mutateAsync(dataObject);
		} catch (error: any) {
			addAlertMessage(error, 'error');
		}
	};

	const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target?.files?.[0];

		if (file) {
			const reader = new FileReader();
			reader.onload = event => {
				//@ts-ignore
				setLogo({ file: file, binary: event?.target.result });
				methods.setValue('logo', file);
			};

			reader.readAsDataURL(file);
		}
	};

	const handleRemoveImage = () => {
		setLogo({});

		if (inputFileRef.current) {
			//@ts-ignore
			inputFileRef!.current!.value = null;
		}
	};

	useEffect(() => {
		const { isPending, isSuccess } = updateSupplier;
		if (!isPending && isSuccess) {
			addAlertMessage('Сохранено', 'success');
		}
	}, [updateSupplier.isPending, updateSupplier.isSuccess]);

	const type = methods.watch('types')?.value;

	return (
		<>
			<div className='partner-form'>
				{!defaultData && (
					<ReactSelect
						control={control}
						name='types'
						label='Тип компании'
						placeholder=''
						error={errors}
						rules={{ required: 'Обязательное поле для заполнения' }}
						required
						options={typesOptions.filter(v => v.value !== 'PARTNER')}
					/>
				)}

				<Input
					control={control}
					name={`name`}
					label='Название'
					placeholder='Название'
					required
					error={errors}
					rules={{
						required: 'Обязательное поле для заполнения'
					}}
					info={`Укажите название поставщика на английском языке для добавления в каталог`}
				/>
				<div className='upload-logo'>
					<div className='upload-logo__input'>
						<h4>Загрузите логотип</h4>
						<p>Минимальный размер изображения: 380х180</p>
						{logo.binary && (
							<div className='upload-logo__preview logo__preview-mob'>
								{<img src={logo.binary} alt='Selected' />}
							</div>
						)}
						<div className='input-file'>
							{logo.file && (
								<p className='file-name'>
									{logo?.file?.name}
									<span className='remove-img' onClick={handleRemoveImage}>
										<svg
											width='10'
											height='10'
											viewBox='0 0 10 10'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												d='M9 1.00003L1.00003 9M1 1L8.99997 8.99997'
												stroke='#333333'
												strokeOpacity='0.4'
												strokeLinecap='round'
											/>
										</svg>
									</span>
								</p>
							)}
							<label>
								<input
									type='file'
									accept='image/*'
									onChange={handleImageChange}
									ref={inputFileRef}
								/>
								Прикрепите файл
								<i>
									<svg
										width='18'
										height='18'
										viewBox='0 0 18 18'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											d='M14.2579 9.82478C16.2474 7.92037 16.2474 4.83272 14.2579 2.92831C12.2683 1.0239 9.04267 1.0239 7.05314 2.92831M5.93813 13.3551L11.8563 7.69016C12.5668 7.01001 12.5668 5.90728 11.8563 5.22713C11.1457 4.54699 9.99372 4.54698 9.28317 5.22713L3.4079 10.851C2.05786 12.1433 2.05786 14.2385 3.4079 15.5308C4.75794 16.8231 6.94678 16.8231 8.29681 15.5308L11.2773 12.6778M2.25 7.52596L4.65157 5.22713'
											stroke='#333333'
											strokeWidth='1.5'
											strokeLinecap='round'
										/>
									</svg>
								</i>
							</label>
						</div>
					</div>

					<div className='upload-logo__preview'>
						{!logo.binary && <p>LOGO</p>}
						{logo.binary && (
							<img
								src={(defaultData?.logo ? URL_SITE : '') + logo.binary}
								alt='Logo'
							/>
						)}
					</div>
				</div>

				<div className='inputs-row'>
					<ReactSelect
						control={control}
						name='city'
						label='Город'
						placeholder=''
						error={errors}
						rules={{ required: 'Обязательное поле для заполнения' }}
						required
						options={citiesOptions}
					/>

					<Input
						control={control}
						name='site_url'
						label='Сайт:'
						required
						placeholder='Сайт:'
						error={errors}
						rules={{
							required: 'Обязательное поле для заполнения',
							pattern: {
								value: REGEX_VERIFY.URL_SITE,
								message: 'Укажите валидный адрес сайта'
							}
						}}
					/>
				</div>

				<div className='inputs-row'>
					<Input
						control={control}
						name='phone_number'
						label='Номер телефона'
						required
						placeholder='Номер телефона'
						error={errors}
						rules={{
							required: 'Обязательное поле для заполнения',
							pattern: {
								message: 'Введите корректный номер телефона',
								value: REGEX_VERIFY.PHONE_REGEX
							}
						}}
					/>
					<Input
						control={control}
						name='email'
						label='Email:'
						placeholder='Email:'
						required
						info='Укажите электронную почту'
						error={errors}
						rules={{
							required: 'Обязательное поле для заполнения',
							pattern: {
								value: REGEX_VERIFY.EMAIL_REGEX,
								message: 'Укажите валидный email'
							}
						}}
					/>
				</div>

				<Input
					control={control}
					className='textarea-block'
					textArea
					name='description'
					label='О компании'
					placeholder='О компании'
					info='Кратко опишите вашу компанию, расскажите чем она занимается, какие товары поставляет'
					error={errors}
				/>

				{type === 'MANUFACTURER' && (
					<div className='select-block' id='distributor-field'>
						<p style={{ position: 'relative' }}>
							Официальные дистрибьюторы
							<div
								style={{ position: 'relative', width: '15px', height: '15px' }}
							>
								<PromptText
									text={`Выберите поставщиков с которыми вы работаете, если таковые
											отсутствуют в списке, отправить запрос на добавление.`}
								/>
							</div>
						</p>
						<ReactSelectMulti
							control={control}
							options={supplierOptions}
							name='distributors'
							rules={{ required: 'Обязательное поле для заполнения' }}
							placeholder=''
							error={errors}
						/>
					</div>
				)}

				{defaultData && (
					<div className='block-save'>
						<button className='btn' type='button' onClick={infoSubmit}>
							Сохранить
						</button>
					</div>
				)}
			</div>
			<AddDistributorPopup isOpen={isOpen} onClose={() => setIsOpen(false)} />
		</>
	);
};

export default ProfilePartnersSurvey;
