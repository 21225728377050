import {
	ChatIcon,
	EmployeesIcon,
	HelpIcon,
	PartnerIcon,
	PasswordIcon,
	RequestSuppliersIcon,
	SourcesStatisticsIcon,
	TariffsIcon,
	UserIcon
} from 'assets/ProfileLogos';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import './navigation/ProfileNav.scss';

interface IProps {
	isShowNav?: boolean;
	onClick: () => void;
}

const ToggleMobNavButton: FC<IProps> = ({ isShowNav, onClick }) => {
	const location = useLocation();

	if (location.pathname === '/account/profile') {
		return (
			<div className='navbar-mob'>
				<div className='navbar-mob__title'>
					<UserIcon />
					<span>Профиль компании</span>
				</div>
				<button
					onClick={onClick}
					type='button'
					className={`toggle-mob-menu ${isShowNav ? 'active' : ''}`}
				>
					<svg
						width='21'
						height='21'
						viewBox='0 0 21 21'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M2.23047 3.9375C2.28642 3.3687 2.41286 2.98583 2.69863 2.70006C3.21119 2.1875 4.03615 2.1875 5.68607 2.1875C7.33598 2.1875 8.16094 2.1875 8.6735 2.70006C9.18607 3.21263 9.18607 4.03758 9.18607 5.6875C9.18607 7.33742 9.18607 8.16237 8.6735 8.67494C8.16094 9.1875 7.33598 9.1875 5.68607 9.1875C4.03615 9.1875 3.21119 9.1875 2.69863 8.67494C2.42793 8.40424 2.30019 8.0464 2.23992 7.52593'
							stroke='white'
							strokeLinecap='round'
						/>
						<path
							d='M18.7681 13.5625C18.7121 12.9937 18.5857 12.6108 18.2999 12.3251C17.7874 11.8125 16.9624 11.8125 15.3125 11.8125C13.6626 11.8125 12.8376 11.8125 12.3251 12.3251C11.8125 12.8376 11.8125 13.6626 11.8125 15.3125C11.8125 16.9624 11.8125 17.7874 12.3251 18.2999C12.8376 18.8125 13.6626 18.8125 15.3125 18.8125C16.9624 18.8125 17.7874 18.8125 18.2999 18.2999C18.5857 18.0142 18.7121 17.6313 18.7681 17.0625'
							stroke='white'
							strokeLinecap='round'
						/>
						<path
							d='M2.1875 15.3125C2.1875 13.6626 2.1875 12.8376 2.70006 12.3251C3.21263 11.8125 4.03758 11.8125 5.6875 11.8125C7.33742 11.8125 8.16237 11.8125 8.67494 12.3251C9.1875 12.8376 9.1875 13.6626 9.1875 15.3125C9.1875 16.9624 9.1875 17.7874 8.67494 18.2999C8.16237 18.8125 7.33742 18.8125 5.6875 18.8125C4.03758 18.8125 3.21263 18.8125 2.70006 18.2999C2.1875 17.7874 2.1875 16.9624 2.1875 15.3125Z'
							stroke='white'
						/>
						<path
							d='M11.8125 5.6875C11.8125 4.03758 11.8125 3.21263 12.3251 2.70006C12.8376 2.1875 13.6626 2.1875 15.3125 2.1875C16.9624 2.1875 17.7874 2.1875 18.2999 2.70006C18.8125 3.21263 18.8125 4.03758 18.8125 5.6875C18.8125 7.33742 18.8125 8.16237 18.2999 8.67494C17.7874 9.1875 16.9624 9.1875 15.3125 9.1875C13.6626 9.1875 12.8376 9.1875 12.3251 8.67494C11.8125 8.16237 11.8125 7.33742 11.8125 5.6875Z'
							stroke='white'
						/>
					</svg>
				</button>
			</div>
		);
	}

	if (location.pathname === '/account/tariffs') {
		return (
			<div className='navbar-mob'>
				<div className='navbar-mob__title'>
					<TariffsIcon />
					<span>Тарифы и оплата</span>
				</div>
				<button
					onClick={onClick}
					type='button'
					className={`toggle-mob-menu ${isShowNav ? 'active' : ''}`}
				>
					<svg
						width='21'
						height='21'
						viewBox='0 0 21 21'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M2.23047 3.9375C2.28642 3.3687 2.41286 2.98583 2.69863 2.70006C3.21119 2.1875 4.03615 2.1875 5.68607 2.1875C7.33598 2.1875 8.16094 2.1875 8.6735 2.70006C9.18607 3.21263 9.18607 4.03758 9.18607 5.6875C9.18607 7.33742 9.18607 8.16237 8.6735 8.67494C8.16094 9.1875 7.33598 9.1875 5.68607 9.1875C4.03615 9.1875 3.21119 9.1875 2.69863 8.67494C2.42793 8.40424 2.30019 8.0464 2.23992 7.52593'
							stroke='white'
							strokeLinecap='round'
						/>
						<path
							d='M18.7681 13.5625C18.7121 12.9937 18.5857 12.6108 18.2999 12.3251C17.7874 11.8125 16.9624 11.8125 15.3125 11.8125C13.6626 11.8125 12.8376 11.8125 12.3251 12.3251C11.8125 12.8376 11.8125 13.6626 11.8125 15.3125C11.8125 16.9624 11.8125 17.7874 12.3251 18.2999C12.8376 18.8125 13.6626 18.8125 15.3125 18.8125C16.9624 18.8125 17.7874 18.8125 18.2999 18.2999C18.5857 18.0142 18.7121 17.6313 18.7681 17.0625'
							stroke='white'
							strokeLinecap='round'
						/>
						<path
							d='M2.1875 15.3125C2.1875 13.6626 2.1875 12.8376 2.70006 12.3251C3.21263 11.8125 4.03758 11.8125 5.6875 11.8125C7.33742 11.8125 8.16237 11.8125 8.67494 12.3251C9.1875 12.8376 9.1875 13.6626 9.1875 15.3125C9.1875 16.9624 9.1875 17.7874 8.67494 18.2999C8.16237 18.8125 7.33742 18.8125 5.6875 18.8125C4.03758 18.8125 3.21263 18.8125 2.70006 18.2999C2.1875 17.7874 2.1875 16.9624 2.1875 15.3125Z'
							stroke='white'
						/>
						<path
							d='M11.8125 5.6875C11.8125 4.03758 11.8125 3.21263 12.3251 2.70006C12.8376 2.1875 13.6626 2.1875 15.3125 2.1875C16.9624 2.1875 17.7874 2.1875 18.2999 2.70006C18.8125 3.21263 18.8125 4.03758 18.8125 5.6875C18.8125 7.33742 18.8125 8.16237 18.2999 8.67494C17.7874 9.1875 16.9624 9.1875 15.3125 9.1875C13.6626 9.1875 12.8376 9.1875 12.3251 8.67494C11.8125 8.16237 11.8125 7.33742 11.8125 5.6875Z'
							stroke='white'
						/>
					</svg>
				</button>
			</div>
		);
	}

	if (location.pathname === '/account/employees') {
		return (
			<div className='navbar-mob'>
				<div className='navbar-mob__title'>
					<EmployeesIcon />
					<span>Сотрудники</span>
				</div>
				<button
					onClick={onClick}
					type='button'
					className={`toggle-mob-menu ${isShowNav ? 'active' : ''}`}
				>
					<svg
						width='21'
						height='21'
						viewBox='0 0 21 21'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M2.23047 3.9375C2.28642 3.3687 2.41286 2.98583 2.69863 2.70006C3.21119 2.1875 4.03615 2.1875 5.68607 2.1875C7.33598 2.1875 8.16094 2.1875 8.6735 2.70006C9.18607 3.21263 9.18607 4.03758 9.18607 5.6875C9.18607 7.33742 9.18607 8.16237 8.6735 8.67494C8.16094 9.1875 7.33598 9.1875 5.68607 9.1875C4.03615 9.1875 3.21119 9.1875 2.69863 8.67494C2.42793 8.40424 2.30019 8.0464 2.23992 7.52593'
							stroke='white'
							strokeLinecap='round'
						/>
						<path
							d='M18.7681 13.5625C18.7121 12.9937 18.5857 12.6108 18.2999 12.3251C17.7874 11.8125 16.9624 11.8125 15.3125 11.8125C13.6626 11.8125 12.8376 11.8125 12.3251 12.3251C11.8125 12.8376 11.8125 13.6626 11.8125 15.3125C11.8125 16.9624 11.8125 17.7874 12.3251 18.2999C12.8376 18.8125 13.6626 18.8125 15.3125 18.8125C16.9624 18.8125 17.7874 18.8125 18.2999 18.2999C18.5857 18.0142 18.7121 17.6313 18.7681 17.0625'
							stroke='white'
							strokeLinecap='round'
						/>
						<path
							d='M2.1875 15.3125C2.1875 13.6626 2.1875 12.8376 2.70006 12.3251C3.21263 11.8125 4.03758 11.8125 5.6875 11.8125C7.33742 11.8125 8.16237 11.8125 8.67494 12.3251C9.1875 12.8376 9.1875 13.6626 9.1875 15.3125C9.1875 16.9624 9.1875 17.7874 8.67494 18.2999C8.16237 18.8125 7.33742 18.8125 5.6875 18.8125C4.03758 18.8125 3.21263 18.8125 2.70006 18.2999C2.1875 17.7874 2.1875 16.9624 2.1875 15.3125Z'
							stroke='white'
						/>
						<path
							d='M11.8125 5.6875C11.8125 4.03758 11.8125 3.21263 12.3251 2.70006C12.8376 2.1875 13.6626 2.1875 15.3125 2.1875C16.9624 2.1875 17.7874 2.1875 18.2999 2.70006C18.8125 3.21263 18.8125 4.03758 18.8125 5.6875C18.8125 7.33742 18.8125 8.16237 18.2999 8.67494C17.7874 9.1875 16.9624 9.1875 15.3125 9.1875C13.6626 9.1875 12.8376 9.1875 12.3251 8.67494C11.8125 8.16237 11.8125 7.33742 11.8125 5.6875Z'
							stroke='white'
						/>
					</svg>
				</button>
			</div>
		);
	}

	if (location.pathname === '/account/password-change') {
		return (
			<div className='navbar-mob'>
				<div className='navbar-mob__title'>
					<PasswordIcon />
					<span>Смена пароля</span>
				</div>
				<button
					onClick={onClick}
					type='button'
					className={`toggle-mob-menu ${isShowNav ? 'active' : ''}`}
				>
					<svg
						width='21'
						height='21'
						viewBox='0 0 21 21'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M2.23047 3.9375C2.28642 3.3687 2.41286 2.98583 2.69863 2.70006C3.21119 2.1875 4.03615 2.1875 5.68607 2.1875C7.33598 2.1875 8.16094 2.1875 8.6735 2.70006C9.18607 3.21263 9.18607 4.03758 9.18607 5.6875C9.18607 7.33742 9.18607 8.16237 8.6735 8.67494C8.16094 9.1875 7.33598 9.1875 5.68607 9.1875C4.03615 9.1875 3.21119 9.1875 2.69863 8.67494C2.42793 8.40424 2.30019 8.0464 2.23992 7.52593'
							stroke='white'
							strokeLinecap='round'
						/>
						<path
							d='M18.7681 13.5625C18.7121 12.9937 18.5857 12.6108 18.2999 12.3251C17.7874 11.8125 16.9624 11.8125 15.3125 11.8125C13.6626 11.8125 12.8376 11.8125 12.3251 12.3251C11.8125 12.8376 11.8125 13.6626 11.8125 15.3125C11.8125 16.9624 11.8125 17.7874 12.3251 18.2999C12.8376 18.8125 13.6626 18.8125 15.3125 18.8125C16.9624 18.8125 17.7874 18.8125 18.2999 18.2999C18.5857 18.0142 18.7121 17.6313 18.7681 17.0625'
							stroke='white'
							strokeLinecap='round'
						/>
						<path
							d='M2.1875 15.3125C2.1875 13.6626 2.1875 12.8376 2.70006 12.3251C3.21263 11.8125 4.03758 11.8125 5.6875 11.8125C7.33742 11.8125 8.16237 11.8125 8.67494 12.3251C9.1875 12.8376 9.1875 13.6626 9.1875 15.3125C9.1875 16.9624 9.1875 17.7874 8.67494 18.2999C8.16237 18.8125 7.33742 18.8125 5.6875 18.8125C4.03758 18.8125 3.21263 18.8125 2.70006 18.2999C2.1875 17.7874 2.1875 16.9624 2.1875 15.3125Z'
							stroke='white'
						/>
						<path
							d='M11.8125 5.6875C11.8125 4.03758 11.8125 3.21263 12.3251 2.70006C12.8376 2.1875 13.6626 2.1875 15.3125 2.1875C16.9624 2.1875 17.7874 2.1875 18.2999 2.70006C18.8125 3.21263 18.8125 4.03758 18.8125 5.6875C18.8125 7.33742 18.8125 8.16237 18.2999 8.67494C17.7874 9.1875 16.9624 9.1875 15.3125 9.1875C13.6626 9.1875 12.8376 9.1875 12.3251 8.67494C11.8125 8.16237 11.8125 7.33742 11.8125 5.6875Z'
							stroke='white'
						/>
					</svg>
				</button>
			</div>
		);
	}

	if (location.pathname === '/account/help') {
		return (
			<div className='navbar-mob'>
				<div className='navbar-mob__title'>
					<HelpIcon />
					<span>Помощь</span>
				</div>
				<button
					onClick={onClick}
					type='button'
					className={`toggle-mob-menu ${isShowNav ? 'active' : ''}`}
				>
					<svg
						width='21'
						height='21'
						viewBox='0 0 21 21'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M2.23047 3.9375C2.28642 3.3687 2.41286 2.98583 2.69863 2.70006C3.21119 2.1875 4.03615 2.1875 5.68607 2.1875C7.33598 2.1875 8.16094 2.1875 8.6735 2.70006C9.18607 3.21263 9.18607 4.03758 9.18607 5.6875C9.18607 7.33742 9.18607 8.16237 8.6735 8.67494C8.16094 9.1875 7.33598 9.1875 5.68607 9.1875C4.03615 9.1875 3.21119 9.1875 2.69863 8.67494C2.42793 8.40424 2.30019 8.0464 2.23992 7.52593'
							stroke='white'
							strokeLinecap='round'
						/>
						<path
							d='M18.7681 13.5625C18.7121 12.9937 18.5857 12.6108 18.2999 12.3251C17.7874 11.8125 16.9624 11.8125 15.3125 11.8125C13.6626 11.8125 12.8376 11.8125 12.3251 12.3251C11.8125 12.8376 11.8125 13.6626 11.8125 15.3125C11.8125 16.9624 11.8125 17.7874 12.3251 18.2999C12.8376 18.8125 13.6626 18.8125 15.3125 18.8125C16.9624 18.8125 17.7874 18.8125 18.2999 18.2999C18.5857 18.0142 18.7121 17.6313 18.7681 17.0625'
							stroke='white'
							strokeLinecap='round'
						/>
						<path
							d='M2.1875 15.3125C2.1875 13.6626 2.1875 12.8376 2.70006 12.3251C3.21263 11.8125 4.03758 11.8125 5.6875 11.8125C7.33742 11.8125 8.16237 11.8125 8.67494 12.3251C9.1875 12.8376 9.1875 13.6626 9.1875 15.3125C9.1875 16.9624 9.1875 17.7874 8.67494 18.2999C8.16237 18.8125 7.33742 18.8125 5.6875 18.8125C4.03758 18.8125 3.21263 18.8125 2.70006 18.2999C2.1875 17.7874 2.1875 16.9624 2.1875 15.3125Z'
							stroke='white'
						/>
						<path
							d='M11.8125 5.6875C11.8125 4.03758 11.8125 3.21263 12.3251 2.70006C12.8376 2.1875 13.6626 2.1875 15.3125 2.1875C16.9624 2.1875 17.7874 2.1875 18.2999 2.70006C18.8125 3.21263 18.8125 4.03758 18.8125 5.6875C18.8125 7.33742 18.8125 8.16237 18.2999 8.67494C17.7874 9.1875 16.9624 9.1875 15.3125 9.1875C13.6626 9.1875 12.8376 9.1875 12.3251 8.67494C11.8125 8.16237 11.8125 7.33742 11.8125 5.6875Z'
							stroke='white'
						/>
					</svg>
				</button>
			</div>
		);
	}

	if (location.pathname === '/account/chat') {
		return (
			<div className='navbar-mob'>
				<div className='navbar-mob__title'>
					<ChatIcon />
					<span>Чат поддержки</span>
				</div>
				<button
					onClick={onClick}
					type='button'
					className={`toggle-mob-menu ${isShowNav ? 'active' : ''}`}
				>
					<svg
						width='21'
						height='21'
						viewBox='0 0 21 21'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M2.23047 3.9375C2.28642 3.3687 2.41286 2.98583 2.69863 2.70006C3.21119 2.1875 4.03615 2.1875 5.68607 2.1875C7.33598 2.1875 8.16094 2.1875 8.6735 2.70006C9.18607 3.21263 9.18607 4.03758 9.18607 5.6875C9.18607 7.33742 9.18607 8.16237 8.6735 8.67494C8.16094 9.1875 7.33598 9.1875 5.68607 9.1875C4.03615 9.1875 3.21119 9.1875 2.69863 8.67494C2.42793 8.40424 2.30019 8.0464 2.23992 7.52593'
							stroke='white'
							strokeLinecap='round'
						/>
						<path
							d='M18.7681 13.5625C18.7121 12.9937 18.5857 12.6108 18.2999 12.3251C17.7874 11.8125 16.9624 11.8125 15.3125 11.8125C13.6626 11.8125 12.8376 11.8125 12.3251 12.3251C11.8125 12.8376 11.8125 13.6626 11.8125 15.3125C11.8125 16.9624 11.8125 17.7874 12.3251 18.2999C12.8376 18.8125 13.6626 18.8125 15.3125 18.8125C16.9624 18.8125 17.7874 18.8125 18.2999 18.2999C18.5857 18.0142 18.7121 17.6313 18.7681 17.0625'
							stroke='white'
							strokeLinecap='round'
						/>
						<path
							d='M2.1875 15.3125C2.1875 13.6626 2.1875 12.8376 2.70006 12.3251C3.21263 11.8125 4.03758 11.8125 5.6875 11.8125C7.33742 11.8125 8.16237 11.8125 8.67494 12.3251C9.1875 12.8376 9.1875 13.6626 9.1875 15.3125C9.1875 16.9624 9.1875 17.7874 8.67494 18.2999C8.16237 18.8125 7.33742 18.8125 5.6875 18.8125C4.03758 18.8125 3.21263 18.8125 2.70006 18.2999C2.1875 17.7874 2.1875 16.9624 2.1875 15.3125Z'
							stroke='white'
						/>
						<path
							d='M11.8125 5.6875C11.8125 4.03758 11.8125 3.21263 12.3251 2.70006C12.8376 2.1875 13.6626 2.1875 15.3125 2.1875C16.9624 2.1875 17.7874 2.1875 18.2999 2.70006C18.8125 3.21263 18.8125 4.03758 18.8125 5.6875C18.8125 7.33742 18.8125 8.16237 18.2999 8.67494C17.7874 9.1875 16.9624 9.1875 15.3125 9.1875C13.6626 9.1875 12.8376 9.1875 12.3251 8.67494C11.8125 8.16237 11.8125 7.33742 11.8125 5.6875Z'
							stroke='white'
						/>
					</svg>
				</button>
			</div>
		);
	}

	if (location.pathname === '/account/partner') {
		return (
			<div className='navbar-mob'>
				<div className='navbar-mob__title'>
					<PartnerIcon />
					<span>Стать партнером</span>
				</div>
				<button
					onClick={onClick}
					type='button'
					className={`toggle-mob-menu ${isShowNav ? 'active' : ''}`}
				>
					<svg
						width='21'
						height='21'
						viewBox='0 0 21 21'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M2.23047 3.9375C2.28642 3.3687 2.41286 2.98583 2.69863 2.70006C3.21119 2.1875 4.03615 2.1875 5.68607 2.1875C7.33598 2.1875 8.16094 2.1875 8.6735 2.70006C9.18607 3.21263 9.18607 4.03758 9.18607 5.6875C9.18607 7.33742 9.18607 8.16237 8.6735 8.67494C8.16094 9.1875 7.33598 9.1875 5.68607 9.1875C4.03615 9.1875 3.21119 9.1875 2.69863 8.67494C2.42793 8.40424 2.30019 8.0464 2.23992 7.52593'
							stroke='white'
							strokeLinecap='round'
						/>
						<path
							d='M18.7681 13.5625C18.7121 12.9937 18.5857 12.6108 18.2999 12.3251C17.7874 11.8125 16.9624 11.8125 15.3125 11.8125C13.6626 11.8125 12.8376 11.8125 12.3251 12.3251C11.8125 12.8376 11.8125 13.6626 11.8125 15.3125C11.8125 16.9624 11.8125 17.7874 12.3251 18.2999C12.8376 18.8125 13.6626 18.8125 15.3125 18.8125C16.9624 18.8125 17.7874 18.8125 18.2999 18.2999C18.5857 18.0142 18.7121 17.6313 18.7681 17.0625'
							stroke='white'
							strokeLinecap='round'
						/>
						<path
							d='M2.1875 15.3125C2.1875 13.6626 2.1875 12.8376 2.70006 12.3251C3.21263 11.8125 4.03758 11.8125 5.6875 11.8125C7.33742 11.8125 8.16237 11.8125 8.67494 12.3251C9.1875 12.8376 9.1875 13.6626 9.1875 15.3125C9.1875 16.9624 9.1875 17.7874 8.67494 18.2999C8.16237 18.8125 7.33742 18.8125 5.6875 18.8125C4.03758 18.8125 3.21263 18.8125 2.70006 18.2999C2.1875 17.7874 2.1875 16.9624 2.1875 15.3125Z'
							stroke='white'
						/>
						<path
							d='M11.8125 5.6875C11.8125 4.03758 11.8125 3.21263 12.3251 2.70006C12.8376 2.1875 13.6626 2.1875 15.3125 2.1875C16.9624 2.1875 17.7874 2.1875 18.2999 2.70006C18.8125 3.21263 18.8125 4.03758 18.8125 5.6875C18.8125 7.33742 18.8125 8.16237 18.2999 8.67494C17.7874 9.1875 16.9624 9.1875 15.3125 9.1875C13.6626 9.1875 12.8376 9.1875 12.3251 8.67494C11.8125 8.16237 11.8125 7.33742 11.8125 5.6875Z'
							stroke='white'
						/>
					</svg>
				</button>
			</div>
		);
	}
	if (location.pathname === '/account/supplier') {
		return (
			<div className='navbar-mob'>
				<div className='navbar-mob__title'>
					<PartnerIcon />
					<span>Стать поставщиком</span>
				</div>
				<button
					onClick={onClick}
					type='button'
					className={`toggle-mob-menu ${isShowNav ? 'active' : ''}`}
				>
					<svg
						width='21'
						height='21'
						viewBox='0 0 21 21'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M2.23047 3.9375C2.28642 3.3687 2.41286 2.98583 2.69863 2.70006C3.21119 2.1875 4.03615 2.1875 5.68607 2.1875C7.33598 2.1875 8.16094 2.1875 8.6735 2.70006C9.18607 3.21263 9.18607 4.03758 9.18607 5.6875C9.18607 7.33742 9.18607 8.16237 8.6735 8.67494C8.16094 9.1875 7.33598 9.1875 5.68607 9.1875C4.03615 9.1875 3.21119 9.1875 2.69863 8.67494C2.42793 8.40424 2.30019 8.0464 2.23992 7.52593'
							stroke='white'
							strokeLinecap='round'
						/>
						<path
							d='M18.7681 13.5625C18.7121 12.9937 18.5857 12.6108 18.2999 12.3251C17.7874 11.8125 16.9624 11.8125 15.3125 11.8125C13.6626 11.8125 12.8376 11.8125 12.3251 12.3251C11.8125 12.8376 11.8125 13.6626 11.8125 15.3125C11.8125 16.9624 11.8125 17.7874 12.3251 18.2999C12.8376 18.8125 13.6626 18.8125 15.3125 18.8125C16.9624 18.8125 17.7874 18.8125 18.2999 18.2999C18.5857 18.0142 18.7121 17.6313 18.7681 17.0625'
							stroke='white'
							strokeLinecap='round'
						/>
						<path
							d='M2.1875 15.3125C2.1875 13.6626 2.1875 12.8376 2.70006 12.3251C3.21263 11.8125 4.03758 11.8125 5.6875 11.8125C7.33742 11.8125 8.16237 11.8125 8.67494 12.3251C9.1875 12.8376 9.1875 13.6626 9.1875 15.3125C9.1875 16.9624 9.1875 17.7874 8.67494 18.2999C8.16237 18.8125 7.33742 18.8125 5.6875 18.8125C4.03758 18.8125 3.21263 18.8125 2.70006 18.2999C2.1875 17.7874 2.1875 16.9624 2.1875 15.3125Z'
							stroke='white'
						/>
						<path
							d='M11.8125 5.6875C11.8125 4.03758 11.8125 3.21263 12.3251 2.70006C12.8376 2.1875 13.6626 2.1875 15.3125 2.1875C16.9624 2.1875 17.7874 2.1875 18.2999 2.70006C18.8125 3.21263 18.8125 4.03758 18.8125 5.6875C18.8125 7.33742 18.8125 8.16237 18.2999 8.67494C17.7874 9.1875 16.9624 9.1875 15.3125 9.1875C13.6626 9.1875 12.8376 9.1875 12.3251 8.67494C11.8125 8.16237 11.8125 7.33742 11.8125 5.6875Z'
							stroke='white'
						/>
					</svg>
				</button>
			</div>
		);
	}

	if (location.pathname === '/account/sources-statistics') {
		return (
			<div className='navbar-mob'>
				<div className='navbar-mob__title'>
					<SourcesStatisticsIcon />
					<span>Источники и статистика</span>
				</div>
				<button
					onClick={onClick}
					type='button'
					className={`toggle-mob-menu ${isShowNav ? 'active' : ''}`}
				>
					<svg
						width='21'
						height='21'
						viewBox='0 0 21 21'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M2.23047 3.9375C2.28642 3.3687 2.41286 2.98583 2.69863 2.70006C3.21119 2.1875 4.03615 2.1875 5.68607 2.1875C7.33598 2.1875 8.16094 2.1875 8.6735 2.70006C9.18607 3.21263 9.18607 4.03758 9.18607 5.6875C9.18607 7.33742 9.18607 8.16237 8.6735 8.67494C8.16094 9.1875 7.33598 9.1875 5.68607 9.1875C4.03615 9.1875 3.21119 9.1875 2.69863 8.67494C2.42793 8.40424 2.30019 8.0464 2.23992 7.52593'
							stroke='white'
							strokeLinecap='round'
						/>
						<path
							d='M18.7681 13.5625C18.7121 12.9937 18.5857 12.6108 18.2999 12.3251C17.7874 11.8125 16.9624 11.8125 15.3125 11.8125C13.6626 11.8125 12.8376 11.8125 12.3251 12.3251C11.8125 12.8376 11.8125 13.6626 11.8125 15.3125C11.8125 16.9624 11.8125 17.7874 12.3251 18.2999C12.8376 18.8125 13.6626 18.8125 15.3125 18.8125C16.9624 18.8125 17.7874 18.8125 18.2999 18.2999C18.5857 18.0142 18.7121 17.6313 18.7681 17.0625'
							stroke='white'
							strokeLinecap='round'
						/>
						<path
							d='M2.1875 15.3125C2.1875 13.6626 2.1875 12.8376 2.70006 12.3251C3.21263 11.8125 4.03758 11.8125 5.6875 11.8125C7.33742 11.8125 8.16237 11.8125 8.67494 12.3251C9.1875 12.8376 9.1875 13.6626 9.1875 15.3125C9.1875 16.9624 9.1875 17.7874 8.67494 18.2999C8.16237 18.8125 7.33742 18.8125 5.6875 18.8125C4.03758 18.8125 3.21263 18.8125 2.70006 18.2999C2.1875 17.7874 2.1875 16.9624 2.1875 15.3125Z'
							stroke='white'
						/>
						<path
							d='M11.8125 5.6875C11.8125 4.03758 11.8125 3.21263 12.3251 2.70006C12.8376 2.1875 13.6626 2.1875 15.3125 2.1875C16.9624 2.1875 17.7874 2.1875 18.2999 2.70006C18.8125 3.21263 18.8125 4.03758 18.8125 5.6875C18.8125 7.33742 18.8125 8.16237 18.2999 8.67494C17.7874 9.1875 16.9624 9.1875 15.3125 9.1875C13.6626 9.1875 12.8376 9.1875 12.3251 8.67494C11.8125 8.16237 11.8125 7.33742 11.8125 5.6875Z'
							stroke='white'
						/>
					</svg>
				</button>
			</div>
		);
	}

	if (location.pathname === '/account/request-suppliers') {
		return (
			<div className='navbar-mob'>
				<div className='navbar-mob__title'>
					<RequestSuppliersIcon />
					<span>Запросы партнерам</span>
				</div>
				<button
					onClick={onClick}
					type='button'
					className={`toggle-mob-menu ${isShowNav ? 'active' : ''}`}
				>
					<svg
						width='21'
						height='21'
						viewBox='0 0 21 21'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M2.23047 3.9375C2.28642 3.3687 2.41286 2.98583 2.69863 2.70006C3.21119 2.1875 4.03615 2.1875 5.68607 2.1875C7.33598 2.1875 8.16094 2.1875 8.6735 2.70006C9.18607 3.21263 9.18607 4.03758 9.18607 5.6875C9.18607 7.33742 9.18607 8.16237 8.6735 8.67494C8.16094 9.1875 7.33598 9.1875 5.68607 9.1875C4.03615 9.1875 3.21119 9.1875 2.69863 8.67494C2.42793 8.40424 2.30019 8.0464 2.23992 7.52593'
							stroke='white'
							strokeLinecap='round'
						/>
						<path
							d='M18.7681 13.5625C18.7121 12.9937 18.5857 12.6108 18.2999 12.3251C17.7874 11.8125 16.9624 11.8125 15.3125 11.8125C13.6626 11.8125 12.8376 11.8125 12.3251 12.3251C11.8125 12.8376 11.8125 13.6626 11.8125 15.3125C11.8125 16.9624 11.8125 17.7874 12.3251 18.2999C12.8376 18.8125 13.6626 18.8125 15.3125 18.8125C16.9624 18.8125 17.7874 18.8125 18.2999 18.2999C18.5857 18.0142 18.7121 17.6313 18.7681 17.0625'
							stroke='white'
							strokeLinecap='round'
						/>
						<path
							d='M2.1875 15.3125C2.1875 13.6626 2.1875 12.8376 2.70006 12.3251C3.21263 11.8125 4.03758 11.8125 5.6875 11.8125C7.33742 11.8125 8.16237 11.8125 8.67494 12.3251C9.1875 12.8376 9.1875 13.6626 9.1875 15.3125C9.1875 16.9624 9.1875 17.7874 8.67494 18.2999C8.16237 18.8125 7.33742 18.8125 5.6875 18.8125C4.03758 18.8125 3.21263 18.8125 2.70006 18.2999C2.1875 17.7874 2.1875 16.9624 2.1875 15.3125Z'
							stroke='white'
						/>
						<path
							d='M11.8125 5.6875C11.8125 4.03758 11.8125 3.21263 12.3251 2.70006C12.8376 2.1875 13.6626 2.1875 15.3125 2.1875C16.9624 2.1875 17.7874 2.1875 18.2999 2.70006C18.8125 3.21263 18.8125 4.03758 18.8125 5.6875C18.8125 7.33742 18.8125 8.16237 18.2999 8.67494C17.7874 9.1875 16.9624 9.1875 15.3125 9.1875C13.6626 9.1875 12.8376 9.1875 12.3251 8.67494C11.8125 8.16237 11.8125 7.33742 11.8125 5.6875Z'
							stroke='white'
						/>
					</svg>
				</button>
			</div>
		);
	}
};

export default ToggleMobNavButton;
