export function calculateOriginalPrice(
	discountedPrice: number,
	discountPercentage: number
): number {
	const discountFactor = discountPercentage / 100;

	const originalPrice = discountedPrice / (1 - discountFactor);

	return originalPrice;
}
