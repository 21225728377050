import clsx from 'clsx';
import { FC } from 'react';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import { IPropsSelectMulti } from '../types';

export const ReactSelectMulti: FC<IPropsSelectMulti> = ({
	control,
	name,
	rules,
	isSearch,
	defaultValue,
	error,
	label,
	required,
	options,
	placeholder,
	className,
	classNamePrefix,
	disabled,
	messageNoOption
}) => {
	const isActive = true;
	const messageOptions = () => messageNoOption;

	const errorMessage =
		typeof error === 'string' ? error : error?.[name]?.message;

	return (
		<Controller
			name={name}
			control={control}
			rules={rules}
			render={({ field: { value, onChange } }) => {
				return (
					<div
						className={clsx(
							'select-block',
							isActive && 'active',
							errorMessage && 'error',
							className
						)}
					>
						<div className='input-placeholder'>
							{label}
							{required && <span>*</span>}
						</div>

						<Select
							//@ts-ignore
							options={options}
							isSearchable={isSearch}
							isMulti
							classNamePrefix={clsx(
								'multi-select-stroke',
								'multi-select-search',
								classNamePrefix
							)}
							isDisabled={disabled}
							//@ts-ignore
							getOptionValue={option => option.value}
							//@ts-ignore
							getOptionLabel={option => option.label}
							required
							value={value}
							noOptionsMessage={messageOptions}
							placeholder={placeholder}
							onChange={onChange}
							defaultValue={defaultValue}
							components={{
								Menu: MenuWithSearch,
								DropdownIndicator: CustomIndicator,
								Option: allOption
							}}
						/>

						<div className='input-error'>
							{/* @ts-ignore */}
							{errorMessage && <span>{errorMessage}</span>}
						</div>
					</div>
				);
			}}
		/>
	);
};
const MenuWithSearch = (props: any) => {
	const { innerProps } = props;
	const { inputValue } = props.selectProps;

	return (
		<div className='multi-select-search__menu' {...innerProps}>
			<div
				className='multi-select-search__search'
				onTouchEnd={e => {
					e.stopPropagation();
					e.preventDefault();
				}}
			>
				<label>
					<input
						type='text'
						placeholder='Поиск'
						value={inputValue}
						onChange={e => {
							props.selectProps.onInputChange(e.target.value);
						}}
					/>
					<i>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='27'
							height='27'
							viewBox='0 0 27 27'
							fill='none'
						>
							<path
								d='M23.625 23.625L16.875 16.875M3.375 11.25C3.375 12.2842 3.57869 13.3082 3.97445 14.2636C4.3702 15.2191 4.95027 16.0872 5.68153 16.8185C6.4128 17.5497 7.28093 18.1298 8.23637 18.5256C9.19181 18.9213 10.2158 19.125 11.25 19.125C12.2842 19.125 13.3082 18.9213 14.2636 18.5256C15.2191 18.1298 16.0872 17.5497 16.8185 16.8185C17.5497 16.0872 18.1298 15.2191 18.5256 14.2636C18.9213 13.3082 19.125 12.2842 19.125 11.25C19.125 10.2158 18.9213 9.19181 18.5256 8.23637C18.1298 7.28093 17.5497 6.4128 16.8185 5.68153C16.0872 4.95027 15.2191 4.3702 14.2636 3.97445C13.3082 3.57869 12.2842 3.375 11.25 3.375C10.2158 3.375 9.19181 3.57869 8.23637 3.97445C7.28093 4.3702 6.4128 4.95027 5.68153 5.68153C4.95027 6.4128 4.3702 7.28093 3.97445 8.23637C3.57869 9.19181 3.375 10.2158 3.375 11.25Z'
								stroke='#6A6A6B'
								strokeWidth='1.5'
								strokeLinecap='round'
								strokeLinejoin='round'
							/>
						</svg>
					</i>
				</label>
			</div>
			{props.children}
		</div>
	);
};

const CustomIndicator = (props: any) => {
	return (
		<div className='multi-select-search__add-btn' {...props.innerProps}>
			<svg
				width='14'
				height='14'
				viewBox='0 0 14 14'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M1 7H7M7 7H13M7 7V13M7 7V1'
					stroke='#8A7DE7'
					strokeWidth='1.5'
					strokeLinecap='round'
				/>
			</svg>
		</div>
	);
};

const allOption = ({ innerProps, label, data }: any) => (
	<div className='custom-option' {...innerProps}>
		<span>{label}</span>
	</div>
);
