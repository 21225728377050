import { useQuery, useQueryClient } from '@tanstack/react-query';
import SupplierService from 'shared/services/supplier.service';

export const useSupplierDetail = (slug?: string) => {
	const queryClient = useQueryClient();
	return useQuery({
		queryFn: () => SupplierService.getSupplierBySlug(slug),
		queryKey: ['get supplier', slug],
		select: ({ data }) => data,
		retry: 0
	});
};

export const useMySupplierDetail = () => {
	const queryClient = useQueryClient();
	return useQuery({
		queryFn: () => SupplierService.getMySupplier(),
		queryKey: ['get my supplier'],
		select: ({ data }) => data,
		retry: 0
	});
};
