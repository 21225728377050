import { useHelpAction } from 'features/help/api';
import { FC, useEffect } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import Popup from 'reactjs-popup';
import { useAlertMessage } from 'shared/hooks';
import { THelper } from 'shared/services/types';
import { FormWrapper } from 'shared/ui/form/form-wrapper';
import { Input } from 'shared/ui/form/input';
import './AddDistributorPopup.scss';

interface IProps {
	isOpen: boolean;
	onClose: () => void;
	isVendor?: boolean;
}

export const AddDistributorPopup: FC<IProps> = ({
	isOpen,
	onClose,
	isVendor
}) => {
	const methodsModal = useForm();
	const { addNewDistributer } = useHelpAction();
	const { addAlertMessage } = useAlertMessage();

	const handlePopupClose = () => {
		onClose();
	};

	useEffect(() => {
		if (!isOpen) {
			methodsModal.reset();
		}
	}, [isOpen]);

	const sendAddDistributor = async (dataForm: FieldValues) => {
		const distributorData = dataForm as THelper.IDistributorRequest;

		try {
			await addNewDistributer.mutateAsync(distributorData);
			addAlertMessage('Запрос успешно отправлен', 'success');
			onClose();
		} catch (error: any) {
			addAlertMessage(error, 'error');
		}
	};

	return (
		<>
			<Popup
				className='add-distributor-modal'
				open={isOpen}
				closeOnDocumentClick
				onClose={onClose}
			>
				<div className='modal__header'>
					<p>
						{isVendor
							? 'Запрос на добавление вендора'
							: 'Запрос на добавление дистрибьютора'}
					</p>
					<button
						type='button'
						className='modal__close'
						onClick={handlePopupClose}
					>
						<svg
							width='32'
							height='32'
							viewBox='0 0 32 32'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M19.75 12.25L12.25 19.75M12.25 12.25L19.7499 19.75'
								stroke='#8A7DE7'
								strokeWidth='1.5'
								strokeLinecap='round'
							/>
							<path
								d='M31 16C31 23.0711 31 26.6066 28.8033 28.8033C26.6066 31 23.0711 31 16 31C8.92893 31 5.3934 31 3.1967 28.8033C1 26.6066 1 23.0711 1 16C1 8.92893 1 5.3934 3.1967 3.1967C5.3934 1 8.92893 1 16 1C23.0711 1 26.6066 1 28.8033 3.1967C30.2639 4.65731 30.7533 6.70983 30.9173 10'
								stroke='#8A7DE7'
								strokeWidth='1.5'
								strokeLinecap='round'
							/>
						</svg>
					</button>
				</div>

				<div className='modal__form'>
					<FormWrapper methods={methodsModal} onSubmit={sendAddDistributor}>
						<Input
							control={methodsModal.control}
							name='name'
							label='Название юридического лица'
							placeholder='Название юридического лица'
							required
							error={methodsModal.formState.errors}
							rules={{ required: 'Обязательное поле для заполнения' }}
						/>

						<Input
							control={methodsModal.control}
							name='site_url'
							label='Сайт'
							placeholder='Сайт'
							required
							error={methodsModal.formState.errors}
							rules={{ required: 'Обязательное поле для заполнения' }}
						/>

						<Input
							control={methodsModal.control}
							name='message'
							label='Сообщение'
							placeholder='Сообщение'
							required
							textArea
							error={methodsModal.formState.errors}
							rules={{ required: 'Обязательное поле для заполнения' }}
						/>
						<button
							disabled={addNewDistributer.isPending}
							type='submit'
							className='btn'
						>
							Отправить
						</button>
					</FormWrapper>
				</div>
			</Popup>
		</>
	);
};
