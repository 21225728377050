import clsx from 'clsx';
import { useGetUser } from 'features/profile/api/useGetUser';
import { useUpdateUser } from 'features/profile/api/useUpdateUser';
import { useGetCountries, useGetCurrencies } from 'features/utils/api';
import Cookies from 'js-cookie';
import { FC, useContext, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import CommonContext from 'services/AuthContext';
import { URL_SITE } from 'shared/constants';
import { TCommon } from 'shared/services/types';

import { FormWrapper } from 'shared/ui/form/form-wrapper';
import { ReactSelect } from 'shared/ui/form/react-select';
import { tokenParse } from 'shared/utils';

interface IProps {
	isOpen: boolean;
	onClose: () => void;
}

export const LanguageSelect: FC<IProps> = ({ isOpen, onClose }) => {
	const KIRA_CURRENCY = JSON.parse(Cookies.get('kira_currency') || '{}');
	const KIRA_COUNTRY = JSON.parse(Cookies.get('kira-country') || '{}');
	const info = tokenParse();
	const methods = useForm();
	const { updateUserAsync } = useUpdateUser(info.user_id);
	const { userData } = useGetUser();

	const formCountry = methods.watch('country');
	const formCurrency = methods.watch('currency');

	const { data: countries } = useGetCountries();
	const { data: currencies } = useGetCurrencies();

	const {
		onChangeCurrencyApp,
		authState,
		setCurrentCurrency: setCurrency
	} = useContext(CommonContext);

	const currencyList = useMemo(() => {
		const selectOptions = currencies?.map(country => {
			const flag = countries?.find(v => v.id === country.country_id)?.flag;

			return {
				value: country.id,
				flag: `${URL_SITE}${flag}`,
				label: `${country.id}`
			};
		});

		return selectOptions || [];
	}, [currencies, countries]);

	const countryList = useMemo(() => {
		const selectOptions =
			countries && currencies
				? countries
						// ?.filter(item => ![2, 4].includes(item.id))
						?.map(country => {
							return {
								value: country.id,
								flag: `${URL_SITE}${country.flag}`,
								label: `${country.name}`
							};
						})
				: [];

		return selectOptions;
	}, [currencies, countries]);

	const onChangeCurrency = async (data: TCommon.TOption) => {
		try {
			if (userData?.currency) {
				await updateUserAsync({
					currency_id: data.label
				});
			}
		} catch (error) {
			console.log(error);
		}
		Cookies.set('kira_currency', JSON.stringify(data));
		window.dispatchEvent(new Event('cookiechange'));
		onChangeCurrencyApp(data);
	};

	const onChangeCountry = async (data: TCommon.TOption) => {
		try {
			if (userData?.country) {
				await updateUserAsync({ country_id: data.value.toString() });
			}

			setCurrency(data);
		} catch (error) {
			console.log(error);
		}
		window.dispatchEvent(new Event('cookiechange'));

		Cookies.set('kira-country', JSON.stringify(data.value));
	};

	useEffect(() => {
		if (Object.keys(KIRA_CURRENCY).length) {
			methods.setValue('currency', KIRA_CURRENCY);
		}
	}, [!KIRA_CURRENCY]);

	useEffect(() => {
		if (!!KIRA_COUNTRY && countryList.length) {
			methods.setValue(
				'country',
				countryList.find(v => v.value === KIRA_COUNTRY)
			);
		}
	}, [!KIRA_COUNTRY, countryList]);

	return (
		<div className={clsx('settings-list', isOpen && 'active')}>
			{authState ? (
				<>
					<FormWrapper methods={methods} onSubmit={e => {}}>
						<ReactSelect
							control={methods.control}
							name='country'
							label='Выберите страну'
							options={countryList}
							onSelectChange={onChangeCountry}
							disabled={!countryList}
						/>
						<ReactSelect
							control={methods.control}
							name='currency'
							label='Выберите валюту'
							onSelectChange={onChangeCurrency}
							options={currencyList}
							disabled={!currencyList}
							withoutMargin
						/>
					</FormWrapper>
				</>
			) : (
				<>
					<p>
						Необходимо
						<br />
						<Link to={'/login'}> войти или зарегистрироваться</Link>
					</p>
				</>
			)}
			<button onClick={onClose} className='close'>
				<svg
					width='32'
					height='32'
					viewBox='0 0 32 32'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M19.75 12.25L12.25 19.75M12.25 12.25L19.7499 19.75'
						stroke='#8A7DE7'
						strokeWidth='1.5'
						strokeLinecap='round'
					/>
					<path
						d='M31 16C31 23.0711 31 26.6066 28.8033 28.8033C26.6066 31 23.0711 31 16 31C8.92893 31 5.3934 31 3.1967 28.8033C1 26.6066 1 23.0711 1 16C1 8.92893 1 5.3934 3.1967 3.1967C5.3934 1 8.92893 1 16 1C23.0711 1 26.6066 1 28.8033 3.1967C30.2639 4.65731 30.7533 6.70983 30.9173 10'
						stroke='#8A7DE7'
						stroke-width='1.5'
						stroke-linecap='round'
					/>
				</svg>
			</button>
		</div>
	);
};
