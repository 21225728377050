import { useMemo, useState } from 'react';
import './SupplierPage.scss';

import { PartnerInfo } from 'entities/partners/partner-info';
import TechWarning from 'entities/statistics/tech-worning';

import { BlurOverlay } from 'entities/blur-overlay';
import { ButtonBack } from 'entities/button-back';
import { ListPartners } from 'entities/suppliers/list-partner';
import { ListWarehouseCities } from 'entities/suppliers/list-warehouse-cities';
import { RequisitesPopup } from 'features/profile/ui/popup-profile';
import ComplaintModal from 'features/reviews/ui/complaint-modal';
import { ReviewList } from 'features/reviews/ui/review-list';
import { useSupplierDetail } from 'features/suppliers/api';
import { useGetCountries } from 'features/utils/api';
import { useParams } from 'react-router';
import { URL_SITE } from 'shared/constants';
import { TReview } from 'shared/services/types';
import { WorkDays } from './WorkDays';
import { formatTimeZone } from './utils/formatTimeZoneRu';

const SuppliersDetail = () => {
	const { name } = useParams();
	const [reviews, setReview] = useState<TReview.DataDetail | null>(null);
	const { data, error } = useSupplierDetail(name);
	const { data: countries } = useGetCountries();
	const [isOpenWarn, setIsOpenWarn] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isModalPartner, setIsModalPartner] = useState(false);

	const roundNumber = (num: number) => {
		if (!num) return 0;
		const roundedNum = Math.round((num + Number.EPSILON) * 100) / 100;

		const numStr = roundedNum.toString();
		if (numStr.endsWith('.00')) {
			return numStr.slice(0, -2);
		} else {
			return numStr;
		}
	};

	const supportedCountries = useMemo(() => {
		const newList = countries?.filter(item =>
			data?.countries_ids?.includes(item.id)
		);
		return newList || [];
	}, [data, countries]);

	const openModal = () => {
		setIsModalVisible(true);
	};

	const closeModal = () => {
		setIsModalVisible(false);
	};

	const openModalPartner = () => {
		setIsModalPartner(true);
	};

	const closeModalPartner = () => {
		setIsModalPartner(false);
	};

	const onClickButton = () => {
		if (data?.type === 'PARTNER') {
			// openModalPartner()
			setIsOpenWarn(true);
		} else {
			window.open('https://check.kira.pw/', '_blank');
		}
	};

	const titleList = {
		PARTNER: {
			title: 'Является действующим партнером поставщиков:',
			list: data?.partner_info?.suppliers
		},
		DISTRIBUTOR: {
			title: 'Является действующим дистрибьютором вендоров:',
			list: data?.manufacturer_info
		},
		BRAND_DEALER: {
			title: 'Является действующим дилером вендоров:',
			list: data?.manufacturer_info
		}
	};

	//@ts-ignore
	const supplierInfo = titleList[data?.type];

	return (
		<div className='partner-page'>
			{error && <BlurOverlay error={error} />}

			<div className='container'>
				<div className='partner-header'>
					<div className='partner-header__title'>
						<div className='partner-logo'>
							{data?.type !== 'PARTNER' && <img src={URL_SITE + data?.logo} />}

							<h1>{data?.name}</h1>
						</div>
						{reviews?.rating && (
							<div className='rating'>
								<svg
									width='32'
									height='29'
									viewBox='0 0 32 29'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M16 3.68429L19.0501 10.5269L19.4023 11.3171L20.2627 11.4079L27.7129 12.1942L22.1478 17.2095L21.5051 17.7887L21.6846 18.635L23.239 25.9636L16.7494 22.2206L16 21.7884L15.2506 22.2206L8.76101 25.9636L10.3154 18.635L10.4949 17.7887L9.85224 17.2095L4.28706 12.1942L11.7373 11.4079L12.5977 11.3171L12.9499 10.5269L16 3.68429Z'
										fill='#F1C644'
										stroke='#F1C644'
										strokeWidth='3'
									/>
								</svg>
								{roundNumber(reviews?.rating)}
							</div>
						)}
					</div>
					<ButtonBack />
				</div>
			</div>
			<div className='container'>
				{supplierInfo?.list && (
					<ListPartners title={supplierInfo.title} data={supplierInfo.list} />
				)}
			</div>

			<div className='partner-information'>
				<div className='container'>
					<div className='partner-information__row'>
						<div className='partner-information__item'>
							<div className='partner-message-client'>
								<h2>О компании</h2>
								{data?.description && (
									<p
										dangerouslySetInnerHTML={{
											__html: data?.description.replace(/\n/g, '<br/>')
										}}
									/>
								)}
							</div>
						</div>

						<div className='partner-information__item flex-end'>
							<div className='partner-contacts '>
								<h2>Контакты</h2>
								<div className='partner-contacts__list'>
									<ul>
										{data?.partner_info?.director && (
											<li>
												<svg
													width='20'
													height='20'
													viewBox='0 0 20 20'
													fill='none'
													xmlns='http://www.w3.org/2000/svg'
												>
													<circle
														cx='10.0003'
														cy='4.99935'
														r='3.33333'
														stroke='#8A7DE7'
														stroke-width='1.5'
													/>
													<path
														d='M12.4993 17.1789C11.7416 17.3846 10.894 17.4997 9.99935 17.4997C6.77769 17.4997 4.16602 16.0073 4.16602 14.1663C4.16602 12.3254 6.77769 10.833 9.99935 10.833C13.221 10.833 15.8327 12.3254 15.8327 14.1663C15.8327 14.4541 15.7689 14.7333 15.6489 14.9997'
														stroke='#8A7DE7'
														stroke-width='1.5'
														stroke-linecap='round'
													/>
												</svg>

												{data?.partner_info?.director}
											</li>
										)}

										<li>
											<svg
												width='24'
												height='24'
												viewBox='0 0 24 24'
												fill='none'
												xmlns='http://www.w3.org/2000/svg'
											>
												<path
													d='M12.9238 1.8457C12.9238 1.8457 15.2084 2.0534 18.1161 4.96109C21.0238 7.86878 21.2315 10.1534 21.2315 10.1534'
													stroke='#8A7DE7'
													strokeWidth='1.5'
													strokeLinecap='round'
												/>
												<path
													d='M12.9238 5.53906C12.9238 5.53906 14.0008 5.84675 15.6161 7.46214C17.2315 9.07752 17.5392 10.1544 17.5392 10.1544'
													stroke='#8A7DE7'
													strokeWidth='1.5'
													strokeLinecap='round'
												/>
												<path
													d='M1.85386 8.00365C1.77457 10.0953 2.30392 13.6473 5.86705 17.2105C6.72666 18.0701 7.58563 18.7531 8.4236 19.2936M3.53235 4.71973C5.05924 3.19285 7.49535 3.398 8.46481 5.13511L9.17623 6.40986C9.81824 7.56025 9.56051 9.06937 8.54934 10.0805C8.54934 10.0806 8.54934 10.0805 8.54934 10.0806C8.54923 10.0807 7.32292 11.3072 9.54663 13.5309C11.7697 15.7539 12.9962 14.529 12.997 14.5282C12.997 14.5282 12.997 14.5282 12.997 14.5281C14.0082 13.517 15.5173 13.2593 16.6677 13.9013L17.9424 14.6127C19.6795 15.5822 19.8847 18.0183 18.3578 19.5452C17.4403 20.4627 16.3163 21.1766 15.0739 21.2237C14.1816 21.2575 13.0234 21.1805 11.7121 20.8045'
													stroke='#8A7DE7'
													strokeWidth='1.5'
													strokeLinecap='round'
												/>
											</svg>
											{data?.phone_number && (
												<a href={`tel:${data?.phone_number}`}>
													{data?.phone_number}
												</a>
											)}
										</li>
										<li>
											<svg
												width='24'
												height='24'
												viewBox='0 0 24 24'
												fill='none'
												xmlns='http://www.w3.org/2000/svg'
											>
												<path
													d='M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7'
													stroke='#8A7DE7'
													strokeWidth='1.5'
													strokeLinecap='round'
												/>
												<path
													d='M14.8284 19.0711C14.457 19.9997 14.016 20.7362 13.5307 21.2388C13.0454 21.7413 12.5253 22 12 22C11.4747 22 10.9546 21.7413 10.4693 21.2388C9.98396 20.7362 9.54301 19.9997 9.17157 19.0711C8.80014 18.1425 8.5055 17.0401 8.30448 15.8268C8.10346 14.6136 8 13.3132 8 12C8 10.6868 8.10346 9.38642 8.30448 8.17317C8.5055 6.95991 8.80014 5.85752 9.17157 4.92893C9.54301 4.00035 9.98396 3.26376 10.4693 2.76121C10.9546 2.25866 11.4747 2 12 2C12.5253 2 13.0454 2.25866 13.5307 2.76121C14.016 3.26375 14.457 4.00035 14.8284 4.92893C15.1999 5.85752 15.4945 6.95991 15.6955 8.17317C15.8965 9.38642 16 10.6868 16 12C16 13.3132 15.8965 14.6136 15.6955 15.8268'
													stroke='#8A7DE7'
													strokeWidth='1.5'
													strokeLinecap='round'
												/>
												<path
													d='M2 12H10M22 12H14'
													stroke='#8A7DE7'
													strokeWidth='1.5'
													strokeLinecap='round'
												/>
											</svg>
											{data?.site_url && (
												<a target='_blank' href={data.site_url}>
													{data?.site_url}
												</a>
											)}
										</li>
										<li>
											<svg
												width='24'
												height='24'
												viewBox='0 0 24 24'
												fill='none'
												xmlns='http://www.w3.org/2000/svg'
											>
												<path
													d='M22 12C22 15.7712 22 17.6569 20.8284 18.8284C19.6569 20 17.7712 20 14 20H10C6.22876 20 4.34315 20 3.17157 18.8284C2 17.6569 2 15.7712 2 12C2 8.22876 2 6.34315 3.17157 5.17157C4.34315 4 6.22876 4 10 4H14C17.7712 4 19.6569 4 20.8284 5.17157C21.4816 5.82475 21.7706 6.69989 21.8985 8'
													stroke='#8A7DE7'
													strokeWidth='1.5'
													strokeLinecap='round'
												/>
												<path
													d='M18 8L15.8411 9.79908C14.0045 11.3296 13.0861 12.0949 12 12.0949C11.3507 12.0949 10.7614 11.8214 10 11.2744M6 8L6.9 8.75L7.8 9.5'
													stroke='#8A7DE7'
													strokeWidth='1.5'
													strokeLinecap='round'
												/>
											</svg>
											{data?.email && (
												<a href={`mailto:${data?.email}`}>{data?.email}</a>
											)}
										</li>
									</ul>

									{data?.partner_info && (
										<ul>
											<li>
												<strong>Время ответа:</strong>
												{data?.partner_info?.answer_time.replace(':00', '')} мин
											</li>
											<li>
												<strong>Рабочее время:</strong>
												<WorkDays workDays={data?.partner_info?.work_days} />
											</li>

											<li>
												<strong>Часовой пояс:</strong>
												{formatTimeZone(data?.partner_info?.time_zone)}
											</li>

											<li>
												<button
													type='button'
													onClick={() => setIsOpen(!isOpen)}
												>
													Реквизиты
												</button>
											</li>
										</ul>
									)}
								</div>
								{!!data?.warehouse_cities?.length && (
									<ListWarehouseCities
										list={data?.warehouse_cities?.map(v => v.name)}
									/>
								)}
							</div>
						</div>

						<RequisitesPopup
							isOpen={isOpen}
							setIsOpen={setIsOpen}
							data={data}
						/>

						<div className='partner-information__item'>
							<div className='partner-works-countries'>
								<h2>Работает со странами</h2>

								<ul>
									{supportedCountries?.map(item => (
										<li key={item.id}>
											<img src={URL_SITE + item.flag} />

											{item.name}
										</li>
									))}
								</ul>
							</div>
						</div>

						<div className='partner-information__item'>
							{/* //TODO временно убран
							<div className='partner-contacts__call'>
								<button
									type='button'
									className='btn'
									onClick={openModalPartner}
								>
									Связаться с продавцом
								</button>
							</div> */}
							<div className='partner-contacts__call'>
								<button type='button' className='btn' onClick={onClickButton}>
									{data?.type === 'PARTNER'
										? 'Связаться с продавцом'
										: 'Проверить с помощью КИRА.Досье'}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			{data?.partner_info && <PartnerInfo data={data} />}

			<TechWarning
				isOpen={isOpenWarn}
				onClose={() => setIsOpenWarn(false)}
				text='Приносим свои извинения, но данный функционал пока недоступен'
			/>

			<ReviewList data={data} setReview={setReview} />
			<ComplaintModal isOpen={isModalVisible} onClose={closeModal} />
			{/* <ContactPartner
				isOpen={isModalPartner}
				onClose={closeModalPartner}
				partnerId={data?.partner?.id || 0}
			/> */}
		</div>
	);
};

export default SuppliersDetail;
