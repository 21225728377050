import { axiosClassic } from 'shared/api';
import { getUtilsUrl } from 'shared/api/config';
import { TUtils } from './types/utils.types';

const UtilsService = {
	async getCountries(group_by_region?: boolean) {
		const response = await axiosClassic.get<TUtils.DataCountry[]>(
			getUtilsUrl(`/countries`),
			{
				params: {
					group_by_region
				}
			}
		);
		return response;
	},

	async getCities(country_id?: string) {
		const response = await axiosClassic.get<TUtils.DataCity[]>(
			getUtilsUrl(`/cities`),
			{
				params: {
					country_id
				}
			}
		);
		return response;
	},

	async getCountriesRegion() {
		const response = await axiosClassic.get<TUtils.RegionCounty>(
			getUtilsUrl(`/countries`),
			{
				params: {
					group_by_region: true
				}
			}
		);
		return response;
	},

	async getCurrencies() {
		const response = await axiosClassic.get<TUtils.DataCurrencies[]>(
			getUtilsUrl(`/currencies`)
		);
		return response;
	},

	async getCurrenciesExchange() {
		const response = await axiosClassic.get<TUtils.CurrenciesExchange>(
			getUtilsUrl(`/currencies/exchange`)
		);
		return response;
	},

	async getDeliveryServices() {
		const response = await axiosClassic.get<TUtils.DataDelivery[]>(
			getUtilsUrl(`/delivery_services`)
		);
		return response;
	},

	async getLanguages() {
		const response = await axiosClassic.get<TUtils.DataLanguage[]>(
			getUtilsUrl(`/languages`)
		);
		return response;
	}
};

export default UtilsService;
